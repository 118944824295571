import CompanyMapBlackImage from 'images/menu_icon/company_map_black.svg';
import ClockCircle from 'images/menu_icon/clock_circle.svg';
import * as React from 'react';
import * as moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { getCalendarCheckIns, getCalendarForms, getCalendarFreeForms, getCalendarOrders, getCalendarPromotions, getCalendarShelfAudits, getEvents } from './EventsApi';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { colorStatusMapping, colorTypeMapping } from './model/Model';
import { CheckInCalendar } from '../../../typings/bindings/check_ins/CheckInCalendar';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { BlueSidely, BorderColor2, SidelyBlack } from '../../styles/global/css/Utils';
import { useRecoilValue } from 'recoil';
import { AEventTypes } from '../../atoms/global/events';
import { updateEvent } from '../client-companies/popup/action';
import { momentToNaiveDateTime } from '../../components_v2/utils';
import useAlert from '../alert/UseAlert';
import { translateToNode, translateToString } from '../../styles/global/translate';
import { AlertRes } from '../alert/AlertProvider';
import Restricted from '../permissions/Restricted';
import PermissionContext from '../permissions/PermissionContext';
import promotionIcon from 'images/icons/events/Promotion.svg';
import { getLanguageCode } from '../reports/utils';
import { toast } from 'react-toastify';

type loadingState = 'loading' | 'loaded' | 'error' | null

export enum EventsTypes {
  Event,
  ShelfAudit,
  FreeForm,
  Order,
  TemporaryEvent,
  CheckIn,
  Promotion,
  Form
}

export class EventsTypesUtils {
	public static toTranslateString(type: EventsTypes): string {
		switch (type) {
			case EventsTypes.Event: return 'event';
			case EventsTypes.ShelfAudit: return 'tool_bar.shelf_audits';
			case EventsTypes.FreeForm: return 'tool_bar.free_forms';
			case EventsTypes.TemporaryEvent: return '';
			case EventsTypes.Order: return 'orders_';
			case EventsTypes.CheckIn: return 'check_in';
			case EventsTypes.Promotion: return 'campagne';
			case EventsTypes.Form: return 'forms';
		}
	}
}


export interface CalendarEvent {
  type: EventsTypes.Event | EventsTypes.TemporaryEvent
  id: number
  eventStatusId: number
  eventTypeId: number
  title: string
  start: Date
  end: Date
  allDay: boolean
  companieName?: string
  incharge: number
  checked?: boolean
  description?: string
  clientCompanyId?: number
  contactId?: number
  contactLastName?: string
  contactFirstName?: string
  created_by?: number
  created_at?: Date
  latitude?: number
  longitude?: number
  form?: number
  scheduled?: boolean
}

interface ShelfAuditEvents {
  type: EventsTypes.ShelfAudit
  id: number
  start: Date
  end: Date
  title: string
  link?: string
  incharge?: number
  companieName?: string,
  companyId?: number
}

interface FreeForm {
  type: EventsTypes.FreeForm
  clientCompanyId: number
  companyName: string
  description: string
  end: Date
  start: Date
  eventStatusId: number
  incharge: number
  title: string
  id: string
  link: string
}

export interface OrderEvent {
  type: EventsTypes.Order
  id: number
  owner_id: number
  start: Date
  end: Date
  client_company_id: number
  incharge: number
  client_company_name: string
}

export type CheckInEvent = {
	type: EventsTypes.CheckIn
	id: number
	checkIn: CheckInCalendar
	start: Date
	end: Date
}

export type PromotionEvent = {
	type: EventsTypes.Promotion
	promotion_id: number
	start: Date
	end: Date
	color?: string,
	name: string,
	promotion_name: string
}

export type FormEvent = {
	type: EventsTypes.Form
	start: Date
	end: Date
	id: string
	owner_id: number,
	name: string
}

export type CalendarEvents = CalendarEvent | ShelfAuditEvents | FreeForm | OrderEvent | CheckInEvent | PromotionEvent | FormEvent

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const DandDCalendar = styled(DragAndDropCalendar)`
	.rbc-time-header {
		resize: vertical;
		min-height: 45px;
		overflow: hidden;
		max-height: 100%;
	}

	.rbc-allday-cell {
		overflow: hidden;
	}
	.rbc-header {
		text-align: unset;
	}
	.rbc-header > a {
		width: 100%;
	}
	.rbc-row-content {
		overflow: auto;
		height: 100%;
	}
`;


const CalendarPanelContainer = styled(FlexDiv)`
	border: 1px solid ${BorderColor2};
	border-radius: 5px;
`;

const CalendarPanel = styled(DefaultButton)<{ selected?: boolean }>`
	color: ${SidelyBlack}${p => p.selected ? '' : '40'};
	${p => p.selected ? 'font-weight: 500;' : ''}
	border-radius: 4px;
	background ${p => p.selected ? `${BorderColor2}80` : 'transparent'};
	margin: 0;
	height: 24px;
	width: fit-content;
	transition: unset;
	line-height: 0px;
	padding: 0 10px;
	width: 75px;

	&:hover {
		filter: unset !important;
	}
`;

const CalendarToolbarButton = styled(DefaultButton)<{ position?: 'first' | 'last', selected?: boolean }>`
	margin: 0;
	border-radius: 0;
	height: 24px;
	color: black;
	border: 1px solid ${BorderColor2};
	color: ${SidelyBlack};
	margin-left: -1px;
	${p => p.position === 'first' ? 'border-radius: 5px 0 0 5px;' : p.position === 'last' ? 'border-radius: 0 5px 5px 0;' : ''}
	&:hover {
		background: ${BorderColor2}80;
		filter: none !important;
	}
	background: ${p => p.selected ? `${BorderColor2}80` : 'transparent'};
	font-size: 11px;
	width: fit-content;
	line-height: 20px;
	padding: 0 5px;
	min-width: 24px;
`;

const ClockIcon = styled.img `
	position: absolute;
	top: 0px;
	right: 0px;
	height: 15px;
	filter: opacity(50%);
`;

function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function getStyleFormType(className: string, color: string, type: EventsTypes, restricted: boolean | undefined, event_id: number | string, hoveredEventId: string | number | undefined, scheduled: boolean) {
	const filter = hoveredEventId && event_id != hoveredEventId ? 'brightness(50%)' : '';
	switch (type) {
		case EventsTypes.ShelfAudit:
		case EventsTypes.FreeForm:
		case EventsTypes.Order:
		case EventsTypes.CheckIn:
		case EventsTypes.Form:
			return {
				className,
				backgroundColor: '#000000',
				border: 'none',
				color: 'white',
				outline: `4px solid ${color}`,
				filter
			};
	}
	const isTemporary = type !== EventsTypes.TemporaryEvent && restricted;
	return {
		className,
		backgroundColor: isTemporary ? '#FFFFFF' : color,
		border: scheduled ? '3px solid rgba(0, 0, 0, 0.2)' : (isTemporary ? `2px solid ${color}` : undefined),
		color: isTemporary ? 'black' : 'white',
		filter: scheduled ? 'brightness(1.1)' : filter,
		fontSize: '12px',
	};
}

export function getEventStatusColor(status: number): string | undefined {
	return colorStatusMapping[status - 1].color;
}

// add dot before event name from status
function getDot(status?: number) {
	if (!status) return <></>;
	const color = getEventStatusColor(status);
	if (!color) return <></>;
	return (<span className="dot" style={{
		backgroundColor: color,
		width: '15px',
		height: '15px',
		minWidth:'15px',
		boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.33)',
		flexShrink: 0
	}} />);
}

const formats = {
	eventTimeRangeFormat: ({ start, end }) => {
		if (end.getTime() - start.getTime() < 3600000) { // 1h
			return '';
		}
		return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
	}
};

function hashRequest(lst: unknown[]) {
	let ret = '';
	for (const item of lst) {
		ret = ret + JSON.stringify(item);
	}
	return ret;
}

function toCalendarFreeForms(freeForms) {
	const ret: CalendarEvents[] = freeForms.map(currFF => {
		const start = new Date(Date.parse(currFF.date));
		const end = new Date(start.getTime() + 1000 * 60 * 45); // add 60mins
		return (
			{
				type: EventsTypes.FreeForm,
				id: currFF.id,
				start,
				end,
				// companieName: currSA.company_name ? currSA.company_name : "",
				title: currFF.title,
				link: currFF.link,
				incharge: currFF.incharge,
			});
	});
	return ret;
}

// turn fetched events into calendarShelfAudit
function toCalendarShelfAudits(shelfAudits): CalendarEvents[] {
	const translate = getTranslate(storeLang.getState().localize);
	const ret: CalendarEvents[] = shelfAudits.map(currSA => {
		const start = new Date(Date.parse(currSA.date));
		const end = new Date(start.getTime() + 1000 * 60 * 45); // add 60mins

		return (
			{
				type: EventsTypes.ShelfAudit,
				id: currSA.id,
				start,
				end,
				companieName: currSA.company_name ? currSA.company_name : undefined,
				companyId:currSA.company_id,
				title: translate('calendar.shelf_audits'),
				incharge: currSA.incharge,
			});
	});
	return ret;
}

export function toTemporaryEvents(events): CalendarEvents[] {
	const ret: CalendarEvents[] = events.map((currEv, i) => {
		return (
			{
				...currEv,
				type: EventsTypes.TemporaryEvent,
				end: currEv.endDate,
				start: currEv.startDate,
				id: currEv.id !== undefined ? currEv.id : `temporaryEvent[${i}]`
			});
	});
	return ret;
}

// get upper and lower bounds of api call
function dateRangeToFecth(obj, key?: string) {
	let avg = 0;
	if (obj.start) {
		avg = (obj.start.getTime() + obj.end.getTime()) / 2;
	} else {
		const length = obj.length;
		avg = obj.map(e => e.getTime())
			.reduce((a, b) => a + b, 0);
		avg /= length;
	}
	if (!key) {
		if (Array.isArray(obj)) {
			key = 'week';
		} else if (obj instanceof Date) {
			key = 'day';
		} else {
			key = 'month';
		}
	}
	const rawStart = new Date(avg);
	const rawEnd = new Date(avg);
	let start;
	let end;
	switch (key) {
		case 'month':
			rawStart.setMonth(rawStart.getMonth() - 2);
			start = new Date(rawStart.getFullYear(), rawStart.getMonth());
			rawEnd.setMonth(rawEnd.getMonth() + 3);
			end = new Date(rawEnd.getFullYear(), rawEnd.getMonth());
			break;
		case 'week':
			rawStart.setDate(rawStart.getDate() - 10);
			rawStart.setHours(0, 0, 0, 0);
			start = rawStart;
			rawEnd.setDate(rawEnd.getDate() + 11);
			rawEnd.setHours(0, 0, 0, 0);
			end = rawEnd;
			break;
		case 'day':
			rawStart.setDate(rawStart.getDate() - 2);
			rawStart.setHours(0, 0, 0, 0);
			start = rawStart;
			rawEnd.setDate(rawEnd.getDate() + 3);
			rawEnd.setHours(0, 0, 0, 0);
			end = rawEnd;
			break;
	}
	return { start, end };
}

export default function SidelyCalendar(props: {
  setEvents: (lst: CalendarEvents[]) => void
  events: CalendarEvents[]
  onSelectSlot?: (e) => void
  onSelectEvent?: (e) => void
  setLoadingState: (loadingState: loadingState) => void
  loadingState: loadingState
  filters?
  toolBarState?
  reset?: boolean
  setReset?: (b: boolean) => void
  style
  restricted?: boolean
  id?: number
  defaultDate?: Date
  onEventHover?: (event?: CalendarEvents) => void
  defaultView?: string
  hoveredEventId?: number | string
  eventStyle?: (event: CalendarEvents, hovered: boolean) => ({ style })
  onMapClicked?: (date: Date) => void
  date?: Date
  onDateChange?: (d: Date) => void
  isMapOpen?: boolean
}) {
	const { onMapClicked } = props;
	const translate = getTranslate(storeLang.getState().localize);
	let reloadApi = false;
	const [isApiRunning, setApiRunning] = React.useState<boolean>(false);
	const [lastestRequest, setLastestRequest] = React.useState<string>('');
	const [calendarView, setCalendarView] = React.useState<string>(props.defaultView ?? 'week');
	const [displayedDate, setdisplayedDate] = React.useState(dateRangeToFecth([new Date(Date.now())], calendarView));
	const [date, setDate_] = React.useState<Date>(props.defaultDate ?? new Date());
	const [clicked, setClicked] = React.useState<boolean>(false);
	const setDate = (date: Date) => {
		setDate_(date);
		props.onDateChange?.(date);
	};
	const eventTypes = useRecoilValue(AEventTypes);
	const alert = useAlert();
	const { isAllowedTo } = React.useContext(PermissionContext);

	const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setRefresh(true);
		}, 500);
		
		return () => clearTimeout(timeout); 
	}, [isLoaded]);

	React.useEffect(() => {props.date && setDate_(props.date);}, [props.date]);

	React.useEffect(() => {
		if (props.reset) {
			props.setReset?.(false);
			setLastestRequest('');
		}
	}, [props.reset]);

	// fetch data
	React.useEffect(() => {
		if (props.toolBarState && props.toolBarState.userId === undefined) {
			return;
		}
		const request = hashRequest([props.filters, props.toolBarState, displayedDate]);
		if (lastestRequest && request == lastestRequest) {
			reloadApi = false;
		} else if (isApiRunning) {
			reloadApi = true;
		} else {
			reloadApi = false;
			setApiRunning(true);
			setLastestRequest(request);
			const apis = async() => {
				try {
					props.setLoadingState('loading');
					let list: CalendarEvents[] = [];
					if (props.filters) {
						for (const type of props.filters.types) {
							switch (type) {
								case EventsTypes.Event: {
									const events = await getEvents(displayedDate, props.toolBarState);
									list = list.concat(events);
									break;
								}
								case EventsTypes.ShelfAudit: {
									const shelf_audits = await getCalendarShelfAudits(displayedDate, props.toolBarState);
									const calendarShelfAudits = toCalendarShelfAudits(shelf_audits);
									list = list.concat(calendarShelfAudits);
									break;
								}
								case EventsTypes.FreeForm: {
									const free_forms = await getCalendarFreeForms(displayedDate, props.toolBarState);
									const calendarFreeForms = toCalendarFreeForms(free_forms);
									list = list.concat(calendarFreeForms);
									break;
								}
								case EventsTypes.Order: {
									const orders = await getCalendarOrders(displayedDate, props.toolBarState);
									list = list.concat(orders);
									break;
								}
								case EventsTypes.CheckIn: {
									const checkIns = await getCalendarCheckIns(displayedDate, props.toolBarState);
									list = list.concat(checkIns);
									break;

								}
								case EventsTypes.Promotion: {
									const promotions = await getCalendarPromotions(displayedDate);
									list = list.concat(promotions);
									break;
								}
								case EventsTypes.Form: {
									const forms = await getCalendarForms(displayedDate, props.toolBarState);
									list = list.concat(forms);
									break;
								}
							}
						}
					} else {
						const events = await getEvents(displayedDate, props.toolBarState ?? { userId: props.id });
						list = list.concat(events);
					}
					setApiRunning(false);
					list = list.concat(props.events.filter(e => e.type == EventsTypes.TemporaryEvent));
					props.setEvents(list);
					if (reloadApi) {
						props.setLoadingState('loading');
					} else {
						props.setLoadingState('loaded');
						setIsLoaded(true);
					}
				} catch (e) {
					console.error(e);
					setApiRunning(false);
					props.setLoadingState('error');
				}
			};
			apis();
		}
	}, [props.loadingState, displayedDate, props.toolBarState, props.filters]);

	function customEvent(props: { event: CalendarEvents }) {
		const { event } = props;
		let value: React.ReactNode = '';
		switch (event.type) {
			case EventsTypes.CheckIn:
				value = event.checkIn.company_name ?? 'check in';
				break;
			case EventsTypes.Promotion:
				value = <>
					<img src={promotionIcon} height='16px' style={{ flexShrink: 0 }}/>
					{event.promotion_name} - {event.name}
				</>;
				break;
			default:
				if ('companieName' in event) value = event.companieName ?? event.title;
				else if ('title' in event) value = event.title;
				else if ('name' in event) value = event.name;
		}
		return (
			<>
				<FlexDiv style={{ whiteSpace: 'nowrap' }} gap='0.5em' margin='0 0 0 10px'>
					{'eventStatusId' in event && getDot(event.eventStatusId)}
					<FlexDiv gap='0.5em'>
						{value}
					</FlexDiv>
				</FlexDiv>
				{ 'scheduled' in event && event?.scheduled && <ClockIcon src={ClockCircle}/> }
			</>
		);
	}

	// static traductions for Big Calendar
	const localTraductions = {
		next: translate('next'),
		previous: translate('previous'),
		today: translate('today'),
		month: translate('month'),
		week: translate('Week'),
		day: translate('day')
	};

	function onEventDrop(event) {
		switch (event.event.type as EventsTypes) {
			case EventsTypes.ShelfAudit:
			case EventsTypes.FreeForm:
			case EventsTypes.Order:
			case EventsTypes.CheckIn:
			case EventsTypes.Promotion:
			case EventsTypes.Form:
				return;
		}
		if (event.isAllDay) {
			event.start.setHours(0, 0, 0);
			event.end.setHours(23, 59, 0);
			event.event.allDay = true;
		}
		const newEvent = {
			...event.event,
			start: event.start,
			end: event.end
		};
		if (event.event.scheduled) { 
			toast.error(translateToString('company.creation.event.moving_scheduled'));
			return;
		}
		if (!props.restricted) {
			if (event.event.type == EventsTypes.Event) {
				if (!isAllowedTo({ objectAction: 'UpdateEvent' }, { ownerId: newEvent.incharge })) return;
				updateEvent(event.event.id, { start: momentToNaiveDateTime(moment(event.start).utc()), end: momentToNaiveDateTime(moment(event.end).utc()) })
					.catch(() => {
						alert({
							title: translateToNode('error'),
							content: translateToNode('error_while_modifying_event'),
							buttons: [{
								title: 'Ok',
								res: AlertRes.Ok
							}]
						});
						const index = props.events.findIndex(e => 'id' in e && e.id === event.event.id);
						if (index >= 0) {
							props.events[index] = event.event;
						}
						props.setEvents([...props.events]);
					});
			}
		}
		if (newEvent.type == EventsTypes.Event) {
			if (!isAllowedTo({ objectAction: 'UpdateEvent' }, { ownerId: newEvent.incharge })) return;
			newEvent.temporaryModified = true;
		}
		const index = props.events.findIndex(e => 'id' in e && e.id === newEvent.id);
		if (index >= 0) {
			props.events[index] = newEvent;
		}
		props.setEvents([...props.events]);
	}

	// change events style by type
	const getEventTypeStyle = (event: CalendarEvents) => {
		if (props.eventStyle != null) {
			return props.eventStyle(event, 'id' in event && event.id == props.hoveredEventId);
		} else if (event.type === EventsTypes.Promotion) {
			const filter = props.hoveredEventId ? 'brightness(50%)' : '';
			return {
				style: {
					backgroundColor: '#FFFFFF',
					border: `1px solid ${event.color ?? BlueSidely}`,
					padding: 0,
					color:  'black',
					filter
				}
			};
		} else {
			let switchOn;
			if ('eventTypeId' in event) {
				switchOn = event.eventTypeId;
			} else {
				switchOn = event.type;
			}
			const scheduled = !!('scheduled' in event && event?.scheduled);
			switch (switchOn) {
				case 1:
					return {
						style: getStyleFormType('task-event', colorTypeMapping[0].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 2:
					return {
						style: getStyleFormType('call-event', colorTypeMapping[1].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 3:
					return {
						style: getStyleFormType('meeting-event', colorTypeMapping[2].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 4:
					return {
						style: getStyleFormType('visit-event', colorTypeMapping[3].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 5:
					return {
						style: getStyleFormType('sms-event', colorTypeMapping[4].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 6:
					return {
						style: getStyleFormType('email-event', colorTypeMapping[5].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 7:
					return {
						style: getStyleFormType('animation-event', colorTypeMapping[6].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 8:
					return {
						style: getStyleFormType('promotion-event', colorTypeMapping[7].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 9: 
					return {
						style: getStyleFormType('delivery-event', colorTypeMapping[8].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 10: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[9].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 11: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[10].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 12: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[11].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 13: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[12].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 14: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[13].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 15: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[14].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 16: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[15].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 17: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[16].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 18: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[17].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				case 19: 
					return {
						style: getStyleFormType('order-event', colorTypeMapping[18].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
				default:
					return {
						style: getStyleFormType('delivery-event', colorTypeMapping[8].color, event.type, props.restricted, event.id, props.hoveredEventId, scheduled)
					};
			}
		}
	};

	// eslint-disable-next-line react/display-name
	const dateHeader = (week: boolean, events: CalendarEvents[], isMapOpen = false) => (props: { date: Date, label: string, onDrillDown: () => void }): React.ReactNode => {
		if (!onMapClicked || events.filter(e => e.start.toDateString() === props.date.toDateString() && ('checkIn' in e || (e.type === EventsTypes.Event && e.latitude && e.longitude && eventTypes.some(event => event.id === e.eventTypeId && event.map)))).length === 0) return <div style={{ cursor: 'pointer' }} onClick={props.onDrillDown}>{props.label}</div>;
		const flexProps = week ? { gap: '10px' } : { margin: '0 15px 0 0' };
		return (
			<FlexDiv justify='space-between' {...flexProps}>
				<div style={{ cursor: 'pointer' }} onClick={props.onDrillDown}>{props.label}</div>
				<Restricted to={{ objectAction: 'ViewMap' }}>
					<div style={{ cursor: 'pointer' }} onClick={_ => {
						setClicked(true);
						onMapClicked(props.date);
					}}><img src={CompanyMapBlackImage} style={{ height: 21, marginTop: '-5px', opacity: props.date.toDateString() === date.toDateString() && isMapOpen ? undefined : 0.3 }}/></div>
				</Restricted>
			</FlexDiv>
		);
	};

	if (!props.loadingState) {
		return (<></>);
	}

	// eslint-disable-next-line react/display-name
	const ToolBar = (events: CalendarEvents[], isMapOpen = false) => (props: {
		date: Date,
		label: string,
		localizer,
		onNavigate: (action, newDate: Date) => void,
		onView: (view: 'week' | 'day' | 'month') => void,
		view: 'week' | 'day' | 'month',
		views: Array<'week' | 'day' | 'month'>
	}): JSX.Element => {
		let map = false;
		if (props.view === 'day' && onMapClicked && events.some(e => e.start.toDateString() === props.date.toDateString() && ('checkIn' in e || (e.type === EventsTypes.Event && e.latitude && e.longitude && eventTypes.some(evenType => evenType.id === e.eventTypeId && evenType.map))))) map = true;

		const onNavigate = (period: PeriodNavigatorType) => {
			switch (period) {
				case 'previous': return props.onNavigate('previous', moment(props.date).subtract(1, props.view).toDate());
				case 'today': return props.onNavigate('today', moment().toDate());
				case 'next': return props.onNavigate('next', moment(props.date).add(1, props.view).toDate());
			}
		};

		return (
			<FlexDiv justify='space-between' width='100%' padding='0 0px 10px 1px' fontWeight='700' color={SidelyBlack}>
				<FlexDiv gap='20px' width='33%'>
					{capitalizeFirstLetter(props.label)}
					<Restricted to={{ objectAction: 'ViewMap' }}>
						{map && onMapClicked && <a onClick={_ => {
							setClicked(true);
							onMapClicked(props.date);
						}}>
							<img src={CompanyMapBlackImage} style={{ height: 21, marginTop: '-5px', opacity: props.date.toDateString() === date.toDateString() && isMapOpen ? undefined : 0.3 }}/>
						</a>}
					</Restricted>
				</FlexDiv>
				<FlexDiv width='33%' justify='center'>
					<PeriodViewSelector
						{...props}
						selectedView={props.view}
					/>
				</FlexDiv>
				<FlexDiv width='33%' justify='right'>
					<PeriodNavigator onNavigate={onNavigate}/>
				</FlexDiv>
			</FlexDiv>
		);

	};

	return (
		<DandDCalendar
			key={`CalendarRerenderEvents${JSON.stringify({ events: props.events.map(e => 'id' in e ? e.id : e.promotion_id), time: date.toDateString(), open: props.isMapOpen , refresh: refresh })}`}
			culture={getLanguageCode()}
			messages={localTraductions}
			localizer={localizer}
			events={props.events}
			style={props.style}
			scrollToTime={moment().set({ h: Number(localStorage.getItem('startCalendarHour') ?? 6), m: 0 }).toDate()}
			views={{ day: true, week: true, month: true }}
			eventPropGetter={getEventTypeStyle}
			onSelectEvent={props.onSelectEvent}
			onRangeChange={e => {
				const ret = dateRangeToFecth(e);
				setdisplayedDate(ret);
			}}
			components={{
				month: { dateHeader: dateHeader(false, props.events, props.isMapOpen) },
				week: { header: dateHeader(true, props.events, props.isMapOpen) },
				toolbar: ToolBar(props.events, props.isMapOpen),
				event: customEvent,
				eventWrapper: ({ event, children }) => (
					<div
						onMouseEnter={() => props.onEventHover?.(event)}
						onMouseLeave={() => props.onEventHover?.()}
					>
						{children}
					</div>
				)
			}}
			date={date}
			onNavigate={setDate}
			startAccessor="start"
			endAccessor="end"
			onEventDrop={onEventDrop}
			selectable={props.onSelectSlot && isAllowedTo({ objectAction: 'CreateEvent' })}
			onSelectSlot={e => {
				if (clicked) {
					setClicked(false);
				} else {
					props.onSelectSlot?.(e);
				}
			}}
			view={calendarView}
			onView={setCalendarView}
			formats={formats}
			defaultDate={props.defaultDate}
			onEventResize={onEventDrop}
			resizable
			resizableAccessor={() => true}
			dateRange
		/>
	);
}

export type PeriodView = 'day' | 'week' | 'month' | 'quarter' | 'year'

export function PeriodViewSelector(props: { views: PeriodView[], onView: (v: PeriodView) => void, selectedView?: PeriodView }) {
	return <CalendarPanelContainer>
		{props.views.map(v =>
			<CalendarPanel
				key={`PeriodViewSelectorButton${v}`}
				onClick={() => props.onView(v)}
				selected={v === props.selectedView}
			>
				<Translate id={v}/>
			</CalendarPanel>
		)}
	</CalendarPanelContainer>;
}
export type PeriodNavigatorType = 'previous' | 'today' | 'next';

export function PeriodNavigator(props: { onNavigate: (navigate: PeriodNavigatorType) => void}) {
	return <FlexDiv>
		<CalendarToolbarButton onClick={() => props.onNavigate('previous')} position='first' >{'<'}</CalendarToolbarButton>
		<CalendarToolbarButton onClick={() => props.onNavigate('today')}><Translate id='today'/></CalendarToolbarButton>
		<CalendarToolbarButton onClick={() => props.onNavigate('next')} position='last' >{'>'}</CalendarToolbarButton>
	</FlexDiv>;
}