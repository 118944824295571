/* eslint-disable @typescript-eslint/no-non-null-assertion */
import reports_black from 'images/ui_icon/kanbanView_black.svg';
import listView_black from 'images/ui_icon/listView_black.svg';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { fieldTypeToFilterType } from '../../../atoms/additionalFieldColumns';
import { AFormFields, AFormTemplate, Field } from '../../../atoms/forms';
import { DbView, FormView } from '../../../atoms/global/views';
import AdvancedFilters from '../../../components_v2/filter/AdvancedFilters';
import { filterTreeLength } from '../../../components_v2/filter/model/Model';
import { Filter, FilterParameter } from '../../../components_v2/filter/pages/FilterList';
import ToolbarFilterButton from '../../../components_v2/toolbarFilter/ToolbarButton';
import { getUrlParameterByName } from '../../../components_v2/utils';
import { ToolBarView } from '../../../components_v2/view/ToolBarView';
import { Translate, translateToString } from '../../../styles/global/translate';
import { useFunctionState } from '../../../utils/customHooks';
import { checkUrlModalParams } from '../../client-companies/Companies';
import { FilterState } from '../../contact/Contacts';
import { TableContext, TableProvider } from '../../contact/data/ContactContext';
import { ToolbarBox } from '../../globals/defaultToolbar/style/Style';
import { ComponentProps, ToolbarState } from '../../globals/mainPage/mainPage';
import { ModalState } from '../../products/model';
import { SwitchView } from '../../reports/interpretor/ReportInterpretor';
import { getFormInstancesFilters } from '../actions';
import { InstancePopup } from '../formInstancePopup';
import { FormTemplate } from '../jsonValidator';
import FormInstanceKanban from './FormInstanceKanban';
import FormInstanceList from './FormInstanceList';

export type FormInstanceViewSubProps = ComponentProps & {
	template: FormTemplate | undefined,
	formId: number,
	fieldMap: { [key: number | string]: Field },
	fieldColumns: { [key: number]: Field },
	setFilterState: React.Dispatch<React.SetStateAction<FilterState>>,
	viewLoaded: boolean,
	openPopup: (uuid: string) => void
};

const FORM_VIEW_LOCAL_STORAGE = 'form_view_id';
export const FORM_HIDDEN_LOCAL_STORAGE = 'form_hidden_columns';
export const FORM_SORT_LOCAL_STORAGE = 'form_sort_columns';

export default function FormInstanceView(props: ComponentProps) {
	return <TableProvider>
		<_FormInstanceView {...props} />
	</TableProvider>;
}

type ViewEnum = 'list' | 'kanban'

function _FormInstanceView(props: ComponentProps) {
	const [subComponentToolBarState, setSubComponentToolBarState] = React.useState<ToolbarState>();
	const [instanceModal, setInstanceModal] = React.useState<ModalState<string>>({ isOpen: false });
	const history = useHistory();
	const formId = parseInt(getUrlParameterByName('id') ?? 'NaN');
	const [view, setView] = useFunctionState<ViewEnum>(history.location.hash === '#kanban' ? 'kanban' : 'list', ({ newValue }) => {
		history.replace({ search: `?id=${formId}`, hash: newValue });
		return newValue;
	});
	const forms = useRecoilValue(AFormTemplate);
	const template = React.useMemo(() => forms.find(a => a.id === formId), [formId, forms]);


	const context = React.useContext(TableContext);
	const { hiddenColumns, sortColumns, filterResult, setFilterResult, setSortColumns, setHiddenColumns } = context;

	// FILTERS
	const [baseFilterParams, setBaseFilterParams] = React.useState<FilterParameter[]>([]);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterState, setFilterState] = React.useState<FilterState>({});

	const fields = useRecoilValue(AFormFields);
	const fieldMap: { [key: number | string ]: Field } = React.useMemo(() => fields.reduce((acc, f) => ({ ...acc, [f.id]: f, [f.slug]: f }), {}), [fields]);
	const fieldColumns: {[key: number]: Field} = React.useMemo(() => template?.screens.flatMap(s => s.fields).reduce((acc, f) => {
		if (Object.keys(f.metadata).length !== 0) return acc;
		if (f.field_id !== undefined) acc[f.field_id] = fieldMap[f.field_id];
		else if (f.slug !== undefined) {
			const field = fieldMap[f.slug];
			acc[field.id] = field;
		}
		return acc;
	}, {}) ?? {}, [template, fieldMap]);
	const [viewLoaded, setViewLoaded] = React.useState(false);

	React.useEffect(() => {
		const f = ({ isOpen, isFullOpen, id }) => setInstanceModal({ isOpen, data: id, fullOpenMode: isFullOpen });
		checkUrlModalParams(f, 'instance')();
		history.listen(checkUrlModalParams(f, 'instance'));
	}, [history]);

	const openPopup = React.useCallback((view: ViewEnum) => (uuid: string) => {
		history.replace({
			search: `?id=${formId}&instance=${uuid}`,
			hash: view,
		});
	}, [formId]);

	React.useEffect(() => {
		getFormInstancesFilters().then((res) => setBaseFilterParams(res.filters));
	}, []);

	React.useEffect(() => {
		setFilterParams([...baseFilterParams, { category: translateToString('additional_field_columns'), filters: Object.values(fieldColumns).map<Filter>((fc): Filter => ({
			id: { additional_field_columns: [fc.id] },
			name: fc.name,
			type: fieldTypeToFilterType(fc.type)
		})) }]);
	}, [baseFilterParams, formId]);

	React.useEffect(() => {
		const localHiddenColumns = JSON.parse(localStorage.getItem(FORM_HIDDEN_LOCAL_STORAGE + `[${formId}]`) || '[]');
		const localSortColumns = JSON.parse(localStorage.getItem(FORM_SORT_LOCAL_STORAGE + `[${formId}]`) || '[]');
		localSortColumns && setSortColumns(localSortColumns);
		localHiddenColumns && setHiddenColumns(localHiddenColumns);
	}, [formId]);

	const filterView = React.useCallback((view: DbView<FormView>) => view.data.formId === formId, [formId]);

	React.useEffect(() => {
		props.setToolBarState({
			title: template?.name ?? translateToString('form_instance'),
			bottomLeftToolbarComponent: <ToolbarBox>
				<ToolBarView
					localStorageKey={FORM_VIEW_LOCAL_STORAGE + `[${formId}]`}
					localStorageHiddenKey={FORM_HIDDEN_LOCAL_STORAGE + `[${formId}]`}
					viewsKey='forms'
					context={context}
					dataFormatter={data => ({ ...data, formId })}
					viewsFilters={filterView}
					onLoad={() => setViewLoaded(true)}
				>
					<ToolbarFilterButton
						activeFilters={filterTreeLength(filterResult?.formatted)}
						onClick={() => setFilterState(state => ({ ...state, openSummary: true, filterOpen: true }))}
						onDeleteFilter={() => {
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>
					{subComponentToolBarState?.bottomLeftToolbarComponent}
				</ToolBarView>
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				<SwitchView src={listView_black} active={view === 'list'} onClick={_ => setView('list')} />
				<SwitchView src={reports_black} active={view === 'kanban'} onClick={_ => setView('kanban')} />
				<Button onClick={() => history.push({ pathname: 'settings', hash: 'form_templates' })}><Translate id='all_templates' /></Button>
				{subComponentToolBarState?.bottomRightToolbarComponent}
			</ToolbarBox>
		});
	}, [subComponentToolBarState, view, filterResult, filterResult, hiddenColumns, sortColumns, formId]);

	return <>
		{view === 'list' && <FormInstanceList {...props} openPopup={openPopup('list')} viewLoaded={viewLoaded} setFilterState={setFilterState} fieldColumns={fieldColumns} fieldMap={fieldMap} setToolBarState={setSubComponentToolBarState} template={template} formId={formId} />}
		{view === 'kanban' && <FormInstanceKanban {...props} openPopup={openPopup('kanban')} viewLoaded={viewLoaded} setFilterState={setFilterState} fieldColumns={fieldColumns} fieldMap={fieldMap} setToolBarState={setSubComponentToolBarState} template={template} formId={formId} />}
		<AdvancedFilters
			permission='ReadForm'
			isOpen={filterState.filterOpen ?? false}
			setOpen={filterOpen => setFilterState({ filterOpen })}
			isOpenSummary={filterState.openSummary ?? false}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
			selectedFilter={filterState.selectedId}
		/>

		<InstancePopup
			isOpen={instanceModal.isOpen}
			onClickOut={() => {
				setInstanceModal({ ...instanceModal, isOpen: false, });
				history.push({ search: `?id=${formId}` });
			}}
			modalState={instanceModal}
		/>
	</>;

}
