import styled from 'styled-components';
import { DefaultImage } from '../../../../styles/global/css/GlobalImage';
import { DarkGreySidely, DarkGreySidely2, GreenSidely, SidelyBlack } from '../../../../styles/global/css/Utils';
import { getColorForEvolution, getColorForPercentage } from '../../../reports/utils';

const Container = styled.div``;

const ContainerModalLeft = styled.div``;

const ContainerTable = styled.div``;

export const TableRowDnContainer = styled.div<{dn: number}>`
    background-color: ${p => getColorForPercentage(p.dn, 1)};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    width: fit-content;
    border-radius: 60px;
    color: ${SidelyBlack};
    font-size: 13px;
    font-weight: 500;
`;

export const TableRowDnEvolutionContainer = styled.div<{dnEvolution: number | null}>`
    color: ${p => getColorForEvolution(p.dnEvolution)};
    font-size: 11px;
    font-weight: 500;
	white-space: nowrap;
`;

interface TableRowProps {
  cursor?: string
  fontWeight?: string
  color?: string
  center?: boolean,
  padding?: string
}

const TableRow = styled.div<TableRowProps>`
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    line-height: 21px;
    text-overflow: ellipsis;
    cursor: ${p => p.cursor ? p.cursor : 'auto'};
    font-weight: ${p => p.fontWeight ? p.fontWeight : '400'};;
    color: ${p => p.color ? p.color : DarkGreySidely2};
    ${p => p.padding ? `padding: ${p.padding};` : ''}
    ${p => p.center
		? `
        display: flex;
        align-items: center;
        padding: 0 5px;
    `
		: ''}

    &:focus-visible {
        border: none;
        outline: none;
    }

    &:focus {
        border: none;
        outline: none;
    }

    &:empty {
        &:not(:focus) {
            &:before {
                content: '-';
            }
        }
    }
`;

const TableRowTitle = styled(TableRow)`
    font-weight: 500;
    color: ${DarkGreySidely};
    cursor: pointer;
    width: 100%;
    text-wrap: nowrap;
    text-align: left;
`;

const TableRowImage = styled(DefaultImage)`
    border-radius: 50%;
`;

const TableRowStatusBackground = styled.div<{noPointer?: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    ${p => p.noPointer ? '' : 'cursor: pointer;'}
`;

interface TableRowStatusProps {
  backgroundColor?: string
}

const TableRowStatus = styled.div<TableRowStatusProps>`
    background-color: ${p => p.backgroundColor ?? ''};
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
    color: white;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Footer = styled.footer``;

const FormContainer = styled.form``;

export const RowComment = styled.p`
    margin: 0;
    font-weight: 400;
    color: ${DarkGreySidely2};
    font-size: 11px;
`;

export {
	Container,
	ContainerModalLeft,
	ContainerTable,
	Footer,
	FormContainer,
	TableRow,
	TableRowTitle,
	TableRowStatus,
	TableRowStatusBackground,
	TableRowImage
};
