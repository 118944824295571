import React, { useState, ChangeEvent } from 'react';
import assert from 'assert';
import Input from '../components_v2/input/Input';
import { Field } from '../atoms/forms';
import { Checkbox } from '../components_v2/filterList/style/Style';
import DropdownOwners from '../components_v2/dropdown/DropdownOwners';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../atoms/global/users';
import { FieldType } from 'bindings/forms/FieldType';
import styled from 'styled-components';
import { BorderColor, DarkGreySidely2 } from '../styles/global/css/Utils';
import { InputStyle } from '../components_v2/input/model/Model';
import { creationPopupInputStyle } from '../containers_v2/client-companies/style/Style';
import { DateAccessor } from '../containers_v2/client-companies/data/CompanyColumns';
import Dropdown from '../components_v2/dropdown/Dropdown';
import { DropdownStyle } from '../components_v2/dropdown/model/Model';

interface FieldEditorProps {
    value: unknown | undefined;
    onChange?: (value: unknown | undefined) => void;
    onSave?: (value: unknown) => void;
    product?: ArrayBuffer;
    company?: ArrayBuffer;
    field: Field | undefined;
	inputStyle?: InputStyle;
	styledSelect?: boolean;
	autoFocus?: boolean;
}

export function stringifyValue(field: Field, value: unknown | undefined): string | undefined {
	if (value === undefined || value === null) {
		return '';
	}
	switch (field.type) {
		case 'Text':
			return value as string;
		case 'Number':
			return (value as number).toString();
		case 'Money':
			return (value as number / 100).toFixed(2);
		case 'Date':
			return value as string;
		case 'User':
			return value as string;
			// if (usersSync === undefined) {
			// 	console.warn('Stringifying user field without users loaded');
			// 	return value as string;
			// } else {
			// 	return usersSync.get(Number(value))?.name ?? (value as any).toString();
			// }
		case 'Url':
			return value as string;
		case 'Email':
			return value as string;
		case 'Phone':
			return value as string;
		case 'Select':
			return value as string;
		case 'Boolean':
			return value ? 'vrai' : 'faux'; // Todo use translate
	}
}

type ParseResult = string | boolean | number | null | string[];

export function parseValue(type: 'Number' | 'User' | 'Integer' | 'Money', value: string): number
export function parseValue(type: 'Boolean', value: string): boolean
export function parseValue(type: 'Multiselect', value: string): string[]
export function parseValue(type: 'File' | 'Image' | 'Location' | 'Dispatch' | 'CheckIn' | 'Signature' | 'V3User' | 'Geography' | 'Entity' | 'EntityList' | 'Period', value: string): null
export function parseValue(type: 'Text' | 'Select' | 'Date' | 'Url' | 'Email' | 'Phone' | 'Address' | 'Company' | 'Form' | 'TextArea', value: string): string
export function parseValue(type: FieldType, value: string): ParseResult
export function parseValue(type: FieldType, value: string): ParseResult {
	switch (type) {
		case 'Multiselect':
			return value;
		case 'Text':
			return value.trim() !== '' ? value : null;
		case 'Number':
			return value.trim() !== '' ? parseFloat(value) : null;
		case 'Money':
			return value.trim() !== '' ? (parseFloat(value) * 100).toFixed(0) : null;
		case 'Date':
			return value;
		case 'User':
			return value;
		case 'Url':
			return (new URL(value)).toString();
		case 'Email':
			assert(validateEmail(value), 'Invalid email');
			return value;
		case 'Phone':
			assert(validatePhone(value), 'Invalid phone');
			return value;
		case 'Select':
			return value.trim() !== '' ? value : null;
	}
	return null;
}

function validateEmail(email: string): boolean {
	return Boolean(String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		));
}

function validatePhone(phone: string): boolean {
	return Boolean(String(phone).match(/^(\+?\d{1,3}[- ]?)?(\d{3}[- ]?){2}\d{3}$/));
}

const Select = styled.select<{ border?: boolean }>`
    ${({ border }) => border ? `height: 50px; border: 1px solid ${BorderColor}; border-radius: 5px;` : ' border: none; width: 100%;'}
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

export default function StyledFieldEditor(props: FieldEditorProps) {
	return <RawFieldEditor inputStyle={creationPopupInputStyle} styledSelect {...props} />;
}

export function RawFieldEditor({ field, value, onChange, onSave, styledSelect, inputStyle, autoFocus }: FieldEditorProps) {
	const [changed, setChanged] = useState(false);
	const owners = useRecoilValue(AUsers);

	// if (field === undefined) {
	// 	return <div className="center">
	// 		<p>Unknown field</p>
	// 	</div>;
	// }

	function handleChanged(e: ChangeEvent<HTMLInputElement>) {
		setChanged(true);
		// onChange?.(e.currentTarget.value);
		if (onChange) {
			try {
				onChange(parseValue(field!.type, e.currentTarget.value));
			} catch {
				try {
					onChange(parseValue(field!.type, e.toString()));
				}
				catch {
					// TODO: Show red border
				}
			}
		}
	}

	function handleBlur(e: ChangeEvent<HTMLInputElement>) {
		if (changed) {
			try {
				const v = parseValue(field!.type, e.currentTarget.value as string);
				setChanged(false);
				save(v);
			} catch (e: any) {
				// toast.error(e.toString());
				console.log('Failed to parse: ', e);
			}
		}
	}

	function save(v: ParseResult) {
		try {
			onSave?.(v);
		} catch {
			// TODO: Show red border
		}
	}

	switch (field?.type) {
		case undefined:
			return <div className="center">
				<p>Unknown field</p>
			</div>;
		case 'Date':
			if (typeof value !== 'string') return <></>;
			return <DateAccessor input inputStyle={inputStyle} date={value} pickerProps={{ buttonsBottom: true }} onDateChange={date => {
				const newDate = date.toISOString();
				onChange?.(newDate);
				save(newDate);
			}} />;

		case 'TextArea':
			return <Input inputStyle={inputStyle ? { ...inputStyle, height: '80px' } : undefined} name={`INPUT_FIELD[${field.id}]`} type='text' textArea={true} value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Text':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type='text' value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Number':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="number" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Integer':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="number" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Money':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="number" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Boolean':
			return <Checkbox isActive={value as boolean} onClick={() => { onChange?.(!value); save(!value); }} />;
		case 'User': {
			const ownerDD = React.useMemo(() => owners.map((owner) => ({
				value: owner,
				label: owner.name
			}))
			, [owners]);
			
			return <DropdownOwners selected={ownerDD.find(o => o.value.id == value)} onChange={(v) => { onChange?.(v?.value?.id); save(v?.value!.id); } } users={ownerDD} />;
		}
		case 'Url':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="url" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Email':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="email" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Phone':
			return <Input inputStyle={inputStyle} name={`INPUT_FIELD[${field.id}]`} type="phone" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} autoFocus={autoFocus}/>;
		case 'Multiselect':
		{
			assert(Array.isArray(field.data), `Field ${field.name} is of type multi-select but has no data (${JSON.stringify(field.data)})`);
			const valuesDD = React.useMemo(() => (field.data as string[]).filter(v => v !== null).map((v) => ({
				value: v,
				label: v,
				checked: (value as string[] | undefined)?.includes(v)
			})), [field.data, value]);
			return <Dropdown
				name={''}
				datalist={valuesDD}
				onChange={values => { const v = values.map(e => e.value); onChange?.(v); save(v); }}
				isMulti
				checkbox
				checkboxAll
				doNotDeleteOptionOnSelect
				dropdownStyle={inputStyle as DropdownStyle}
			/>;
		}
		case 'Select':
		{
			assert(Array.isArray(field.data), `Field ${field.name} is of type select but has no data (${JSON.stringify(field.data)})`);
			// let allowedValues = undefined;
			// if (product || company && field.constraint) {
			// 	const entity = use(entities(EntityRepresentation.Map, product ? EntityType.Product : EntityType.Company)).get(product ?? company!);
			// 	assert(entity, 'Field editor has wrong field entity');
			// 	allowedValues = Array.from(field.constraint.entries()).reduce((acc, [constraintField, constraintMap]) => {
			// 		const f = entity.fields.get(constraintField);
			// 		if (!f) {
			// 			return acc;
			// 		}
			// 		assert(constraintMap.tag === 7, 'Constraint map is not a map');
			// 		assert(typeof f.val === 'string', 'Constraint field value is not a string');
			// 		const allowedValues = constraintMap.val.get(f.val);
			// 		assert(allowedValues, 'Constraint field value is not in its constraint map');
			// 		assert(allowedValues.tag === 6, 'Allowed values are not a set');
			// 		if (acc === undefined) {
			// 			return allowedValues.val.map(v => v.val);
			// 		} else {
			// 			return acc.filter(v => allowedValues.val.map(v => v.val).includes(v));
			// 		}

			// 	}, undefined as FieldValueInner[] | undefined);
			// }
			return <Select border={styledSelect} value={stringifyValue(field, value) ?? ''} onChange={(e) => { onChange?.(e.target.value); save(e.target.value); }} autoFocus={autoFocus}>
				<option value=''></option>
				{(field.data as string[]).filter(v => v !== null).map((v) => (
					<option value={v} key={v}>{v}</option>
				))}
			</Select>;
		}
		default:
			return <div className="center">
				<p>Unsupported field type {field?.type}</p>
			</div>;

	}
}
