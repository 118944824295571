import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { decodeReport, Report, ValueVals } from '../interpretor/bareReportingDecoder';
import { GenericReport } from 'bindings/reports/generic/GenericReport';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getGenericAndDecodeReport(body: GenericReport): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post<ArrayBufferLike>(`${PATH}/v2/reports/generic/query`, body, { responseType: 'arraybuffer' })
		.then(res => {
			const u8Array = new Uint8Array(res.data);
			return decodeReport(u8Array);
		});
}

export type GoalPath = {
	[key: string]: {
		value: ValueVals
		index: number,
	}
} & {date?: string }

export type GoalGoal = {
	date: string,
	value: number,
	dashboard: boolean
}

export type Goal = {
	report_id: number,
	path: GoalPath,
	column_name: string,
	goals: GoalGoal[],
	granularity: string,
}

export type GoalDelete = Omit<Goal, 'goals'> & {date: string};

export async function removeGoals(report_id: number): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	await axios.delete(`${PATH}/v2/goals/${report_id}`);
}
export async function getGoals(report_id: number): Promise<Goal[]> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.get(`${PATH}/v2/goals/${report_id}`).then(res => res.data);
}

export async function postGoals(goals: Goal[]): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post(`${PATH}/v2/goals`, goals);
}

export async function deleteGoals(goals: GoalDelete[]): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post(`${PATH}/v2/goals/delete`, goals);
}

export async function getDistinctMetadata(): Promise<string[]> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.get(`${PATH}/v2/reports/generic/metadata`).then(res => res.data);
}

export async function createNewAdditionalColumn(name: string): Promise<number> {
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.post(`${PATH}/v2/additional-columns`, { name, type: 'ReportColumn' });
	return res.data;
}

export async function updateGenericReport(id: number, body: any): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	await axios.put(`${PATH}/v2/reports/generic/${id}`, body);
}

export interface ReportResponse {
	id: number,
	name: string,
	value: Report,
}

export async function deleteGenericReport(id: number): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	await axios.delete(`${PATH}/v2/reports/generic/${id}`);
}

export async function getGenericReport(id: number): Promise<ReportResponse> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.get<ReportResponse>(`${PATH}/v2/reports/generic/${id}`)
		.then(res => res.data);
}
export async function createGenericReport(body: any): Promise<number> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.post(`${PATH}/v2/reports/generic/`, body).then(res => res.data);

}
export async function getReportPresignedUrl(report: GenericReport): Promise<string> {
	return await axios.post(`${PATH}/v2/unique-token/gen`, { value: report }).then(res => res.data.token);
}