import { BlueSidely, GreenSidely, RedSidely } from '../../../styles/global/css/Utils';

interface EventParams {
  title: string
  event_type: number
  event_status: number
  start_date: string
  end_date: string
  client_company_id?: number
  contact_id?: number
  opportunity_id?: number
  in_charge?: number
  description?: string
  event_tag?: number
  form?: number
  scheduled: boolean
}

interface Event {
  id: number
  title: string
  event_type_id: number
  event_status_id: number
  start_date: string
  end_date: string
  client_company_id?: number
  contact_id?: number
  in_charge?: number
  description?: string
}

export interface LegacyEvent {
  clientCompanyId?: number
  contactId?: number
  eventTypeId: number
  eventStatusId: number
  incharge?: number
  title?: string
  startDate: Date
  endDate: Date
  description?: string
  allDay: boolean
}

export type RepresentationMode = 'per_users' | 'per_types'

export interface CalendarMapping {
  id: number
  color: string
  label: string,
  map?: boolean
}

export const colorTypeMapping: CalendarMapping[] = [
	{ id: 1, color: GreenSidely, label: 'Task' },
	{ id: 2, color: BlueSidely, label: 'Call' },
	{ id: 3, color: '#FEA240', label: 'Meeting' },
	{ id: 4, color: '#12CBC3', label: 'Visit', map: true },
	{ id: 5, color: '#30739A', label: 'SMS' },
	{ id: 6, color: '#4886DB', label: 'Email' },
	{ id: 7, color: '#9F9FED', label: 'Animation' },
	{ id: 8, color: '#A8B5C7', label: 'Promotion' },
	{ id: 9, color: '#FEC940', label: 'Delivery', map: true },
	{ id: 10, color: '#B6784B', label: 'Order' },
	{ id: 11, color: '#d9635b', label: 'TimeOff' },
	{ id: 12, color: '#d14402', label: 'PublicHoliday' },
	{ id: 13, color: '#729e09', label: 'Session' },
	{ id: 14, color: '#10a34b', label: 'Training' },
	{ id: 15, color: '#47b0b5', label: 'TradeShow' },
	{ id: 16, color: '#0a5bab', label: 'SickLeave' },
	{ id: 17, color: '#4f4bc9', label: 'WarehouseVisit' },
	{ id: 18, color: '#5d0991', label: 'VehicleMaintenance' },
	{ id: 19, color: '#c433b8', label: 'SampleCollection' }
];

export const colorStatusMapping: CalendarMapping[] = [
	{ id: 1, color: '#FFB946', label: 'To Do' },
	{ id: 2, color: GreenSidely, label: 'Completed' },
	{ id: 3, color: RedSidely, label: 'Cancelled' }
];

export interface EventStatus {
  id: number
  name: string
  colorCode?: string
}

export interface EventType {
  id: number
  name: string
}

export {
	EventParams,
	Event
};
