import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import FilesRouteBlack from 'images/filemanager/file_menu_black.svg';
import FilesRouteWhite from 'images/filemanager/file_menu_white.svg';
import FilesRouteBlue from 'images/filemanager/file_menu_blue.svg';
import { FileManager } from '../../containers_v2/documents/filemanager';

const FileManagerRoutes = (): Route[] => [
	{
		component: FileManager,
		path: '/filemanager',
		key: 'documents',
		sectionKey: 'documents',
		logoImg: FilesRouteBlack,
		hoverImg: FilesRouteBlue,
		activeImg: FilesRouteWhite,
		permission: ['ViewDoc'],
		title: 'Documents',
		subTitle: 'Documents',
		admin: false,
		name: <Translate id="documents" />,
		restricted: false,
		menu: true,
		exact: true,
		beta: true,
	
	}
];

export default FileManagerRoutes;
