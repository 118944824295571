import { IFrequency } from 'proto/protobufs';
import { atom, selector } from 'recoil';
import { getColorForPercentage } from '../../containers_v2/reports/utils';
import { AtomCategory, AtomState } from '../utils/model/Model';

// -----------------------[ ATOM ]----------------------- //
const AGlobalFrequencyEventType: AtomState<number> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_frequency_event_type', // UNIQUE ID
		default: parseInt(localStorage.getItem('selected_frequency_event_type') ?? '4')
	})
};

export const AFrequencyEventType = selector<number>({
	key: 'AFrequencyEventType',
	get: ({ get }) => get(AGlobalFrequencyEventType.atom),
	set: ({ set }, newValue) => {
		localStorage.setItem('selected_frequency_event_type', newValue.toString());
		return set(AGlobalFrequencyEventType.atom, newValue);
	}
});

// -----------------------[ UTILS ]------------------------ //
export const FREQUENCY_SCORE_STEPS = [0, 0.25, 0.5, 0.75, 1, 2, 4, 6, 7];

export function getEmojiByFrequencyScore(score?: number): string {
	if (score === undefined) return '🤔';
	if (score < FREQUENCY_SCORE_STEPS[1]) {
		return '🤩';
	} else if (score < FREQUENCY_SCORE_STEPS[2]) {
		return '😃';
	} else if (score < FREQUENCY_SCORE_STEPS[3]) {
		return '🙂';
	} else if (score < FREQUENCY_SCORE_STEPS[4]) {
		return '😐';
	} else if (score < FREQUENCY_SCORE_STEPS[5]) {
		return '😕';
	} else if (score < FREQUENCY_SCORE_STEPS[6]) {
		return '😬';
	} else if (score < FREQUENCY_SCORE_STEPS[7]) {
		return '😨';
	} else {
		return '🤯';
	}
}


export function getAvgColorByFrequencyScore(score?: number, required?: boolean): string | undefined {
	if (score === undefined) return;
	const index =
		required
			? score >= 7 ? 8 : FREQUENCY_SCORE_STEPS.findIndex(fsc => fsc > score)
			: score > 7 ? 8 : FREQUENCY_SCORE_STEPS.findIndex(fsc => fsc > score);
	if (index <= 0) return;
	const newScore = (FREQUENCY_SCORE_STEPS[index - 1] + FREQUENCY_SCORE_STEPS[index]) / 2;
	return getColorByFrequencyScore(newScore);
}

export function getColorByFrequencyScore(score: number): string {
	return getColorForPercentage(score > 7 ? 7 : score, 1.0, score <= 1
		? [
			{ pct: 0.0, color: { r: 5, g: 119, b: 57 } },
			{ pct: 1.0, color: { r: 195, g: 252, b: 221 } }
		]
		: [
			{ pct: 1.0, color: { r: 255, g: 215, b: 212 } },
			{ pct: 7.0, color: { r: 162, g: 21, b: 9 } }
		]
	);
}

export const getDailyFrequency = (frequency: number): number => 365 / frequency;

export function getDaysDelay<T extends IFrequency>(frequency: T): number {
	if (!frequency.score || !frequency.target) return 0;
	return frequency.score * getDailyFrequency(frequency.target);
}

export function getRemainingDays<T extends IFrequency>(frequency: T): number {
	if (!frequency.score || !frequency.target) return 0;
	return getDailyFrequency(frequency.target) - getDaysDelay(frequency);
}