import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from 'react-localize-redux';
import Routes from './routes/Routes';
import store from './helpers/store';
import storeLang from './helpers/storeLang';
import { currentData } from './store/actions/user.action';
import './styles/style.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import { CookiesProvider } from 'react-cookie';
import { RecoilRoot } from 'recoil';
import PageLoader from './components_v2/pageLoader/PageLoader';
import { FlexDiv } from './containers_v2/products/style';
import { URL_API, URL_API_BUILDER, URL_FOUNDATION, URL_FOUNDATION_WS, URL_CERBERUS, URL_JANUS, URL_ALEXANDRIA, SENTRY_DSN } from './config/keys';
import * as Sentry from "@sentry/react";

console.log(`version ${process.env.GIT_COMMIT}`);

const API_REXS = [
	URL_API, URL_API_BUILDER, URL_FOUNDATION, URL_FOUNDATION_WS, URL_CERBERUS, URL_JANUS, URL_ALEXANDRIA
]
.filter(Boolean) // remove undefined
.map((u) => new RegExp(`^${u.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`))

// doc: https://docs.sentry.io/platforms/javascript/guides/react/
// upload source maps: npx @sentry/wizard@latest -i sourcemaps --saas
Sentry.init({
	dsn: SENTRY_DSN,
	release: process.env.GIT_COMMIT,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 0.8, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: API_REXS,
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	beforeSend(event) {
		if (event.exception && event.exception.values) {
			const errorMessage = event.exception.values[0]?.value || "";
			if (errorMessage.includes("401") || errorMessage.includes("Unauthorized")) {
				return null; // Ignore authentication failures
			}
		}
		return event;
	},
});

const history = createBrowserHistory();

store.dispatch(currentData());

ReactDOM.render(
	<RecoilRoot>
		<React.Suspense fallback={<FlexDiv height='100vh' width='100vw' justify='center'><PageLoader /></FlexDiv>}>
			<CookiesProvider>
				<LocalizeProvider store={storeLang}>
					<Provider store={store}>
						<Router history={history}>
							<div>{renderRoutes(Routes)}</div>
						</Router>
					</Provider>
				</LocalizeProvider>
			</CookiesProvider>
		</React.Suspense>
	</RecoilRoot>,
	document.getElementById('root')
);
