import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import CheckboxGroupFilter from '../../containers/companies/components/CheckboxGroupFilter';
import storeLang from '../../helpers/storeLang';
import { EventsTypes, EventsTypesUtils } from './Calendar';
import { ButtonToggle } from '../../styles/global/css/Open';
import { FlexDiv } from '../products/style';
import { CalendarModalSubContainer, CalendarModalTitle } from './style/style';
import ListPicker from '../../components_v2/listPicker/listPicker';
import { colorTypeMapping, RepresentationMode } from './model/Model';
import { Owner } from '../orders/model/Model';
import { Dot } from '../../styles/global/css/Dot';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { sortOwners } from '../../components_v2/dropdown/DropdownOwners';
import { UserLabel } from '../../components_v2/dropdown/style/Style';
import Collapse from '../../components_v2/Collapse/Collapse';
import Left from 'images/icon/left.png';
import Right from 'images/icon/right.png';
import { initialCalendarFilters } from './CalendarView';
import { BlueSidely } from '../../styles/global/css/Utils';

const REPRESENTATION_MODES: Array<[RepresentationMode, string]> = [
	['per_types', 'type'],
	['per_users', 'User']
];

export default function ModalLeft(props: {
  handleFilter: (key: string, value?: any) => void,
  onRepresentationChange: (value: RepresentationMode) => void
  colors?: Array<{ owner: Owner, color: string }>
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const types = [
		{ label: translate('event.events'), value: EventsTypes.Event },
		{ label: translate('campagnes'), value: EventsTypes.Promotion },
		{ label: translate('tool_bar.shelf_audits'), value: EventsTypes.ShelfAudit },
		{ label: translate('tool_bar.free_forms'), value: EventsTypes.FreeForm },
		{ label: translate('orders_'), value: EventsTypes.Order },
		{ label: translate('check_in'), value: EventsTypes.CheckIn },
		{ label: translate('forms'), value: EventsTypes.Form },
	];
	const [isOpen, setIsOpen] = React.useState(false);
	const [activeCollapse, setActiveCollapse] = React.useState<string[]>(['elements']);
	const localStorageEventFiltersStatus = localStorage.getItem('eventFiltersStatus');
	const [statusCheckBox, setStatusCheckBox] = React.useState<EventsTypes[]>(localStorageEventFiltersStatus ? JSON.parse(localStorageEventFiltersStatus) : initialCalendarFilters(false).types);
	const [selectedRepresentation, setSelectedRepresentation_] = React.useState<RepresentationMode>(REPRESENTATION_MODES[0][0]);
	const [activeTab, setActiveTab] = React.useState<number>(1);

	const setSelectedRepresentation = (value: RepresentationMode) => {
		setSelectedRepresentation_(value);
		props.onRepresentationChange(value);
	};

	function toggle(type: string) {
		const active = [...activeCollapse];
		if (active.includes(type)) {
			active.splice(active.indexOf(type), 1);
		} else {
			active.push(type);
		}
		setActiveCollapse(active);
	}

	function buttonToggle(title: string, key: string) {
		return (
			<ButtonToggle
				isOpen={activeCollapse.includes(key)}
				onClick={() => toggle(key)}
				title={title}
				key={key}
			/>
		);
	}

	function handleCheckedStatus(isChecked: boolean, value: EventsTypes | undefined) {
		let newValue = statusCheckBox;
		if (value !== undefined) {
			if (isChecked) {
				newValue.push(value);
			} else {
				const index = newValue.indexOf(value);
				if (index >= 0) { newValue.splice(index, 1); }
			}
		} else {
			if (isChecked) {
				newValue = types.map(e => e.value);
			} else {
				newValue = [];
			}
		}
		localStorage.setItem('eventFiltersStatus', JSON.stringify(newValue));
		setStatusCheckBox([...newValue]);
		props.handleFilter('types', newValue);
	}

	function handleReset() {
		setStatusCheckBox(initialCalendarFilters(false).types);
		props.handleFilter('reset');
	}

	return (
		<div className='filter-modal'
			style={{ marginTop: '52px', height: 'calc(100vh - 107px)', overflow: 'visible', zIndex: 5, left: '0' }}>
			<div className={isOpen ? 'box-filter-modal' : 'd-none'} style={{ width: '275px' }}>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={activeTab === 1 ? 'active' : ''}
							onClick={() => setActiveTab(1)}
						>
							<Translate id="events" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={activeTab === 2 ? 'active' : ''}
							onClick={() => setActiveTab(2)}
						>
							<Translate id="Filters" />
						</NavLink>
					</NavItem>
				</Nav>
				{activeTab == 1 &&
					<FlexDiv flow='column' gap='50px' padding='20px 0 0 0 '>
						<div style={{ width: '100%' }}>
							<CalendarModalSubContainer>
								<CalendarModalTitle>
									<Translate id='event_representation' />
								</CalendarModalTitle>
							</CalendarModalSubContainer>
							{REPRESENTATION_MODES.map(([mode, translation], index) => <ListPicker
								key={`ModalLeftCalendarREPRESENTATION_MODES[${index}]`}
								selected={selectedRepresentation == mode}
								value={translate(translation).toString()}
								onSelect={() => setSelectedRepresentation(mode)}
							/>)}
						</div>
						<CalendarModalSubContainer>
							<CalendarModalTitle>
								<Translate id='event_legend' />
							</CalendarModalTitle>
							{selectedRepresentation == 'per_users' && props.colors?.sort((a, b) => sortOwners(a.owner, b.owner)).map((c, i) => <FlexDiv key={`modalLeftCalendarUser[${i}]`} gap='20px'>
								<Dot color={c.color} size='10px' />
								<UserLabel me={translate('me').toString()} user={c.owner}>{c.owner.name}</UserLabel>
							</FlexDiv>)}
							{selectedRepresentation == 'per_types' &&
								<>
									{statusCheckBox.includes(EventsTypes.Event) && colorTypeMapping.map((mapping, i) => <FlexDiv key={`modalLeftCalendarEventColor[${i}]`} gap='20px'>
										<Dot color={mapping.color} size='10px' />
										<DefaultTextDiv><Translate id={`event.${mapping.label}`} /></DefaultTextDiv>
									</FlexDiv>)
									}
									{statusCheckBox.filter(e => e !== EventsTypes.Event && e !== EventsTypes.Promotion).map(et => <>
										<FlexDiv gap='20px'>
											<Dot color='black' size='10px' style={{ outline: `${colorTypeMapping[et - 1].color} solid 4px` }} />
											<DefaultTextDiv><Translate id={EventsTypesUtils.toTranslateString(et)} /></DefaultTextDiv>
										</FlexDiv>
									</>)}
								</>
							}
							{statusCheckBox.includes(EventsTypes.Promotion) && 
								<FlexDiv gap='20px'>
									<Dot color='white' size='10px' style={{ outline: `${BlueSidely} solid 2px` }} />
									<DefaultTextDiv><Translate id='campagne' /></DefaultTextDiv>
								</FlexDiv>
							}
						</CalendarModalSubContainer>
					</FlexDiv>
				}
				{activeTab == 2 &&
					<FlexDiv flow='column' gap='50px' padding='20px 0 0 0 '>
						<CalendarModalSubContainer>
							<FlexDiv justify='space-between' width='100%' margin='0 0 20px 0'>
								<CalendarModalTitle noMargin>
									<Translate id='Quick filters' />
								</CalendarModalTitle>
								<button
									type='button'
									className='btn btn-primary-outline'
									style={{
										padding: '5px 15px',
										borderRadius: 5
									}}
									onClick={() => {
										handleReset();
									}}
								>
									<Translate id='Reset All' />
								</button>
							</FlexDiv>
							<div style={{ width: '100%' }}>
								{buttonToggle('global.elements', 'elements')}
								<Collapse isOpen={activeCollapse.includes('elements')}>
									<div style={{ maxWidth: '260px' }}>
										<CheckboxGroupFilter
											options={types}
											valChecked={statusCheckBox}
											input={{ value: statusCheckBox, onChange: () => null }}
											checkedAll={
												statusCheckBox && statusCheckBox.length === types.length
											}
											handleCheckedStatus={handleCheckedStatus}
											placeholder='Search status'
											type='status'
											meta={{ touched: types.length == 0, error: 'Error fetching status data' }}
										/>
									</div>
								</Collapse>
							</div>
						</CalendarModalSubContainer>
					</FlexDiv>
				}
			</div>
			<button
				type='button'
				className='btn btn-filter-modal'
				onClick={() => setIsOpen(!isOpen)}
			>
				<img
					src={isOpen ? Left : Right}
					alt=''
				/>
			</button>
		</div>
	);
}
