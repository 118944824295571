/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { getBase64 } from '../import/UploadFile';
import readXlsxFile from 'read-excel-file';
import { launchToastError } from '../forms/visual-form-editor/visualFormEditor';
import { toast } from 'react-toastify';
import { usePapaParse } from 'react-papaparse';
import { translateToString } from '../../styles/global/translate';
import { BlueSidely } from '../../styles/global/css/Utils';
import moment from 'moment';
import { importPromotionsCall } from './actions';
import { Product } from '../../atoms/product';
const { readString } = usePapaParse();

export const ADDITIONAL_COLUMN_TYPE_LST = ['campaign_id', 'is_period', 'name', 'is_active', 'description', 'color', 'start_date', 'end_date', 'product_id', 'product_barcode'] as const;
type Fields = { [key: number ]: unknown };
type AdditionalColumnTypeLst = typeof ADDITIONAL_COLUMN_TYPE_LST[number];
type HeaderValue = AdditionalColumnTypeLst | { field: number, period?: string } | { period: string, type: AdditionalColumnTypeLst };
type Campaign = { id?: number, name: string, is_active: boolean, description?: string, fields?: Fields };
type Period = { name: string, color: string, start: moment.Moment, end: moment.Moment, fields?: Fields };
type PeriodCampaignAndProduct = (Campaign | Period) & { product_id?: number };
type Row = Partial<Campaign> & Partial<Period> & { is_period?: boolean, product_id?: number };
type ImportCampaignAndPeriodsMaps = Campaign & { periods: Map<string, Period>, products: Set<number> };
export type ImportCampaignAndPeriods = Campaign & { periods: Period[], products?: number[] };

function parseHeaders(headers: unknown[]): Map<number, HeaderValue> {
	return headers.reduce<Map<number, HeaderValue>>((acc, header, index) => {
		if (typeof header === 'string') {
			const splitted = header.split(':');
			const type = splitted[0].trim();
			if (ADDITIONAL_COLUMN_TYPE_LST.includes(type as any)) {
				if (splitted.length > 1) {
					acc.set(index, { period: splitted[1].trim(), type: type as AdditionalColumnTypeLst });
				} else {
					acc.set(index, type as HeaderValue);
				}
				return acc;
			}
			const field = parseInt(header);
			if (!isNaN(field)) {
				acc.set(index, { field, period: splitted.length > 1 ? splitted[1].trim() : undefined });
				return acc;
			}
			return acc;
		}
		if (typeof header === 'number') {
			acc.set(index, { field: header });
			return acc;
		}
		return acc;
	}, new Map());
}

function columnErrorThrower(error: string, headerIndex: number) {
	throw `${error} Column ${headerIndex + 1}`;
}

function errorThrower(name: string, value: unknown, expectedType: string, headerIndex: number) {
	throw columnErrorThrower(`Expected '${expectedType}' for ${name} got '${typeof value}'.`, headerIndex);
}

function missingField<T>(value: T | undefined, fieldName: string): T {
	if (value == undefined) throw 'Missing mandatory field ' + fieldName;
	return value;
}

function rowToCampaignOrPeriod(row: Row): PeriodCampaignAndProduct {
	if (row.is_period) {
		return {
			name: missingField(row.name, 'name'),
			color: row.color ?? BlueSidely,
			start: missingField(row.start, 'start_date'),
			end: missingField(row.end, 'end_date'),
			fields: row.fields,
			product_id: row.product_id,
		};
	}
	return {
		id: row.id,
		name: missingField(row.name, 'name'),
		is_active: row.is_active ?? true,
		description: row.description,
		fields: row.fields,
		product_id: row.product_id,
	};
}


function parseRows(headers: Map<number, HeaderValue>, rows: unknown[], productBarcodeMap: Map<string, number>, productIdSet: Set<number>): PeriodCampaignAndProduct[] {
	function switchHeader(res: Row, value: unknown, header: HeaderValue, headerIndex: number, pushRes = false): Row[] {
		switch (header) {
			case 'product_id':
				if (typeof value === 'number') {
					if (!productIdSet.has(value)) {
						throw columnErrorThrower(`Product id ${value} does not exist.`, headerIndex);
					}
					res.product_id = value;
				} else {
					errorThrower('product_id', value, 'number', headerIndex);
				}
				break;
			case 'product_barcode':
				if (typeof value === 'string' || typeof value === 'number') {
					const s = value.toString();
					if (!productBarcodeMap.has(s)) {
						throw columnErrorThrower(`Product barcode ${value} does not exist.`, headerIndex);
					}
					res.product_id = productBarcodeMap.get(s);
				} else {
					errorThrower('product_barcode', value, 'string', headerIndex);
				}
				break;
			case 'campaign_id':
				if (typeof value === 'number') {
					res.id = value;
				} else {
					errorThrower('campaign_id', value, 'number', headerIndex);
				}
				break;
			case 'is_period':
				if (typeof value === 'boolean') {
					res.is_period = value;
				} else {
					errorThrower('is_active', value, 'boolean', headerIndex);
				}
				break;
			case 'name':
				if (typeof value === 'string') {
					res.name = value;
				} else {
					errorThrower('name', value, 'string', headerIndex);
				}
				break;
			case 'is_active':
				if (typeof value === 'boolean') {
					res.is_active = value;
				} else {
					errorThrower('is_active', value, 'boolean', headerIndex);
				}
				break;
			case 'description':
				if (typeof value === 'string') {
					res.description = value;
				} else {
					errorThrower('description', value, 'string', headerIndex);
				}
				break;
			case 'color':
				if (typeof value === 'string') {
					res.color = value;
				} else {
					errorThrower('color', value, 'string', headerIndex);
				}
				break;
			case 'start_date':
				if (value instanceof Date || typeof value == 'number' || typeof value === 'string') {
					res.start = moment(value);
				} else {
					errorThrower('start_date', value, 'string', headerIndex);
					break;
				}
				if (res.start?.isValid() === false) {
					throw columnErrorThrower(`Invalid date ${value} please use ISO 8601 format.`, headerIndex);
				}
				if (res.start && res.end && res.start.isAfter(res.end)) {
					throw 'End date is before start date';
				}
				break;
			case 'end_date':
				if (value instanceof Date || typeof value == 'number' || typeof value === 'string') {
					res.end = moment(value);
				} else {
					errorThrower('end_date', value, 'string', headerIndex);
					break;
				}
				if (res.end?.isValid() === false) {
					throw columnErrorThrower(`Invalid date ${value} please use ISO 8601 format.`, headerIndex);
				}
				if (res.start && res.end && res.start.isAfter(res.end)) {
					throw 'End date is before start date';
				}
				break;
			default:
				if ('field' in header) {
					if (header.period !== undefined) {
						if (pushRes) {
							return [res, ...switchHeader({ name: header.period, is_period: true }, value, { field: header.field }, headerIndex, true)];
						} else {
							return switchHeader({ name: header.period, is_period: true }, value, { field: header.field }, headerIndex, true);
						}
					}
					if (res.fields == undefined) res.fields = {};
					res.fields[header.field] = value;
				} else {
					if (pushRes) {
						return [res, ...switchHeader({ name: header.period, is_period: true }, value, header.type, headerIndex, true)];
					} else {
						return switchHeader({ name: header.period, is_period: true }, value, header.type, headerIndex, true);
					}
				}
				break;
		}
		if (pushRes) return [res];
		return [];

	}

	return rows.reduce<PeriodCampaignAndProduct[]>((acc, row, rowIndex) => {
		try {
			if (rowIndex === 0) return acc;
			if (!Array.isArray(row)) {
				throw `Expected array got ${typeof row}`;
			}
			if (row.length == 0 || (row.length == 1 && !row[0])) return acc;
			const res: Row = {};
			const additionalRows: Row[] = [];
			for (const [headerIndex, header] of headers.entries()) {
				let value = row[headerIndex];
				if (value === undefined || value === '' || value === null) continue;
				try {
					value = JSON.parse(value as string);
				} catch (e) { /* empty */ }
				additionalRows.push(...switchHeader(res, value, header, headerIndex));
			}
			acc.push(rowToCampaignOrPeriod(res));
			acc.push(...mergeAdditionalRows(additionalRows).map(rowToCampaignOrPeriod));
		} catch (e) {
			throw `Import error: ${e} line ${rowIndex}`;
		}
		return acc;
	}, []);
}

function mergeAdditionalRows(additionalRows: Row[]): Row[] {
	const nameMap = new Map<string, Row>();
	additionalRows.forEach(row => {
		if (row.is_period) {
			const name = missingField(row.name, 'name');
			if (nameMap.has(name)) {
				const existing = nameMap.get(name)!;
				let newMap: Fields | undefined = undefined;
				if (existing.fields !== undefined || row.fields !== undefined) {
					newMap = { ...existing.fields, ...row.fields };
				}
				Object.assign(existing, row);
				existing.fields = newMap;
			} else {
				nameMap.set(name, row);
			}
		}
	});
	return [...nameMap.values()];
}

function transformData(data: unknown[], productBarcodeMap: Map<string, number>, productIdSet: Set<number>): ImportCampaignAndPeriods[] {
	try {
		if (!Array.isArray(data[0])) throw 'Expected array headers got ' + typeof data[0];
		const headers = parseHeaders(data[0]);
		const rows = parseRows(headers, data, productBarcodeMap, productIdSet);
		return rows.reduce<ImportCampaignAndPeriodsMaps[]>((acc, row, index) => {
			if ('is_active' in row) {
				if (acc.length !== 0 && acc[acc.length - 1].name === row.name) {
					if (row.product_id) {
						const lastElement = acc[acc.length - 1];
						lastElement.products.add(row.product_id!);
					}
					return acc;
				}
				acc.push({ ...row, periods: new Map(), products: row.product_id ? new Set([row.product_id!]) : new Set() });
			} else {
				if (acc.length === 0) throw 'Period does not have matching campaign. Line ' + (index + 1);
				if (acc[acc.length - 1].periods.has(row.name)) return acc;
				acc[acc.length - 1].periods.set(row.name, row);
			}
			return acc;
		}, []).map((c) => ({ ...c, periods: [...c.periods.values()], products: [...c.products] }));
	} catch (e) {
		launchToastError(e);
		return [];
	}
}

async function transformAndImportData(data: unknown[], productBarcodeMap: Map<string, number>, productIdSet: Set<number>): Promise<void> {
	const campaigns = transformData(data, productBarcodeMap, productIdSet);
	if (campaigns.length === 0) return;
	return importPromotionsCall(campaigns);
}

export default function importPromotions(onImport: () => void, products: Product[]) {
	const productBarcodeMap = new Map(products.filter(p => p.barcode).map(p => [p.barcode!, p.id]));
	const productIdSet = new Set(products.map(p => p.id));
	const input = document.createElement('input');
	input.type = 'file';
	input.accept = '.xlsx,.csv';
	input.onchange = async(e) => {
		const file = (e.target as HTMLInputElement).files![0];
		getBase64(file, async loadedFile => {
			if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				readXlsxFile(loadedFile).then((res) => transformAndImportData(res, productBarcodeMap, productIdSet).then(onImport));
			} else {
				readString(loadedFile, {
					complete: (res) => {
						if (res.errors.length !== 0) {
							toast.error((translateToString('import.csv_error.' + res.errors[0].type) as string).replace('{{ ROW }}', (res.errors[0].row + 1).toString()).replace('{{ SEPARATOR }}', res.meta.delimiter), {
								position: 'top-right',
								autoClose: 4000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							});
							return;
						}
						transformAndImportData(res.data, productBarcodeMap, productIdSet).then(onImport);
					},
					worker: true
				});
			}
		});
	};
	input.click();
}