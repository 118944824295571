import * as React from 'react';
import styled from 'styled-components';
import { GetFile, GetFolder, confirmFileManagerPost, deleteFile, deleteFolder, folderHasFiles, getAllFromFileManagerPath, getFoldersFromPath, getSingleFileFromPath, getUsage, postFileManagerGetUrl, postToS3, putFile, renameFolder } from './actions';
import InputFile from '../../components_v2/input/InputFile';
import Sha256 from 'sha256-wasm';
import { toast } from 'react-toastify';
import deleteImage from 'images/icon/delete.png';
import homeImage from 'images/icon/home.svg';
import useAlert from '../alert/UseAlert';
import downloadImage from 'images/icons/Down.svg';
import editImpage from 'images/icon/edit.png';
import threeDots from 'images/icon/three_dots.svg';
import { AlertRes } from '../alert/AlertProvider';
import { ProgressBar } from 'react-bootstrap';
import { FlexDiv } from '../products/style';
import { BlueSidely, LightBlueSidely } from '../../styles/global/css/Utils';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import { ToolbarState } from '../globals/mainPage/mainPage';
import Dropdown from '../../components_v2/dropdown/Dropdown';

import FolderImage from 'images/filemanager/folder.svg';
import NewFolderImage from 'images/filemanager/new_folder.svg';

import NewFileImage from 'images/filemanager/new_file.svg';
import FileBlueImage from 'images/filemanager/file_blue.svg';
import FileOrangeImage from 'images/filemanager/file_orange.svg';
import FileGreenImage from 'images/filemanager/file_green.svg';
import FileSalmonImage from 'images/filemanager/file_salmon.svg';


import { Translate, translateToString } from '../../styles/global/translate';
import { FileResult } from '../../components_v2/input/model/Model';
import { uploadFile } from './utils';
import { set } from 'lodash';
import { DropdownData } from '../../components_v2/dropdown/model/Model';

const MAX_FILES_LIMIT_IN_MO = 200;

const GedBody = styled.div`
	display: flex;
	background-color: #f5f5f5;
	width: 100%;
	min-height: calc(100vh - 250px);
	margin-left: 10px;
	padding-bottom: 20px;
	flex-wrap: wrap;
`;

const Footer = styled.div`
	width: 100%;
	margin-top: 45px;
	margin-left: 10px;
	padding-bottom: 20px;
`;

const FileLabel = FlexDiv.extend`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 120px;
  text-align: center;
  word-wrap: break-word;
  position: relative;
  top: 80px;
  height: 55px;
`;

const FolderLabel = FileLabel.extend`
	//top: 80px;
`;

const Home = styled.img `
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: -2px;
`;


const ProgressBarStyle = styled.div`
	font-size: 11px;
	padding-right: 20px;
	.progress .progress-bar {
		background-color: ${BlueSidely};
		width: 100%;
	}
	.progress {
		width: 200px;
		height: 5px !important;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	height: 80px;
	width: 120px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 50px;
	position: relative;
`;

const Folder = styled.div`
	height: 80px;
	width: 80px;
	background-image: url(${FolderImage});
	background-repeat: no-repeat;
	background-size: 100%;
	position: absolute;
	top: 9.5px;
	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
`;
interface FileProps {
	file: string;
}

const File = styled.div<FileProps>`
	height: 80px;
	width: 80px;
	background-image: url(${(props) => props.file});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow-wrap:  break-word;
	position: absolute;
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
`;

const NewFile = Folder.extend`
	background-image: url(${NewFileImage});
	height: 25px;
	width: 25px;
	display: inline-block;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
	position: relative;
	top: 0px;
`;

const DropDownContainer = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	opacity: 0.3;
	&:hover {
		cursor: pointer;
		opacity: 1;
	}
`;


const NewFolder = Folder.extend`
	height: 25px;
	width: 25px;
	display: inline-block;
	background-image: url(${NewFolderImage});
	margin-right: 10px;
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
	position: relative;
	top: 0px;
`;

const UploadBody = styled.div`
	display: flex;
	width: 100%;
	min-height: calc(100vh - 300px);
	margin-left: 10px;
	padding-bottom: 20px;
	flex-wrap: wrap;
	background-image: url(${NewFileImage});
	background-position: center;
	background-size: 12%;
	background-repeat: no-repeat;

`;

const BreadCrumbItem = styled.a`
	cursor: pointer;
	padding: 8px;
  	border-radius: 13px;
	&:hover {
		background-color: ${LightBlueSidely};
	}
`;

const whiteListedExtensions: string[] = [
	// Images
	'.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp',
	// Documents
	'.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.rtf', '.odt', '.ods', '.odp',
	// Autres formats utiles
	'.csv', '.ics', '.vcf', '.json', '.xml'
	// // Audio
	// '.mp3', '.wav', '.ogg', '.m4a',
	// // Vidéo
	// '.mp4', '.mov', '.avi', '.mkv', '.webm',
];

function clickDownload(blob, filename) {
	const a = document.createElement('a');
	a.download = filename;
	a.href = blob;
	a.click();
	a.remove();
}


function launchToastError(message: string) {
	toast.error(message, {
		position: 'top-right',
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}

export function FileManager(props: {setToolBarState: (value: ToolbarState) => void}): JSX.Element {
	const [files, setFiles] = React.useState<GetFile[]>([]);
	const [currentPath, setCurrentPath] = React.useState<string>('/');
	const [folders, setFolders] = React.useState<GetFolder[]>([]);
	const alert = useAlert();
	const [usage, setUsage] = React.useState<number>(0);
	const [update, setUpdate] = React.useState<number>(0);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADED);
	const [dragging, setDragging] = React.useState(false);
	const reader = new FileReader();

	const img = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg'];
	const pres = ['.pdf', '.ppt', '.pptx', '.odp'];
	const sheets = ['.xls', '.xlsx', '.ods', '.csv', '.ics', '.vcf', '.json', '.xml'];
	//const doc = ['.doc', '.docx', '.txt', '.rtf', '.odt']; everything else

	function isFileType(filename: string, extensions: string[]): boolean {
		return extensions.some(ext => filename.toLowerCase().endsWith(ext));
	}

	function selectSVG(filename: string) {
		if (isFileType(filename, pres)) {
			return FileSalmonImage;
		}
		if (isFileType(filename, img)) {
			return FileOrangeImage;
		}
		if (isFileType(filename, sheets)) {
			return FileGreenImage;
		}
		return FileBlueImage;
	}

	React.useEffect(() => {
		getFoldersFromPath(currentPath).then(f => {
			setFolders(f);
		});
	}, [update, currentPath]);

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getAllFromFileManagerPath(currentPath).then(f => setFiles(f));
		getUsage().then(u => setUsage((u / 1024 / 1024)));
		setLoadingState(LoadingStateEnum.LOADED);

		props.setToolBarState({
			title: translateToString('file_manager.files'),
			bottomLeftToolbarComponent: <>
				
				<BreadCrumbItem onClick={() => setCurrentPath('/')}>
					<Home src={homeImage}/>/
				</BreadCrumbItem>
				{currentPath.split('/').map((breadcrumb, index) => {
					return <>
						{index !== 0 && index + 1 !== currentPath.split('/').length &&
							<>
								<BreadCrumbItem
									onClick={() => {
									// rebuild path using index
										let newPath = '/';
										for (let i = 1; i <= index; i++) {
											newPath += currentPath.split('/')[i] + '/';
										}
										setCurrentPath(newPath);
									}}
								>
									{breadcrumb}
								</BreadCrumbItem>
								{index !== 0 && <>/</>}
							</>
						}
					</>;
				})}
			</>,
			bottomRightToolbarComponent: <>
				<NewFolder
					onClick={
						() => {
							const newFolder = prompt(translateToString('file_manager.enter_new_folder_name'));
							if (newFolder) {
								if (!folders.find((f) => f.folder_name === newFolder)) {
									setLoadingState(LoadingStateEnum.LOADING);
									confirmFileManagerPost({ path: currentPath + newFolder + '/', filename: newFolder, hash: '', filesize: 0 }).then(() => setUpdate(update + 1));
								}
								else {
									launchToastError(translateToString('file_manager.directory_already_exist'));
								}
							}
						}
					}
				/>
				<NewFile>
					<InputFile 
						hideAddIcon={true}
						limitMo={Math.round((MAX_FILES_LIMIT_IN_MO - usage) * 100) / 100}
						onChange={(file) => {
							if (files.find(f => f.filename === file.file.name)) {
								launchToastError(translateToString('file_manager.file_already_exist'));
								return;
							}
							if (!whiteListedExtensions.some(str => file.file.name.toLowerCase().endsWith(str))) {
								launchToastError(translateToString('file_manager.filename_forbidden'));
								return;
							}

							setLoadingState(LoadingStateEnum.LOADING);
							let hash: string | undefined = undefined;
							if (!Sha256.WASM_SUPPORTED) {
								console.log('WebAssembly not supported by your runtime');
							} else {
								hash = Sha256().update(file.content).digest('hex');
							}
							if (hash) {
								if (!files.find(f => f.filename === file.file.name)) {
									uploadFile(file, hash, currentPath).then(msg => {
										setUpdate(update + 1);
									});
								}
							}
						}}
						onError={e => launchToastError(e.message)}
					/>
				</NewFile>
			</>
		});

	}, [currentPath, update, folders]);

	const currentFolder = currentPath.split('/')[currentPath.split('/').length - 2];

	return <>
		<ComponentLoader loadingState={loadingState} allScreen />

		{dragging && <UploadBody
			onDragOver={
				(ev) => {
					ev.preventDefault();
				}
			}
			onDragLeave={
				(ev) => {
					ev.preventDefault();
					setDragging(false);
				}
			}
			onDrop={
				async(ev) => {
					ev.preventDefault();
					if (ev.dataTransfer.files[0].size === 0) {
						launchToastError('Vous ne pouvez pas envoyer de dossiers.');
						setDragging(false);
						return;
					}

					ev.dataTransfer.files;

					let totalSize = 0;
					for (let i = 0; i < ev.dataTransfer.files.length; i++) {
						totalSize += ev.dataTransfer.files[i].size;
					}

					if (MAX_FILES_LIMIT_IN_MO && parseFloat(((totalSize / 1024) / 1024).toFixed(4)) > MAX_FILES_LIMIT_IN_MO) {
						launchToastError('Vous avez dépassé la limite de stockage de ' + MAX_FILES_LIMIT_IN_MO + 'Mo. Veuillez supprimer des fichiers pour continuer. ');
						setDragging(false);
						return;
					}

					setLoadingState(LoadingStateEnum.LOADING);

					const uploadPromises: Promise<string>[] = [];
					for (let i = 0; i < ev.dataTransfer.files.length; i++) {
						const file = ev.dataTransfer.files[i];
						console.log('file:', file);
						if (file != null) {
							const errorFilename = (messageKey) => {
								launchToastError(translateToString(messageKey));
								setLoadingState(LoadingStateEnum.LOADED);
								setDragging(false);
							};
							if (files.some(f => f.filename === file.name)) {
								errorFilename('file_manager.file_already_exist');
								continue;
							}
							if (!whiteListedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))) {
								errorFilename('file_manager.filename_forbidden');
								continue;
							}
							const url = URL.createObjectURL(file);

			

							const p = new Promise(() => {
								const reader = new FileReader();
								reader.onload = async evt => {
									console.log('LAUNCH ON LOADs');
									const tab = evt.target?.result?.toString().split(',');
									const contents = (tab != null) ? tab[1] : '';
									const contentType = (tab != null) ? tab[0] : '';
									const f: FileResult = {
										file,
										content: contents,
										contentType,
										url
									};

									let hash: string | undefined = undefined;
									if (!Sha256.WASM_SUPPORTED) {
										console.log('WebAssembly not supported by your runtime');
									} else {
										hash = Sha256().update(f.content).digest('hex');
									}
									if (hash) {
										uploadFile(f, hash, currentPath).then(msg => {
											console.log('test &', msg);
											setUpdate(update => update + 1);
											setDragging(false);
										});
									}
								};
								reader.readAsDataURL(file);
							});
							uploadPromises.push(p as Promise<string>);
						}
					}
					await Promise.all(uploadPromises);

				}
			}
		>
		</UploadBody>}
		{!dragging && <GedBody
			onDragOver={
				(ev) => {
					ev.preventDefault();
					setDragging(true);
				}
			}
		>

			{ // map folders and filter out current folder
				folders.filter(folder => folder.path !== currentPath + currentFolder).map((folder, index) =>
					<Item key={`file${index}`}>
						<Folder
							key={index}
							onClick={() => {
								setCurrentPath(folder.path);
							}}
						>
						</Folder>
						<FolderLabel>{folder.folder_name}</FolderLabel>
						<DropDownContainer>
							<Dropdown
								datalist={[{ label: translateToString('edit'), value: 'edit', image: editImpage }, { label: translateToString('delete'), value: 'delete', image: deleteImage }]}
								name='dropdown_actions'
								searchable={false}
								readOnly
								JSXButton={() => (
									<img
										src={threeDots}
										className="custom-icon"
										style={{ marginTop: '3px' }}
										alt=""
									/>
								)}
								dropdownStyle={{
									optionWidth: '200px',
									optionLeft: '0px',
									margin: '0 0 0 auto'
								}}
								onChange={(value: DropdownData) => {
									switch (value.value) {
										case 'edit':
											// eslint-disable-next-line no-case-declarations
											const newName = prompt(translateToString('file_manager.enter_new_folder_name'));
											if (newName) {
												setLoadingState(LoadingStateEnum.LOADING);
												renameFolder(folder.uuid, newName, folder.path).then(() => {
													setUpdate(update => update + 1);
												}).catch(() => {
													setLoadingState(LoadingStateEnum.ERROR);
													toast.error(translateToString('error'));
												});
											}
											break;
										case 'delete':
											alert({
												title: translateToString('file_manager.deleting_folder'),
												content: translateToString('file_manager.delete_folder_sure'),
												mode: 'delete'
											}).then(res => {
												if (res === AlertRes.Ok) {
													setLoadingState(LoadingStateEnum.LOADING);
													deleteFolder(folder.uuid, folder.path).then(() => {
														setUpdate(update => update + 1);
													});
												}
											});	
											break;
										default:
											break;
									}
								}}
							/>
						</DropDownContainer>
					</Item>)}
			{files.map((file, index) =>
				<Item key={`file${index}`}>
					<File file={selectSVG(file.filename)} onClick={() => {
						alert({
							title: translateToString('file_manager.file_download'),
							content: translateToString('file_manager.are_you_sure_download') + file.filename + ' ?',
						}).then(res => {
							if (res === AlertRes.Ok) {
								setLoadingState(LoadingStateEnum.LOADING);
								getSingleFileFromPath(file.path + file.hash).then(f => {
									if (f.url) {
										fetch(f.url, {
											headers: new Headers({
												'Origin': location.origin,
											}),
											mode: 'cors'
										})
											.then(response => response.blob())
											.then(blob => {
												const blobUrl = window.URL.createObjectURL(blob);
												clickDownload(blobUrl, f.filename);
												setLoadingState(LoadingStateEnum.LOADED);
											})
											.catch(e => console.error(e));
									}
								});
							}
						});
					}}/>
					<FileLabel>{file.filename}</FileLabel>
					<DropDownContainer>
						<Dropdown
							datalist={[{ label: translateToString('edit'), value: 'edit', image: editImpage }, { label: translateToString('delete'), value: 'delete', image: deleteImage }]}
							name='dropdown_actions'
							searchable={false}
							readOnly
							JSXButton={() => (
								<img
									src={threeDots}
									className="custom-icon"
									style={{ marginTop: '3px' }}
									alt=""
								/>
							)}
							dropdownStyle={{
								optionWidth: '200px',
								optionLeft: '0px',
								margin: '0 0 0 auto'
							}}
							onChange={(value: DropdownData) => {
								switch (value.value) {
									case 'edit':
										// eslint-disable-next-line no-case-declarations
										const newName = prompt(translateToString('file_manager.enter_new_filename'));
										if (newName) {
											setLoadingState(LoadingStateEnum.LOADING);
											putFile({ uuid: file.uuid, filename: newName, path: file.path }).then(() => {
												setUpdate(update => update + 1);
											}).catch(() => {
												setLoadingState(LoadingStateEnum.ERROR);
												toast.error(translateToString('error'));
											});
										}
										break;
									case 'delete':
										alert({
											title: translateToString('file_manager.are_you_sure'),
											content: translateToString('file_manager.are_you_sure_delete') + file.filename + '?',
										}).then(res => {
											if (res === AlertRes.Ok) {
												setLoadingState(LoadingStateEnum.LOADING);
												deleteFile(file.hash, currentPath).then(() => {
													setUpdate(update => update + 1);
												});
											}
										});
										break;
									default:
										break;
								}
							}}
						/>
					</DropDownContainer>
				</Item>)}
			
		</GedBody>}
		<Footer>
			<FlexDiv>
			</FlexDiv>
			{translateToString('file_manager.storage_usage')}
			<ProgressBarStyle>
				<ProgressBar
					now={Math.round(usage * 100) / 100}
					min={0}
					max={MAX_FILES_LIMIT_IN_MO}
					variant={usage > MAX_FILES_LIMIT_IN_MO * 0.5 ? usage > MAX_FILES_LIMIT_IN_MO * 0.8 ? 'danger' : 'warning' : 'success'}
				/>
				<div>{Math.round(usage * 100) / 100}Mo / {MAX_FILES_LIMIT_IN_MO}Mo</div>
			</ProgressBarStyle>
		</Footer>

	</>;
}