import add_blueImage from 'images/icon/add_blue.png';
import trashImage from 'images/formbuilder/trash.svg';
import modal_openImage from 'images/icons/company/modal_open.svg';
import open_new_tabImage from 'images/icons/company/open_new_tab.svg';
import closeImage from 'images/icons/orders/close.svg';
import noImage from 'images/no-image.jpg';
import * as React from 'react';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import { DefaultImage } from '../../styles/global/css/GlobalImage';
import {
	BodyHeaderStatus,
	Container,
	FullOpen,
	Header as CCHeader,
	HeaderBlock,
	HeaderLeft,
	HeaderRight,
	LeftContainer,
	Logo
} from '../client-companies/style/PopupStyle';
import { Tag, TagContainer } from '../client-companies/style/Style';
import { HeaderTitle } from '../orders/templateOrders/subPage/style/PopupOrderDetailStyle';
import {
	createProductTag,
	deleteAlternativeProducts,
	deleteProductImage,
	deleteProductTag,
	getAlternativeProducts,
	getBrands,
	getCatalogues,
	getProductById,
	getProductByIdTags,
	getProductTax,
	getStatuses,
	getTags,
	postProductImages,
	updateProduct,
	updateTax,
	postImages
} from './action';
import Barcode from 'react-barcode';
import { formatCurrency } from '../reports/utils';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
	BarcodeContainer,
	BarcodeError,
	BarcodeTitle,
	BodyMainTextEditable,
	Content,
	FlexDiv,
	HideTrashDiv,
	InnerContainer,
	InnerContainerContent,
	InnerContainerTitle,
	Link,
	Picture,
	PictureTrash,
	RightContainer,
	TABLE_IMAGE_PADDING,
	TABLE_IMAGE_WIDTH,
	TableAnswer,
	TableTitle,
	TextArea,
	TextBorder,
	TextTile,
	TopRight
} from './style';
import { AlternativeProduct, Catalogue, ModalState, Product, ProductStatus } from './model';
import AssortmentDetail from './assortment';
import { Brand } from '../globals/Model';
import LegacyCategoryModal from './legacyCategoryModal';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import ProductAlternativesCreation from './productAlternativesCreation';
import InputImage from '../../components_v2/input/InputImage';
import { ImageThumbnail } from '../../components_v2/input/model/Model';
import { LoadingState } from '../import/model';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import { Table, TableSortType } from '../../components_v2/table/Table';
import { TableRow } from '../orders/templateOrders/style/Style';
import { Open } from '../../styles/global/css/Open';
import { Collapse } from 'reactstrap';
import Pagination from '../../components_v2/pagination/Pagination';
import DropdownTagsCloud from '../../components_v2/dropdown/DropdownTagsCloud';
import { ProductTax } from '../settings/models';
import { ITag } from '../../../typings/proto/protobufs';
import { BlueSidely, DarkGreySidely, LightBlueSidely, SidelyBlack } from '../../styles/global/css/Utils';
import useAlert from '../alert/UseAlert';
import { translateToNode, translateToString } from '../../styles/global/translate';
import styled from 'styled-components';
import { isValidEAN13, isValidEAN8, isValidUPC, parseNumber } from '../../components_v2/utils';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';
import { HeaderTitleInput, blurOnEnterPressed } from '../../components_v2/input/Input';

const DEFAULT_LIMIT = 5;

interface modalStateManager {
		assortment: ModalState
		product: ModalState
		alternativeProduct: ModalState
}

const initModalState = {
	assortment: { isOpen: false },
	product: { isOpen: false },
	alternativeProduct: { isOpen: false }
};

const Header = styled(CCHeader)`
	min-height: 90px;
`;

const StyledSpan = styled.span`
	font-size: 0.85em;
`;

export default function ProductPopup(props: {
		isOpen: boolean
		setIsOpen: (value: boolean) => void
		product?: Product
		productId?: number
		fullOpenMode?: boolean
		refresh?: () => void
		isChild?: boolean
}): JSX.Element {
	const { isOpen, setIsOpen, fullOpenMode, isChild } = props;
	const [popupMode, setPopupMode] = React.useState<PopupMode>(fullOpenMode ? PopupMode.Default : PopupMode.Details);
	const [statuses, setStatuses] = React.useState<ProductStatus[]>([]);
	const [tags, setTags] = React.useState<ITag[]>([]);
	const [product, setProduct] = React.useState<Product | undefined>(undefined);
	const [modalState, setModalState] = React.useState<modalStateManager>(initModalState);
	const [disableClose, setDisableClose] = React.useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

	const refresh = () => {
		props.refresh?.();
		if (product?.id) { fetchProduct(product.id); }
	};

	const fetchProduct = async(id: number) => await getProductById(id)
		.then(res => setProduct(res.data))
		.catch(console.log);

	const fetchTags = async(id: number) => await getProductByIdTags(id)
		.then(res => setTags(res.data))
		.catch(console.log);

	React.useEffect(() => {
		setProduct(props.product);
	}, [props.product, isOpen]);

	React.useEffect(() => {
		if (props.productId && props.productId !== product?.id && isOpen) {
			fetchProduct(props.productId);
		}
	}, [props.productId, isOpen]);

	React.useEffect(() => {
		getStatuses()
			.then(res => {
				res.data.unshift({ name: '-', id: 0 });
				setStatuses(res.data);
			})
			.catch(console.log);
	}, []);

	React.useEffect(() => {
		if (product?.id) {
			fetchTags(product.id);
		}
	}, [product?.id]);

	React.useEffect(() => {
		setPopupMode(fullOpenMode ? PopupMode.Default : PopupMode.Details);
	}, [fullOpenMode, isOpen]);

	if ((product == null) || !isOpen) { return <></>; }

	const isFullOpen = popupMode == PopupMode.Default;

	function switchMode() {
		setPopupMode(popupMode == PopupMode.Details ? PopupMode.Default : PopupMode.Details);
	}

	return (
		<Popup
			isOpen={isOpen}
			onClickOut={() => setIsOpen(false)}
			popupStyle={{ animate: true, height: '100vh', top: '0' }}
			popupMode={popupMode}
			disableOutClick={Object.values(modalState).some(ms => ms.isOpen) || disableClose}
			disableEsc={Object.values(modalState).some(ms => ms.isOpen) || disableClose}
		>
			<Restricted to={{ objectAction: 'ReadCatalog' }} fallback={<Unhautorized />}>
				<Container>
					<LeftContainer isFullOpen={isFullOpen}>
						<Header>
							<HeaderLeft width='calc(100% - 20px)'>
								<DefaultImage cursor="pointer" src={closeImage} onClick={() => props.setIsOpen(false)} />
								<HeaderBlock flexDirection='row' marginLeft="20px" width='calc(100% - 45px)'>
									<Logo src={product.photos_url_id?.[0]?.[0] ?? noImage} />
									<HeaderBlock flexDirection='column' marginLeft="10px" alignItem="" width='calc(100% - 60px)'>
										<HeaderBlock flexDirection='row'>
											<Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={<HeaderTitle>{product.name}</HeaderTitle>}>
												<HeaderTitleInput 
													type="text"
													defaultValue={product.name}
													placeholder={translateToString('product.enter_name')}
													onKeyDownCapture={blurOnEnterPressed}
													onBlur={(e) => {
														const inputValue = e.target.value.trim();
														if (inputValue === product.name) return;
														updateProduct(product.id, { name: inputValue })
															.then(refresh)
															.catch(console.log);
													}}
												/>
											</Restricted>
										</HeaderBlock>
										<HeaderBlock flexDirection="row">
											<Restricted to={{ objectAction: 'UpdateCatalog' }}
												fallback={<BodyHeaderStatus backgroundColor={product.status_color_code}>
													{product.status_name ?? '-'}
												</BodyHeaderStatus>}
											>
												<Dropdown
													dropdownStyle={{ optionWidth: 'fit-content' }}
													name="select_status"
													datalist={statuses.map(s => { return { label: s.name, value: s, color: s.color_code }; })}
													JSXButton={() => <FlexDiv gap="10px">
														<BodyHeaderStatus backgroundColor={product.status_color_code}>
															{product.status_name ?? '-'}
														</BodyHeaderStatus>
														<Open isOpen={dropdownOpen} width={12} height={7} />
													</FlexDiv>}
													JSXContent={(status: DropdownData) => <BodyHeaderStatus inDropdown backgroundColor={status.color}>{status.label ?? '-'}</BodyHeaderStatus>}
													onChange={(value: DropdownData) => {
														if (value.value.id === product.status_id || (!value.value.id && !product.status_id)) return;
														updateProduct(product.id, { status_id: value.value.id })
															.then(refresh)
															.catch(console.log);
													}}
													onOpen={setDropdownOpen}
												/>
											</Restricted>
										</HeaderBlock>
									</HeaderBlock>
								</HeaderBlock>
							</HeaderLeft>
							<HeaderRight>
								{isChild
									? <FullOpen isActive src={open_new_tabImage} onClick={() => window.open(`/products-v2?id=${product?.id}`, '_blank')?.focus()} />
									: <FullOpen src={modal_openImage} isActive={isFullOpen} onClick={() => switchMode()} />
								}
							</HeaderRight>
						</Header>
						<div style={{ overflow: 'auto' }}>
							<Tags tags={tags} productId={product.id} refresh={async() => await fetchTags(product.id)} />
							<Overview product={product} refresh={refresh} onToggle={(b) => setDisableClose(b)} />
							<BarcodeDisplayer product={product} refresh={refresh} />
						</div>
					</LeftContainer>
					<RightContainer isOpen={isFullOpen}>
						<Pictures product={product} onToggle={(b) => setDisableClose(b)} refresh={refresh} />
						<Caracteristics product={product} refresh={refresh} />
						<Catalogues
							productId={product.id}
							openAssortmentDetails={(data) => setModalState({ ...modalState, assortment: { isOpen: !modalState.assortment.isOpen, data } })}
						/>
						<Alternatives
							productId={product.id}
							setIsChildOpen={setDisableClose}
						/>
					</RightContainer>
					<AssortmentDetail
						isOpen={modalState.assortment.isOpen}
						setIsOpen={isOpen => setModalState({ ...modalState, assortment: { ...modalState.assortment, isOpen } })}
						assortmentId={modalState.assortment.data}
						isChild
					/>
				</Container>
			</Restricted>
		</Popup >
	);
}

function Overview(props: { product: Product, refresh: () => void, onToggle: (b: boolean) => void }): JSX.Element {
	const { product, refresh, onToggle } = props;
	const [focus, setFocus] = React.useState<string | undefined>(undefined);
	const [brands, setBrands] = React.useState<Brand[]>([]);
	const [isOpen, setIsOpen_] = React.useState<boolean>(false);
	const [isLocalOpen, setIsLocalOpen] = React.useState<boolean>(true);
	const [productTaxes, setProductTaxes] = React.useState<ProductTax[]>();
	const translate = getTranslate(storeLang.getState().localize);
	const alert = useAlert();


	const setIsOpen = (b: boolean) => {
		setIsOpen_(b);
		onToggle(b);
	};

	const fetchProductTaxes = async() => await getProductTax(product.id)
		.then(setProductTaxes)
		.catch(console.log);

	React.useEffect(() => {
		getBrands()
			.then(res => {
				res.data.unshift({ id: 0, name: translate('None') });
				setBrands(res.data);
			})
			.catch(console.log);
		fetchProductTaxes();
	}, []);

	function updatePrice(price: number, key: string) {
		if (!isNaN(price) && price >= 0) {
			if (price == product[key]) return;
			const body = {};
			body[key] = price;
			updateProduct(product.id, body)
				.then(() => refresh())
				.catch(console.log);
		}
	}

	return <InnerContainer>
		<InnerContainerTitle>
			<TitleWithOpen
				title={<Translate id='overview' />}
				isOpen={isLocalOpen}
				setIsOpen={setIsLocalOpen}
			/>
		</InnerContainerTitle>
		<Collapse isOpen={isLocalOpen}>
			<Content>
				<table>
					<tbody>
						<tr>
							<TableTitle><Translate id='reference' /></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onBlur={(e) => {
										if (e.currentTarget.innerText.trim() === product.reference) return;
										updateProduct(product.id, { reference: e.currentTarget.innerText.trim() })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.reference}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='brand' /></TableTitle>
							<TableAnswer>
								<Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={product.brand_name}>
									<Dropdown
										dropdownStyle={{
											optionWidth: '200px'
										}}
										datalist={brands.map(b => ({ value: b.id, label: b.name }))}
										name={'product-brand-selector'}
										onChange={(value: DropdownData) => {
											if (value.value === product.brand_id || (!product.brand_id && !value.value)) return;
											updateProduct(product.id, { brand_id: value.value })
												.then(() => refresh())
												.catch(console.log);
										}}
										selectedValue={brands.map(b => ({ value: b.id, label: b.name })).find(b => b.value == product.brand_id)}
										JSXButton={() => <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
											{brands.find(b => b.id == product.brand_id)?.name ?? '-'}
										</div>}
									/>
								</Restricted>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='category' /></TableTitle>
							<Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={<TableAnswer>{product.category_name ?? '-'}</TableAnswer>}>
								<TableAnswer clickable onClick={() => setIsOpen(true)}>{product.category_name ?? '-'}</TableAnswer>
							</Restricted>
						</tr>
						<tr>
							<TableTitle><Translate id='stock' /></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onBlur={(e) => {
										const stock = parseInt(e.currentTarget.innerText.trim());
										if (isNaN(stock) || stock < 0 || stock === product.stock) return;
										updateProduct(product.id, { stock })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.stock}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='price' /></TableTitle>
							<TableAnswer>
								<UpdatablePrice
									onFocus={() => setFocus('price')}
									onBlur={(e) => {
										setFocus(undefined);
										updatePrice(parseNumber(e.currentTarget.innerText), 'price');
									}}
									isFormated={focus == 'price'}
									value={product.price}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='recommended price' /></TableTitle>
							<TableAnswer>
								<UpdatablePrice
									onFocus={() => setFocus('recommended_price')}
									onBlur={(e) => {
										setFocus(undefined);
										updatePrice(parseNumber(e.currentTarget.innerText), 'recommended_price');
									}}
									isFormated={focus == 'recommended_price'}
									value={product.recommended_price}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='outer' /></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onBlur={(e) => {
										const outer = parseInt(e.currentTarget.innerText.trim());
										if (isNaN(outer) || outer < 0 || outer === product.outer) return;
										updateProduct(product.id, { outer })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.outer}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='width' /><StyledSpan> (cm)</StyledSpan></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onFocus={() => setFocus('width')}
									onBlur={(e) => {
										setFocus(undefined);
										const width = parseNumber(e.currentTarget.innerText.trim());
										if (isNaN(width) || width < 0 || width === product.width) return;
										updateProduct(product.id, { width })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.width}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='height' /><StyledSpan> (cm)</StyledSpan></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onFocus={() => setFocus('height')}
									onBlur={(e) => {
										setFocus(undefined);
										const height = parseNumber(e.currentTarget.innerText.trim());
										if (isNaN(height) || height < 0 || height === product.height) return;
										updateProduct(product.id, { height })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.height}
								/>
							</TableAnswer>
						</tr>
						<tr>
							<TableTitle><Translate id='depth' /><StyledSpan> (cm)</StyledSpan></TableTitle>
							<TableAnswer>
								<UpdatableContent
									onFocus={() => setFocus('depth')}
									onBlur={(e) => {
										setFocus(undefined);
										const depth = parseNumber(e.currentTarget.innerText.trim());
										if (isNaN(depth) || depth < 0 || depth === product.depth) return;
										updateProduct(product.id, { depth })
											.then(() => refresh())
											.catch(console.log);
									}}
									value={product.depth}
								/>
							</TableAnswer>
						</tr>
						{!productTaxes || productTaxes.length == 0 && <tr onClick={() => alert({
							title: <Translate id='no_tax' />,
							content: <div>{translateToNode('please_create_taxes', { onClickA: () => window.open('/settings#tax_management', '_blank')?.focus() })}</div>,
							svg: 'warning',
							zIndex: 201,
							noButtons: true
						})}>
							<TableTitle><Translate id='tax' /></TableTitle>
							<TableAnswer>
							-
							</TableAnswer>

						</tr>}
						{productTaxes?.map((tax, i) => <tr key={`productTaxes[${i}]`}>
							<TableTitle>{tax.name}</TableTitle>
							<TableAnswer>
								<UpdatableContent
									onFocus={() => setFocus(`tax_${tax.name}`)}
									onBlur={(e) => {
										setFocus(undefined);
										let outer: number | undefined = parseFloat(e.currentTarget.innerText.trim());
										if (isNaN(outer)) outer = undefined;
										if (outer === tax.tax_value) return;
										const body = {
											id: tax.product_tax_id,
											tax_value: outer,
											product_id: product.id,
											tax_id: tax.tax_id
										};
										updateTax(body).then(fetchProductTaxes).catch(console.error);
									}}
									value={tax.tax_value}
									format={(value: any) => {
										if (focus == `tax_${tax.name}`) return value;
										const parsed = parseFloat(value);
										switch (tax.tax_type_name) {
											case 'percentage':
												if (!isNaN(parsed)) return `${parsed} %`;
												break;
											case 'amount':
												if (!isNaN(parsed)) return formatCurrency(value);
												break;
										}
										return '-';
									}}
								/>
							</TableAnswer>
						</tr>)}
					</tbody>
				</table>
			</Content>
		</Collapse>
		<LegacyCategoryModal
			isOpen={isOpen}
			toggle={() => setIsOpen(false)}
			onSubmit={(category_id: number) => {
				if (category_id === product.category_id) return;
				updateProduct(product.id, { category_id })
					.then(() => refresh())
					.catch(console.log);
			}}
			currentId={product.category_id}
		/>
	</InnerContainer>;
}

export function BarcodeWrapper({ format, value, width, height, botMargin }: {format: 'EAN13' | 'EAN8' | 'upc', value: string, width?: number, height?: number, botMargin?: string}): JSX.Element {
	if ((format === 'EAN13' && isValidEAN13(value)) || (format === 'EAN8' && isValidEAN8(value)) || (format === 'upc' && isValidUPC(value)))
		return <BarcodeContainer margin={(format === 'EAN13' || format === 'EAN8') ? `0 0 ${botMargin ?? '20px'} -12px` : `0 0 ${botMargin ?? 0} 12px`}>
			<Barcode format={format} value={value} width={width} height={height}/>
		</BarcodeContainer>;
	return <BarcodeError><Translate id="products.invalid_barcode"/></BarcodeError>;
}

function BarcodeDisplayer(props: { product: Product, refresh: () => void }): JSX.Element {
	const { product, refresh } = props;
	const [isOpen, setIsOpen] = React.useState<boolean>(true);

	return <InnerContainer>
		<InnerContainerTitle>
			<TitleWithOpen
				title={<Translate id='barcodes' />}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</InnerContainerTitle>
		<Collapse isOpen={isOpen}>
			<Content>
				<BarcodeTitle>
					<Translate id='EAN-JAN barcode' />
				</BarcodeTitle>
				<div>
					<UpdatableContent
						onBlur={(e) => {
							const barcode = e.currentTarget.innerText.trim();
							if (barcode === product.barcode) return;
							updateProduct(product.id, { barcode })
								.then(() => refresh())
								.catch(console.log);
						}}
						value={product.barcode}
					/>
				</div>
				<BarcodeWrapper format={product.barcode?.length === 13 ? 'EAN13' : 'EAN8'} value={product.barcode ?? ''} />
				<BarcodeTitle>
					<Translate id='UPC Barcode' />
				</BarcodeTitle>
				<div>
					<UpdatableContent
						onBlur={(e) => {
							const code_upc = e.currentTarget.innerText.trim();
							if (code_upc === product.code_upc) return;
							updateProduct(product.id, { code_upc })
								.then(() => refresh())
								.catch(console.log);
						}}
						value={product.code_upc}
					/>
				</div>
				<BarcodeWrapper format="upc" value={product.code_upc ?? ''} />
			</Content>
		</Collapse>
	</InnerContainer>;
}

function Pictures(props: { product: Product, onToggle: (b: boolean) => void, refresh: () => void }): JSX.Element {
	const { product, onToggle, refresh } = props;
	const [current, setCurrent] = React.useState<number>(0);
	const [isOpen, setIsOpen_] = React.useState<boolean>(false);
	const [isLocalOpen, setIsLocalOpen] = React.useState<boolean>(true);
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loaded');

	const setIsOpen = (b: boolean) => {
		setIsOpen_(b);
		onToggle(b);
	};

	const postImage = async(value: ImageThumbnail) => {
		setLoadingState('loading');
		await postImages([value.thumbnail, value.original], ['thumbs/' + value.thumbnail.name, 'originals/' + value.original.name]);
		postProductImages(product.id, [{ name: 'thumbs/' + value.thumbnail.name, file: '' }])
			.then(() => {
				setLoadingState('loaded');
				refresh();
			})
			.catch(e => {
				setLoadingState('error');
				console.error(e);
			});
	};

	const deleteImage = (id: number) => {
		setLoadingState('loading');
		deleteProductImage(product.id, id)
			.then(() => {
				setLoadingState('loaded');
				refresh();
			})
			.catch(e => {
				setLoadingState('error');
				console.log(e);
			});
	};

	return (
		<InnerContainer>
			<InnerContainerTitle>
				<TitleWithOpen
					title={<><Translate id='Pictures' /> ({product.photos_url_id.length})</>}
					isOpen={isLocalOpen}
					setIsOpen={setIsLocalOpen}
				/>
			</InnerContainerTitle>
			<Collapse isOpen={isLocalOpen}>
				<InnerContainerContent>
					<FlexDiv gap='10px' flexWrap='wrap'>
						{product.photos_url_id.map(([url, id], i) => <HideTrashDiv key={`productPhotoUrl[${i}]`}>
							<Picture src={url} onClick={() => { setCurrent(i); setIsOpen(true); }} />
							<Restricted to={{ objectAction: 'UpdateCatalog' }}>
								<TopRight>
									{loadingState == 'loading'
										? <PageLoader className='trash' size={15} />
										: <PictureTrash className='trash' src={trashImage} onClick={() => deleteImage(id)} />
									}
								</TopRight>
							</Restricted>
						</HideTrashDiv>)}
						<Restricted to={{ objectAction: 'UpdateCatalog' }}>
							<InputImage
								isProduct={true}
								compress={true}
								limitMo={10}
								disableShowImage
								onChange={postImage}
								isLoading={loadingState == 'loading'}
							/>
						</Restricted>
						{isOpen &&
							<Lightbox
								mainSrc={product.photos_url_id[current][0]}
								nextSrc={product.photos_url_id[current + 1]?.[0]}
								prevSrc={product.photos_url_id[current - 1]?.[0]}
								onCloseRequest={() => setIsOpen(false)}
								onMovePrevRequest={() => setCurrent(current - 1)}
								onMoveNextRequest={() => setCurrent(current + 1)}
							/>
						}
					</FlexDiv>
				</InnerContainerContent>
			</Collapse>
		</InnerContainer>
	);
}

function Caracteristics(props: { product: Product, refresh: () => void }): JSX.Element {
	const { product, refresh } = props;
	const [isOpen, setIsOpen] = React.useState<boolean>(true);

	return <InnerContainer>
		<InnerContainerTitle>
			<TitleWithOpen
				title={<Translate id='Caracteristics' />}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</InnerContainerTitle>
		<Collapse isOpen={isOpen}>
			<InnerContainerContent>
				<div style={{ width: '100%', padding: '0 18px', marginTop: '25px' }}>
					<UpdatableContentWithBorder
						title={<Translate id='summary' />}
						value={product.summary}
						onChange={async(summary: string) => {
							if (summary === product.summary) return;
							return await updateProduct(product.id, { summary })
								.then(() => refresh())
								.catch(console.log);
						}
						}

					/>
				</div><div style={{ width: '100%', padding: '0 18px', marginTop: '20px' }}>
					<UpdatableContentWithBorder
						title={<Translate id='description' />}
						value={product.description}
						onChange={async(description: string) => {
							if (description === product.description) return;
							return await updateProduct(product.id, { description })
								.then(() => refresh())
								.catch(console.log);
						}
						}
					/>
				</div>
			</InnerContainerContent>
		</Collapse>
	</InnerContainer>;
}

function Alternatives(props: {
		productId: number
		setIsChildOpen: (b: boolean) => void
}): JSX.Element {
	const { productId, setIsChildOpen } = props;
	const [sort, setSort] = React.useState<TableSortType>();
	const [isOpen, setIsOpen] = React.useState<boolean>(true);
	const [modalStateCreation, setModalStateCreation] = React.useState<ModalState>({ isOpen: false });
	const [modalStateProduct, setModalStateProduct] = React.useState<ModalState>({ isOpen: false });
	const [alternativeProducts, setAlternativeProducts] = React.useState<AlternativeProduct[]>([]);
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });

	const setModalState = (state: ModalState, key: 'product' | 'creation') => {
		setIsChildOpen(state.isOpen);
		switch (key) {
			case 'creation':
				setModalStateCreation(state);
				break;
			case 'product':
				setModalStateProduct(state);
				break;
		}
	};

	React.useEffect(() => {
		setSort(undefined);
		setPagination({ step: DEFAULT_LIMIT, offset: 0 });
	}, [productId]);

	React.useEffect(() => {
		getAlternativeProducts(productId, pagination.step, pagination.offset, sort)
			.then(res => setAlternativeProducts(res.data))
			.catch(console.log);
	}, [pagination, sort]);

	const columns = React.useMemo(() => ([
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: AlternativeProduct) => <Link centered onClick={() => setModalState({ isOpen: true, data: row.id }, 'product')} background={row.photos_url_id?.[0]?.[0] ?? noImage}/>,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding:TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true
		},
		{
			id: 'lower(p.name)',
			Header: <Translate id='name' />,
			accessor: (row: AlternativeProduct) => <TableRow fontWeight="500" color={DarkGreySidely} onClick={() => setModalState({ isOpen: true, data: row.id }, 'product')} cursor="pointer">
				{row.name ?? '-'}
			</TableRow>,
			width: 200
		},
		{
			id: 'lower(reference)',
			Header: <Translate id='reference' />,
			accessor: (row: AlternativeProduct) => <TableRow>{row.reference ?? '-'}</TableRow>,
			width: 200
		},
		{
			id: 'lower(c.name)',
			Header: <Translate id='category' />,
			accessor: (row: AlternativeProduct) => <TableRow>{row.category_name ?? '-'}</TableRow>,
			width: 200
		},
		{
			id: 'options',
			Header: ' ',
			accessor: (row: AlternativeProduct) => <Restricted to={{ objectAction: 'UpdateCatalog' }}>
				<TableRow onClick={() => { deleteAlternativeProducts(productId, row.id).then(() => setPagination({ ...pagination })).catch(console.log); }} cursor="pointer">
					<img src={trashImage} width={15} />
				</TableRow>
			</Restricted>,
			disableSortBy: true,
			width: 35,
			minWidth: 35,
			unresizeable: true
		}
	]), [alternativeProducts]);

	return (
		<InnerContainer>
			<InnerContainerTitle>
				<TitleWithOpen
					title={<><Translate id='Alternative products' /> ({alternativeProducts.length})</>}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
				<Restricted to={{ objectAction: 'UpdateCatalog' }}>
					<ToolbarImage
						width='25px'
						height='25px'
						hasPointer
						src={add_blueImage}
						backgroundColor={LightBlueSidely}
						round
						onClick={() => setModalState({ isOpen: true, data: productId }, 'creation')}
					/>
				</Restricted>
			</InnerContainerTitle>
			<InnerContainerContent>
				<Collapse isOpen={isOpen}>
					<Table height={`calc(40px * ${alternativeProducts.length + 1} + 10px)`}
						columns={columns}
						data={alternativeProducts}
						onSort={sort => setSort(sort[0])}
						initialSortBy={sort}
					/>
					{alternativeProducts[0]?.count > DEFAULT_LIMIT &&
						<Pagination
							label={translateToString('products')}
							amount={alternativeProducts[0]?.count}
							steps={[DEFAULT_LIMIT]}
							onChange={(value) => {
								setPagination({ ...pagination, ...value });
							}} />
					}
				</Collapse>
			</InnerContainerContent>
			<ProductAlternativesCreation
				isOpen={modalStateCreation.isOpen}
				setIsOpen={isOpen => setModalState({ ...modalStateCreation, isOpen }, 'creation')}
				refresh={() => setPagination({ ...pagination })}
				productId={productId}
			/>
			<ProductPopup
				isOpen={modalStateProduct.isOpen}
				setIsOpen={isOpen => setModalState({ ...modalStateProduct, isOpen }, 'product')}
				productId={modalStateProduct.data}
				isChild
				refresh={() => setPagination({ ...pagination })}
			/>
		</InnerContainer>
	);
}

function Catalogues(props: { productId: number, openAssortmentDetails: (id: number) => void }): JSX.Element {
	const { productId, openAssortmentDetails } = props;
	const [sort, setSort] = React.useState<TableSortType>();
	const [isOpen, setIsOpen] = React.useState<boolean>(true);
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [catalogues, setCatalogues] = React.useState<Catalogue[]>([]);

	React.useEffect(() => {
		setSort(undefined);
		setPagination({ step: DEFAULT_LIMIT, offset: 0 });
	}, [productId]);

	React.useEffect(() => {
		getCatalogues(productId, pagination.step, pagination.offset, sort)
			.then(res => setCatalogues(res.data))
			.catch(console.log);
	}, [pagination, sort]);

	const columns = React.useMemo(() => ([
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: Catalogue) => <Link centered onClick={() => openAssortmentDetails(row.id)} background={row.url}> </Link>,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding:TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true
		},
		{
			id: 'lower(C.name)',
			Header: <Translate id='name' />,
			accessor: (row: Catalogue) => <TableRow fontWeight="500" color={DarkGreySidely} onClick={() => openAssortmentDetails(row.id)} cursor="pointer">
				{row.name ?? '-'}
			</TableRow>,
			width: 200
		}
	]), [catalogues]);

	return <InnerContainer>
		<InnerContainerTitle>
			<TitleWithOpen
				title={<><Translate id='catalogs' /> ({catalogues.length})</>}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</InnerContainerTitle>
		<Collapse isOpen={isOpen}>
			<InnerContainerContent>
				<Table height={`calc(40px * ${catalogues.length + 1} + 10px)`}
					columns={columns}
					data={catalogues}
					onSort={sort => setSort(sort[0])}
					initialSortBy={sort}
				/>
				{catalogues[0]?.count > DEFAULT_LIMIT &&
					<Pagination label={'catalogues'} amount={catalogues[0]?.count} steps={[DEFAULT_LIMIT]} onChange={(value) => {
						setPagination({ ...pagination, ...value });
					}} />
				}
			</InnerContainerContent>
		</Collapse>
	</InnerContainer>;
}

function Tags(props: { tags: ITag[], productId: number, refresh: () => void }): JSX.Element {
	const { tags, productId, refresh } = props;
	const [allTags, setAllTags] = React.useState<ITag[]>([]);

	React.useEffect(() => {
		getTags().then(tags => {
			setAllTags(tags);
		});
	}, []);

	function changeTags(ids: number[]) {
		const toAdd = ids.filter(id => !tags.some(t => t.id == id));
		const toDel = tags.map(t => t.id).filter(t => !ids.some(id => t == id));

		const apis = async() => {
			for (const id of toDel) {
				await deleteProductTag(productId, id!);
			}
			for (const id of toAdd) {
				await createProductTag(productId, id);
			}
		};

		apis().then(refresh).catch(console.log);
	}

	return <InnerContainer>
		<Restricted to={{ objectAction: 'UpdateCatalog' }} 
			fallback={<div style={{ overflow: 'auto', display: 'flex' }}>
				{tags.map(t => 
					<TagContainer key={`tagNoPermission[${t.id}]`} backgroundColor={t.color!}>
						<Tag backgroundColor={t.color!}>
							{t.name}
						</Tag>
					</TagContainer>)}
			</div>}
		>
			<DropdownTagsCloud
				tags={allTags}
				selected={tags}
				onChange={(value: ITag[]) => changeTags(value.map(v => v.id!))}
				onDelete={(id: number) => {
					deleteProductTag(productId, id)
						.then(refresh)
						.catch(console.log);
				}}
			/>
		</Restricted>
	</InnerContainer>;
}

function UpdatablePrice(props: { onFocus: () => void, onBlur: (e) => void, isFormated: boolean, value?: number }) {
	const { onFocus, onBlur, isFormated, value } = props;
	return <Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={!value ? value : formatCurrency(value)}>
		<BodyMainTextEditable
			color={SidelyBlack}
			onFocus={onFocus}
			suppressContentEditableWarning
			contentEditable
			onKeyDownCapture={blurOnEnterPressed}
			onBlur={onBlur}
		>
			{isFormated || !value ? value : formatCurrency(value)}
		</BodyMainTextEditable>
	</Restricted>;
}

function UpdatableContent(props: { onFocus?: () => void, onBlur?: (e) => void, value?: any, noBorder?: boolean, format?: (value: any) => any }) {
	const { onFocus, onBlur, value, noBorder, format } = props;
	return <Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={(format != null) ? format(value) : value}>
		<BodyMainTextEditable
			color={SidelyBlack}
			onFocus={onFocus}
			suppressContentEditableWarning
			contentEditable
			onKeyDownCapture={blurOnEnterPressed}
			onBlur={onBlur}
			noBorder={noBorder}
		>
			{(format != null) ? format(value) : value}
		</BodyMainTextEditable>
	</Restricted>;
}

export function TitleWithOpen(props: {
		title?: string | JSX.Element
		isOpen: boolean
		children?
		setIsOpen: (b: boolean) => void
		color?: 'blue' | 'white' | 'black'
}) {
	return <FlexDiv gap='20px' onClick={() => props.setIsOpen(!props.isOpen)} clickable justify="space-between" color={props.color === 'blue' ? BlueSidely : props.color === 'white' ? 'white' : SidelyBlack} fontWeight='500'>
		<Open
			color={props.color}
			isOpen={props.isOpen}
		/>
		{props.children ?? props.title}
	</FlexDiv>;
}

export function UpdatableContentWithBorder(props: { title: string | JSX.Element, value?: string, onChange?: (value: string) => void }) {
	const { title, value, onChange } = props;

	return <TextBorder>
		<TextTile>
			{title}
		</TextTile>
		<TextArea>
			<UpdatableContent
				onBlur={(e) => onChange?.(e.currentTarget.innerText.trim())}
				value={value}
				noBorder
			/>
		</TextArea>
	</TextBorder>;
}
