import * as React from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { translateToNode, translateToString } from '../../styles/global/translate';
import { Responsive as ResponsiveGridLayout, Layout, Layouts } from 'react-grid-layout';
import styled from 'styled-components';
import { BlueSidely } from '../../styles/global/css/Utils';
import { useWindowDimensions } from '../../components_v2/utils';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import Add from '../../components_v2/add/Add';
import { getDashboard, getGoalDashboard, updateDashboard } from './actions';
import DashboardPopup from './popup/DashboardPopup';
import Widget from './Widget';
import { Id } from '../settings/subSettings/statusesAndTagsSettings';
import { LoadingStateEnum } from '../import/model';
import Restricted from '../permissions/Restricted';
import NoData, { Unhautorized } from '../noData/NoData';
import TabList from './tabs/TapList';
import useAlert from '../alert/UseAlert';
import { AlertRes } from '../alert/AlertProvider';
import { ModalState } from '../products/model';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';

const GridStyles = styled.div`
	position: relative;
	.react-grid-layout {
		position: relative;
		transition: height 200ms ease;
	}
	.react-grid-item {
		transition: all 200ms ease;
		transition-property: left, top;
	}
	.react-grid-item img {
		pointer-events: none;
		user-select: none;
	}
	.react-grid-item.cssTransforms {
		transition-property: transform;
	}
	.react-grid-item.resizing {
		cursor: se-resize;
		z-index: 1;
		will-change: width, height;
	}
	  
	.react-grid-item.react-draggable-dragging {
		cursor: grabbing;
		transition: none;
		z-index: 3;
		will-change: transform;
	}
	  
	.react-grid-item.dropping {
		visibility: hidden;
	}
	  
	.react-grid-item.react-grid-placeholder {
		background: ${BlueSidely};
		border-radius: 5px;
		opacity: 0.2;
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	  
	.react-grid-item > .react-resizable-handle {
		position: absolute;
		width: 20px;
		height: 20px;
	}
	  
	.react-grid-item > .react-resizable-handle::after {
		content: "";
		position: absolute;
		right: 3px;
		bottom: 3px;
		width: 5px;
		height: 5px;
		border-right: 2px solid rgba(0, 0, 0, 0.4);
		border-bottom: 2px solid rgba(0, 0, 0, 0.4);
	}
	  
	.react-resizable-hide > .react-resizable-handle {
		display: none;
	}
	  
	.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
		bottom: 0;
		left: 0;
		cursor: sw-resize;
		transform: rotate(90deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
		bottom: 0;
		right: 0;
		cursor: se-resize;
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
		top: 0;
		left: 0;
		cursor: nw-resize;
		transform: rotate(180deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
		top: 0;
		right: 0;
		cursor: ne-resize;
		transform: rotate(270deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
	.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
		top: 50%;
		margin-top: -10px;
		cursor: ew-resize;
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
		left: 0;
		transform: rotate(135deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
		right: 0;
		transform: rotate(315deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
	.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
		left: 50%;
		margin-left: -10px;
		cursor: ns-resize;
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
		top: 0;
		transform: rotate(225deg);
	}
	.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
		bottom: 0;
		transform: rotate(45deg);
	}
	  
	.react-resizable {
		position: relative;
	}
	.react-resizable-handle {
		position: absolute;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		background-origin: content-box;
		box-sizing: border-box;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
		background-position: bottom right;
		padding: 0 3px 3px 0;
	}
	.react-resizable-handle-sw {
		bottom: 0;
		left: 0;
		cursor: sw-resize;
		transform: rotate(90deg);
	}
	.react-resizable-handle-se {
		bottom: 0;
		right: 0;
		cursor: se-resize;
	}
	.react-resizable-handle-nw {
		top: 0;
		left: 0;
		cursor: nw-resize;
		transform: rotate(180deg);
	}
	.react-resizable-handle-ne {
		top: 0;
		right: 0;
		cursor: ne-resize;
		transform: rotate(270deg);
	}
	.react-resizable-handle-w,
	.react-resizable-handle-e {
		top: 50%;
		margin-top: -10px;
		cursor: ew-resize;
	}
	.react-resizable-handle-w {
		left: 0;
		transform: rotate(135deg);
	}
	.react-resizable-handle-e {
		right: 0;
		transform: rotate(315deg);
	}
	.react-resizable-handle-n,
	.react-resizable-handle-s {
		left: 50%;
		margin-left: -10px;
		cursor: ns-resize;
	}
	.react-resizable-handle-n {
		top: 0;
		transform: rotate(225deg);
	}
	.react-resizable-handle-s {
		bottom: 0;
		transform: rotate(45deg);
	}  
`;

export type DashboardItemType = 'Target' | 'ParentDn' | 'LivePromotions' | 'EventCount' | 'EventFrequency' |'EventFrequencyByUser' | 'Status' | 'Company' | 'ClientCompanyStatusCount' | 'TopCompanyByCheckout';

export const DEFAULT_LAYOUTS: { [key in DashboardItemType]: Layout} = {
	'Target': {
		minH: 3,
		minW: 3,
	} as Layout,
	'ParentDn': {
		minW: 2,
		minH: 5
	} as Layout,
	'LivePromotions': {
		minW: 3,
		minH: 5,
	} as Layout,
	'EventCount': {
		minW: 2,
		minH: 4
	} as Layout,
	'EventFrequency': {
		minW:3,
		minH:3
	} as Layout,
	'EventFrequencyByUser': {
		minW:3,
		minH:3
	} as Layout,
	'Status': {
		minW:2,
		minH:7
	} as Layout,
	'Company': {
		minW:2,
		minH:7
	} as Layout,
	'ClientCompanyStatusCount': {
		minW:2,
		minH:4
	} as Layout,
	'TopCompanyByCheckout': {
		minW:4,
		minH:5
	} as Layout,
};

export type DashboardItem<T = Id> = {
	item_type: DashboardItemType,
	item_id?: number,
	layout?: Layout,
	id: T,
	additional_values?: object,
	title?: string
	screen_id: number
};

export enum Page {
	Grid,
	ParentDnCreator,
	EventCountCreator,
	EventFrequencyCreator,
	EventFrequencyByUserCreator,
	StatusCreator,
	ClientCompanyStatusCountCreator,
	CompanyCreator,
	TopCompanyByCheckoutCreator
}

/**
 * Mapping for correct creator
 * Page.Grid is like a default
 */
export const creatorPageMapping: { [key in DashboardItemType]: Page } = {
	ParentDn: Page.ParentDnCreator,
	EventCount: Page.EventCountCreator,
	EventFrequency: Page.EventFrequencyCreator,
	EventFrequencyByUser: Page.EventFrequencyByUserCreator,
	Status: Page.StatusCreator,
	ClientCompanyStatusCount: Page.ClientCompanyStatusCountCreator,
	Company: Page.CompanyCreator,
	TopCompanyByCheckout: Page.TopCompanyByCheckoutCreator,
	Target:Page.Grid,
	LivePromotions:Page.Grid,
} || Page.Grid;

interface DashboardContextState {
	currentItem: DashboardItem<Id> | undefined,
	setCurrentItem: React.Dispatch<React.SetStateAction<DashboardItem<Id> | undefined>>
	companyModal: ModalState<number>
	setCompanyModal: React.Dispatch<React.SetStateAction<ModalState<number>>>
}
export const DashboardContext = React.createContext<DashboardContextState | undefined>(undefined);

export default function Dashboard(props: ComponentProps) {
	const [items, setItems] = React.useState<DashboardItem[]>([]);
	const [layouts, setLayouts] = React.useState<Layouts>({});
	const [dashboardPopupOpen, setDashboardPopupOpen] = React.useState<boolean>(false);
	const [page, setPage] = React.useState<Page>(Page.Grid);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [currentItem, setCurrentItem] = React.useState<DashboardItem<Id> | undefined>(undefined);
	const [activeTab, setActiveTab] = React.useState<number>();
	const [companyModal, setCompanyModal] = React.useState<ModalState<number>>({ isOpen: false });
	const alert = useAlert();


	const { width } = useWindowDimensions();
	const reset = React.useCallback(() => {
		if (activeTab) {
			setLoadingState(LoadingStateEnum.LOADING);
			return getDashboard(activeTab).then(items => {
				setItems(items);
				setLayouts({ lg: items.map(si => si.layout).filter(e => e) as Layout[] });
				setLoadingState(LoadingStateEnum.LOADED);
			});
		}
	}, [activeTab]);


	React.useEffect(() => {
		reset();
		props.setToolBarState({
			title: translateToString('dashboard'),
			bottomLeftToolbarComponent: <TabList activeTab={activeTab} switchTab={(tabId) => setActiveTab(tabId)} />,
			bottomRightToolbarComponent: <ToolbarBox>
				<Restricted to={[{ objectAction: 'CreateDashboard' }, { objectAction: 'UpdateDashboard' }, { objectAction: 'DeleteDashboard' }]}>
					<Add onClick={() => setDashboardPopupOpen(true)}/>
				</Restricted>
			</ToolbarBox>
		});
	}, [activeTab]);
	const onLayoutChange = (localLayouts: Layout[], allLayouts: Layouts) => {
		const newDashboardItems: DashboardItem<number | undefined>[] = items.map(item => {
			const layout: Layout = localLayouts.find(layout => layout.i === JSON.stringify(item.id)) ?? DEFAULT_LAYOUTS[item.item_type];
			Object.assign(layout, DEFAULT_LAYOUTS[item.item_type]);
			return ({
				...item,
				id: typeof item.id == 'number' ? item.id : undefined,
				layout
			});
		});
		if (loadingState === LoadingStateEnum.LOADED && activeTab) updateDashboard(activeTab, newDashboardItems).then(reset);
		setLayouts(allLayouts);
	};


	if (activeTab === undefined) return <></>;

	return <DashboardContext.Provider value={{ currentItem, setCurrentItem, companyModal, setCompanyModal }}>
		<Restricted to={{ objectAction: 'ViewDashboard' }} fallback={<Unhautorized />}>
			{items.length === 0 &&
				<NoData
					message={translateToString('no_dashboard')}
					height='calc(100vh - 170px - 65px)'
					messageButton={translateToString('create_your_dashboard')}
					backgroundColor="inherit"
					onClickButton={() => setDashboardPopupOpen(true)}
				/>}
			{items.length > 0 && <GridStyles>
				<ResponsiveGridLayout
					className="layout"
					layouts={layouts}
					breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
					cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					rowHeight={30}
					width={width - 100}
					onLayoutChange={onLayoutChange}
				>
					{items.map((item) => (
						<div
							key={JSON.stringify(item.id)}
							id={JSON.stringify(item.id)}
							className="widget"
							data-grid={{ w:item.layout?.minW, h:item.layout?.minH, x: 0, y: Infinity }}
						>
							<Widget
								item={item}
								setItem={ newItem => {
									setItems(items => {
										const newItems = items.map(item => {
											if (item.id === newItem.id) return newItem;
											return item;
										});
										updateDashboard(activeTab, newItems.map(item => ({ ...item, id: typeof item.id === 'number' ? item.id : undefined }))).then(reset);
										return items;
									});
								}}
								onClickDelete={(id: number) => {
									alert({
										title: translateToString('do_you_want_to_delete_the_target', [['target', items.find(item => item.id === id)?.title ?? translateToString('widget').toLocaleLowerCase()]]),
										content: <div>{translateToNode('attention_delete_widget')}</div>,
										mode:'delete'
									}).then(res => {
										if (res === AlertRes.Ok) {
											setItems(items => {
												const newItems = items.filter(item => item.id !== id);
												updateDashboard(activeTab, newItems.map(item => ({ ...item, id: typeof item.id === 'number' ? item.id : undefined }))).then(reset);
												return newItems;
											});
										}
									});
								}}
								onClickEdit={(item: DashboardItem<Id>) => {
									setCurrentItem(item);
									setDashboardPopupOpen(true);
									setPage(Page[`${item.item_type}Creator`]);
								}}
							/>
						</div>
					))}
				</ResponsiveGridLayout>
			</GridStyles>}
			<DashboardPopup
				isOpen={dashboardPopupOpen}
				setOpen={setDashboardPopupOpen}
				items={items}
				setItems={setItems}
				page={page}
				setPage={setPage}
				editItem={(newItem) => setItems(items => {
					const newItems = items.map(item => {
						if (item.id === newItem.id) return newItem;
						return item;
					});
					updateDashboard(activeTab, newItems.map(item => ({ ...item, id: typeof item.id === 'number' ? item.id : undefined }))).then(reset);
					return items;
				})}
				activeTab={activeTab ?? 0}
			/>
			<ClientCompany
				isOpen={companyModal.isOpen}
				setOpen={b => setCompanyModal({ isOpen: b })}
				clientCompanyId={companyModal.data ?? 0}
				fullOpenMode={companyModal.fullOpenMode}
			/>
		</Restricted>
	</DashboardContext.Provider>;
}