module.exports = process.env.NODE_ENV === 'production'
	? (() => {
		const env = document.location.hostname.split('.').reverse()[2] ?? 'app';
		const ids = { google: '', hubspot: '', outlook_calendar: '' };
		switch (env) {
			case 'edge':
				ids.google = '564137750026-m27bq8bj9g0rtujkbn8u8auisktt7bvq.apps.googleusercontent.com';
				ids.hubspot = '7f820813-754a-42c8-916f-59fb89651e25';
				ids.outlook_calendar = 'c30d37ef-6418-40bd-a63f-d1a9534b7144';
				break;
			case 'stage':
				ids.google = '564137750026-i1h8cm687m9igcag8f4tombioloup610.apps.googleusercontent.com';
				ids.hubspot = 'd17afb5c-bf0a-4b7b-9589-6e01cf152b68';
				ids.outlook_calendar = '655d107b-2196-4d92-94c9-3a724de20481';
				break;
			default: 
				ids.google = '564137750026-dd20p1qnicsirnrtjalrdtng5fb5725c.apps.googleusercontent.com';
				ids.hubspot = 'fea77b7e-36ce-42ec-9e3c-6064eff205b2';
				ids.outlook_calendar = '206d45f4-9bfa-4866-915e-d229b56d242d';
				break;
		}
		return {
			URL_API: `https://api.${env}.sidely.app`,
			URL_API_BUILDER: `https://form.${env}.sidely.app`,
			URL_FOUNDATION: `https://foundation.${env}.sidely.app`,
			URL_FOUNDATION_WS: `wss://foundation.${env}.sidely.app/ws/`,
			URL_CERBERUS: `https://cerberus.${env}.sidely.app`,
			URL_JANUS: `https://janus.${env}.sidely.app`,
			URL_ALEXANDRIA: `https://alexandria.${env}.sidely.app`,
			HUBSPOT_CLIENT_ID: ids.hubspot,
			GOOGLE_CLIENT_ID: ids.google,
			OUTLOOK_CALENDAR_CLIENT_ID: ids.outlook_calendar,
			SENTRY_DSN: 'https://aee4c2334cc0568a05d9d11bda2d697e@o4508693982674944.ingest.de.sentry.io/4508754672353360'
		};
	}
	)()
	: {
		URL_API: process.env.URL_API,
		URL_FOUNDATION: process.env.URL_FOUNDATION,
		URL_CERBERUS: process.env.URL_CERBERUS,
		URL_FOUNDATION_WS: process.env.URL_FOUNDATION_WS,
		URL_API_BUILDER: process.env.URL_API_BUILDER,
		URL_JANUS: process.env.URL_JANUS,
		URL_ALEXANDRIA: process.env.URL_ALEXANDRIA,
		HUBSPOT_CLIENT_ID: process.env.HUBSPOT_CLIENT_ID || 'fea77b7e-36ce-42ec-9e3c-6064eff205b2',
		GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID || '564137750026-lp81duqfmd7hfb05jm7no2dn9ku8hg6a.apps.googleusercontent.com',
		OUTLOOK_CALENDAR_CLIENT_ID: process.env.OUTLOOK_CALENDAR_CLIENT_ID || '206d45f4-9bfa-4866-915e-d229b56d242d',
		SENTRY_DSN: process.env.SENTRY_DSN || ''
	};
