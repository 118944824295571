import axios from 'axios';
import { FieldType } from '../../../typings/bindings/forms/FieldType';
import { Field } from '../../atoms/forms';
import { FilterId } from '../../components_v2/filter/model/Model';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { URL_FOUNDATION } from '../../config/keys';
import { translateToString } from '../../styles/global/translate';
import { DefaultTableQuery } from '../checkouts/actions';
import { ServerAdvancedFilter } from '../client-companies/model/Model';
import { Fields, Form } from './create-from-template/type';
import { Screen } from './jsonValidator';

export function generateFormWithTemplate(grid: boolean, form: Form, name: string, forceResume: number, fieldTemplates: Fields[], createAdditionalColumn: boolean, template: string) {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/templates/generate`, { form, name: name, force_resume: forceResume, field_templates: fieldTemplates, create_additional_column: createAdditionalColumn, template: template, grid: grid });
}

export type FormInstance = {
	count: number,
	started_at: string,
	made_at: string,
	created_at: string,
	progression: number,
	created_by: number,
	uuid: string,
	linked_company: string,
	name: string,
	additional_field_columns_value: { [key: string]: { value: unknown }},
	updated_at?: string
}

export async function getFormInstances(id: number, body?: DefaultTableQuery): Promise<FormInstance[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/instances/${id}`, body ?? {}).then(res => res.data);
}

export async function getFormInstancesFilters(): Promise<{filters: FilterParameter[], columns: FilterId[]}> {
	return axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/v2/forms/instances/filters`).then(res => {
		return {
			filters: [res.data.reduce((acc: FilterParameter, f) => {
				acc.filters.push({
					id: f[0],
					name: translateToString(f[0] as string),
					type: f[1]
				});
				return acc;
			}, { category: translateToString('forms'), filters: [] })],
			columns: res.data.map(c => c[0]),
		};
	});
}

export type KanbanFormInstance = {
	uuid: string,
	made_at: string,
	progression: number,
	created_by: number,
	linked_company?: string,
	value: string | null,
	field_value_uuid: string | null,
}

export async function getKanbanFormInstances(id: number, fieldId: number, body: DefaultTableQuery): Promise<KanbanFormInstance[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/instances/${id}/kanban/${fieldId}`, body).then(res => res.data);
}

export async function getLinkedFormInstance(id: number): Promise<{ [key: string]: FormInstance[] }> {
	const res = await axios.get<FormInstance[]>(`${URL_FOUNDATION}/api/v2/forms/instances/linked/${id}`);
	const sres = res.data.reduce<{ [key: string]: FormInstance[] }>((acc, f) => {
		if (!acc[f.name]) {
			acc[f.name] = [];
		}
		acc[f.name].push(f);
		return acc;
	}, {});
	return sres;
}

export function deleteFormInstance(id: string): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/forms/instances/${id}`);
}

export type Metadata = {
	[key: string]: string
}


export type InstanceField = {
	id: number,
	type: FieldType,
	value: object | string | number | Date,
	metadata: Metadata,
	name: string,
	slug: string,
}


export function getInstanceFields(id: string): Promise<InstanceField[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/instance-fields/${id}`).then(res => res.data);
}

export type Instance = {
	uuid: string,
	form_id: number,
	made_at?: string,
	created_by: number,
	progression: number,
	started_at?: string,
	linked_event?: number,
	name: string,
	screens: Screen[],
	linked_company_id?: number,
	linked_company_name?: string,
	created_at: string,
}

export async function getInstanceByUuid(uuid: string): Promise<Instance> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/instance/${uuid}`).then(res => res.data);
}

export async function getField(id: number): Promise<Field> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/field/${id}`).then(res => res.data);
}

export async function putField(id: number, body: Omit<Field, 'slug'>): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/forms/field/${id}`, body);
}

export function postForm(body): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/templates`, body);
}

export function postField(body): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/forms/fields`, body);
}

export function deleteFormTemplate(id: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/forms/templates/${id}`);
}

export function deleteField(id: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/forms/field/${id}`);
}

export async function getClientCompanyByUuid(companyUuid: string): Promise<{ name: string }> {
	return axios.get(`${URL_FOUNDATION}/api/v2/client-companies/uuid/${companyUuid}`).then(res => res.data).then(data => {
		return { name: data.company_name };
	});
}

export async function getProductByUuid(companyUuid: string): Promise<{ name: string }> {
	return axios.get(`${URL_FOUNDATION}/api/v2/products/uuid/${companyUuid}`).then(res => res.data).then(data => {
		return { name: data.name };
	});
}

export async function exportFieldValues(formId: number, formName: string, body: DefaultTableQuery): Promise<void> {
	const blob = await axios.post(`${URL_FOUNDATION}/api/v2/export/field-values?form_id=${formId}`, body, { responseType: 'blob' });
	const href = window.URL.createObjectURL(blob.data);
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', `export-${formName}-${(new Date()).toISOString()}.csv`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
}

export async function exportGroupedFieldValues(formId: number, formName: string, body: DefaultTableQuery): Promise<void> {
	const blob = await axios.post(`${URL_FOUNDATION}/api/v2/export/grouped?form_id=${formId}`, body, { responseType: 'blob' });
	const href = window.URL.createObjectURL(blob.data);
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', `export-${formName}-${(new Date()).toISOString()}.csv`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
}

export async function putFieldValue(body: { uuid?: string, field_id: number, form_instance_id: string, value: unknown, metadata: unknown }): Promise<string | undefined> {
	return axios.put(`${URL_FOUNDATION}/api/v2/forms/field_values`, body).then(res => res.data);
}

export type MetadataPeriod = {
	metadata: string
}

export async function getMetadataPeriods(): Promise<MetadataPeriod[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/field_values/metadata_periods`).then(res => res.data);
}

export type View = {
  id: number
  name: string
  template: string
  sequence: number
}

export async function getFormViews(formId: number): Promise<View[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/forms/views?form_id=${formId}`).then(res => res.data.sort((a, b) => a.sequence - b.sequence));
}
