import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../../config/keys';
import { colorTypeMapping } from '../../containers_v2/calendar/model/Model';
import { EventType as LegacyEventType } from '../../containers_v2/client-companies/model/Model';

// import { EventType as ProtoEventType } from '../../../typings/proto/protobufs';

export enum EventType {
    Task = 0,
    Call = 1,
    Meeting = 2,
    Visit = 3,
    Sms = 4,
    Email = 5,
    Promotion = 6,
    Animation = 7,
	Delivery = 8,
	Order = 9,
	TimeOff = 10,
	PublicHoliday = 11,
	Session = 12,
	Training = 13,
	TradeShow = 14,
	SickLeave = 15,
	WarehouseVisit = 16,
	VehicleMaintenance = 17,
	SampleCollection = 18
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EventType {
	export const toString = (type: EventType): string => Object.keys(EventType).filter((v) => isNaN(Number(v)))[type];
}

export type AtomEventType = {
	id: number
	name: string
	status: boolean
	created_at: string
	updated_at: string
	deleted_at: string
	color: string,
	map?: boolean
}

// -----------------------[ ATOM ]----------------------- //
export const AEventTypes = selector<AtomEventType[]>({
	key: 'AEventTypes',
	get: async() => (await getEventTypes()).map(t => {
		const mapping = colorTypeMapping.find(ct => ct.id == t.id);
		return ({ ...t, color: mapping?.color ?? '', map: mapping?.map });
	})
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getEventTypes(): Promise<LegacyEventType[]> {
	return await axios.get(`${URL_FOUNDATION}/api/crm/event-types`)
		.then(response => {
			const events: LegacyEventType[] = response.data as LegacyEventType[];
			const index = events.findIndex(item => item.name === 'Visit');
			if (index !== -1) {
				const visit = events.splice(index, 1)[0];
				events.unshift(visit);
			}
			return events;
		})
		.catch(e => {
			console.log(e);
			return [];
		});
}