import * as React from 'react';
import { Label } from '../dropdown/style/Style';
import { InputProps } from './model/Model';
import { Asterisk, Container, InputBlock, InputContainer, InputLogo, LabelContainer, TextArea } from './style/Style';
import styled from 'styled-components';
import { BlueSidely, LightBlueSidely } from '../../styles/global/css/Utils';

export const HeaderTitleInput = styled.input`
	font-weight: 700;
	font-size: 15px;
	max-width: 100%;
	text-overflow: ellipsis;
	border: none;
	&:hover {
		border-bottom: 1px solid ${BlueSidely};
    	margin-bottom: -1px;
	}
	&:focus {
    	background-color: ${LightBlueSidely};
  	}
`;

export function blurOnEnterPressed(e: React.KeyboardEvent<HTMLInputElement>) {
	if (e.key === 'Enter') {
		(document.activeElement as HTMLElement).blur();
	}
}

function ValueChange(prevValue: string | number, currentValue: string | number, props: InputProps, setValue, canUpdate: boolean): void {
	if (props.normalize != null) {
		props.normalize.forEach(f => {
			currentValue = f(currentValue);
		});
	}

	let valid = true;

	if (props.validate != null) {
		props.validate.forEach(f => {
			if (valid) {
				valid = f(currentValue);
			}
		});
	}

	if (valid) {
		setValue(currentValue);
		if ((props.onChange != null) && canUpdate) {
			props.onChange(currentValue);
		}
	} else {
		setValue(prevValue);
		if ((props.onChange != null) && canUpdate) {
			props.onChange(prevValue);
		}
	}
}

function Input(props: InputProps): JSX.Element {
	const [value, setValue] = React.useState<string | number>(props.value ?? '');
	const [canUpdate, setCanUpdate] = React.useState<boolean>(!props.delay);
	const [timeoutId, setTimeoutId] = React.useState<number | undefined>(undefined);
	const width = props.inputStyle?.width ? props.inputStyle?.width : '300px';
	const textAlign = props.inputStyle?.textAlign ? 'center' : 'left';

	React.useEffect(() => {
		setValue(props.value ?? '');
	}, [props.value]);

	function update(value: any): void {
		clearTimeout(timeoutId);
		setCanUpdate(false);

		const id = setTimeout(() => {
			setTimeoutId(undefined);
			setCanUpdate(true)
			;(props.onChange != null) && props.onChange(value);
		}, props.delay, canUpdate, timeoutId, value);

		setTimeoutId(id);
	}

	const onValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!props.disableChange) {
			props.delay && update(e.target.value);
			setValue(e.target.value);
			ValueChange(value, e.target.value, props, setValue, canUpdate);
		}};

	return (
		<Container flexDirection={props.inputStyle?.labelUp ? 'column' : 'row'} height={props.inputStyle?.height} marginLeft={props.inputStyle?.marginLeft} marginRight={props.inputStyle?.marginRight} width={props.inputStyle?.containerWidth} padding={props.paddingLabel ? '21px 0 0 0' : undefined} margin={props.inputStyle?.margin} flexShrink={props.inputStyle?.flexShrink} alignSelf={props.inputStyle?.alignSelf}>
			{
				props.label &&
				<LabelContainer>
					{
						props.required &&
						<Asterisk />
					}
					<Label {...props.inputStyle?.labelStyle}>{props.label}</Label>
				</LabelContainer>
			}
			<InputContainer cursor={props.inputStyle?.cursor} hasBorder={props.inputStyle?.hasInputBorder} borderRadius={props.inputStyle?.borderRadius} backgroundColor={props.inputStyle?.backgroundColor} height={props.inputStyle?.height}>
				{
					(props.JSXContent != null) &&
					<InputLogo maxHeight={props.inputStyle?.height}>
						{props.JSXContent}
					</InputLogo>
				}
				{props.textArea
					? <TextArea padding={props.inputStyle?.padding}
						placeholderColor={props.inputStyle?.placeholderColor}
						borderRadius={props.inputStyle?.borderRadius}
						style={{ width, textAlign, height: props.inputStyle?.height }}
						id={props.id}
						name={props.name}
						type={props.type}
						onChange={onValueChange}
						value={value}
						required={props.required}
						disabled={props.disabled}
						placeholder={props.placeholder}
						maxLength={props.maxLength}
						onClick={props.onClick}
						spellCheck={props.spellcheck}
						{...props.extraInputAttribute}
					/>
					: <InputBlock padding={props.inputStyle?.padding}
						removePlaceHolderOnFocus={props.inputStyle?.removePlaceHolderOnFocus}
						placeholderColor={props.inputStyle?.placeholderColor}
						borderRadius={props.inputStyle?.borderRadius}
						style={{ width, textAlign, height: props.inputStyle?.height, cursor: props.inputStyle?.cursor, textOverflow:props.inputStyle?.textOverflow }}
						id={props.id}
						name={props.name}
						type={props.type}
						border={props.inputStyle?.border}
						fontSize={props.inputStyle?.fontSize}
						onChange={onValueChange}
						value={value}
						step={props.step}
						required={props.required}
						disabled={props.disabled}
						placeholder={props.placeholder}
						maxLength={props.maxLength}
						onClick={props.onClick}
						fontWeight={props.inputStyle?.fontWeight}
						color={props.inputStyle?.color}
						{...props.extraInputAttribute}
						onKeyDownCapture={blurOnEnterPressed}
						onBlur={e => props.onBlur?.(e)}
						mode={props.mode}
						min={props.min}
						max={props.max}
						pattern={props.pattern}
						autoFocus={props.autoFocus}
					/>
				}

			</InputContainer>
		</Container>
	);
}

export default Input;
