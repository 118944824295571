import * as React from 'react';
import Popup from '../../components_v2/popup/Popup';
import { PopupMode } from '../../components_v2/popup/model/Model';
import PopupCreation, { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { Translate, translateToString } from '../../styles/global/translate';
import Input from '../../components_v2/input/Input';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../client-companies/style/Style';
import { normalizeCapital } from '../globals/FieldFunction';
import { FlexDiv } from '../products/style';
import { LeftContainer, RightContainer } from '../client-companies/style/PopupStyle';
import styled from 'styled-components';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import useAlert from '../alert/UseAlert';
import { AlertContext, AlertRes } from '../alert/AlertProvider';
import { TARGET_ENUM_LIST, Target, TargetData, TargetEnum } from './Targets';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import { BorderColor, GreenSidely } from '../../styles/global/css/Utils';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { ALL_GRANULARITY } from '../../components_v2/toolbarFilter/time/GranularitySelect';
import { Granularity } from 'bindings/time/Granularity';
import { InputStyle } from '../../components_v2/input/model/Model';
import { DropdownStyle } from '../../components_v2/dropdown/model/Model';
import { Owner } from '../orders/model/Model';
import DropdownOwners, { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import { DeleteDot } from '../../styles/global/css/Dot';
import * as moment from 'moment';
import Add from '../../components_v2/add/Add';
import { DotWrapper, SaveWrapper } from '../promotions/PopupPromotion';
import { TimeCarousel } from '../map/calendarMapView';
import { diff } from '../../components_v2/utils';
import { PostTargetBody, PutTargetBody, TargetChartData, deleteTarget, getTargetById, getTargetChart } from './actions';
import { ProgressBar } from 'react-bootstrap';
import optionGrey from 'images/icon/options_grey.png';
import RestrictedSuperAdmin from '../permissions/RestrictedSuperAdmin';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';
import PermissionContext from '../permissions/PermissionContext';

const DATE_FORMAT = 'YYYY-MM-DD';

const localCreationPopupInputStyle: InputStyle = {
	...creationPopupInputStyle,
	margin: `0 ${creationPopupInputStyle.marginRight ?? 0} 21px ${creationPopupInputStyle.marginLeft ?? 0}`

};

const localCreationPopupDropdownStyle: DropdownStyle = {
	...creationPopupDropdownStyle,
	margin: `11px ${creationPopupDropdownStyle.marginRight ?? 0} 11px 0px`

};

type Props = {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	target?: Target,
	onCreate?: (body: PostTargetBody) => void,
	onUpdate?: (id: number, body: PutTargetBody) => void,
	onDelete?: (id: number) => void,
	readOnly?: boolean,
	targetId?: number
}

const UserTargetPickerDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	gap: 1em;
	padding-right: 1em;
	position: relative;
	background: white;
	border-radius: 10px;
`;

const UserTargetPickerLeftDiv = styled.div`
	width: 200px;
	transition: width 0.4s, padding: 0.25s;
	height: calc(100% - 10px);
	display: flex;
	gap: 1em;
	align-items: center;
	border-right: 1px solid ${BorderColor};
	padding-right: 0.5em;
	flex-shrink: 0;
`;

const HiddableDiv = styled.div<{ parent? }>`
	width: 0px;
	transition: 0.4s;
	overflow: hidden;
	${({ parent }) => parent ?? UserTargetPickerLeftDiv}:hover & {
		width: calc(25px + 1em);
		padding-left: 1em;
	}
	flex-shrink: 0;
`;

const UserTargetPickerRightDiv = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	flex-shrink: 1;
	width: inherit;
	gap: 2em;
	overflow: auto;
	position: relative;
	height: 100%;
	background: white;
`;

const UserWrapper = styled.div<{ deleteAnim: boolean }>`
	position: relative;
	border: 1px solid ${BorderColor};
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.05) 1px 0px 2px;
	transition: 0.4s;
	height: 60px;
	${({ deleteAnim }) => deleteAnim ? `
		height: 0px;
		overflow: hidden;
		border: none;
		margin-top: -1em;
	` : ''}
`;

const ApplyAllWrapper = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	transition: 0.4s;
	overflow: hidden;
	background: white;
	bottom: 0;
	gap: 0.5em;
	left: 200px;
	padding: 5px 0.5em;
	border: 1px solid ${BorderColor};
	border-radius: 0 0 10px 10px;
	border-top: none;
	${UserWrapper}:hover & {
		translate: 0 100%;
	}
	box-shadow: rgba(0, 0, 0, 0.05) 1px 0px 2px;
	flex-shrink: 0;
`;

type GranularityOptions = {
	maxI: number,
	startOf: Granularity,
	format: string,
	timeDifference: number,
	timeDifferenceGranularity: Granularity,
	formatFn: (time: moment.Moment) => string
}

const GRANULARITY_OPTIONS: { [keys in Granularity]: GranularityOptions } = {
	'day': {
		maxI: 7,
		startOf: 'week',
		format: 'dd Do MMM',
		timeDifference: 7,
		timeDifferenceGranularity: 'day',
		formatFn: time => `${moment(time).startOf('week').format('Do MMM')} -> ${moment(time).endOf('week').format('Do MMM YYYY')}`
	},
	'month': {
		maxI: 12,
		startOf: 'year',
		format: 'MMM YYYY',
		timeDifference: 1,
		timeDifferenceGranularity: 'year',
		formatFn: time => time.format('YYYY')
	},
	'quarter': {
		maxI: 4,
		startOf: 'year',
		format: 'Qo YYYY',
		timeDifference: 1,
		timeDifferenceGranularity: 'year',
		formatFn: time => time.format('YYYY')
	},
	'week': {
		maxI: 4,
		startOf: 'month',
		format: 'wo YYYY',
		timeDifference: 1,
		timeDifferenceGranularity: 'month',
		formatFn: time => time.format('MMM YYYY')
	},
	'year': {
		maxI: 10,
		startOf: 'year',
		format: 'YYYY',
		timeDifference: 10,
		timeDifferenceGranularity: 'year',
		formatFn: time => `${time.format('YYYY')} -> ${moment(time).add(9, 'year').format('YYYY')}`
	}
};

function getGranularityTicks(granularity: Granularity, date: moment.Moment): [moment.Moment, string][] {
	const opt = GRANULARITY_OPTIONS[granularity];
	const start = moment(date).startOf(opt.startOf);
	const res: [moment.Moment, string][] = [[start, start.format(opt.format)]];
	for (let i = 1; i < opt.maxI; i++) {
		const date = moment(start).add(i, granularity);
		res.push([date, date.format(opt.format)]);
	}
	return res;
}

function GranularityTick(props: { userId: number, title: string, data: TargetData | undefined, setData: React.Dispatch<React.SetStateAction<TargetData | undefined>>, date: moment.Moment }) {
	const dataKey = props.date.format(DATE_FORMAT);
	return <FlexDiv flow='column'>
		{props.title}
		<Input
			normalize={[value => {
				const v = typeof value === 'string' ? parseInt(value) : value;
				return v < 0 ? 0 : v;
			}]}
			value={props.data?.[props.userId]?.[dataKey]}
			onChange={value => {
				props.setData(data => {
					if (!data) data = {};
					if (!data[props.userId.toString()]) data[props.userId.toString()] = {};
					data[props.userId.toString()][dataKey] = value;
					return { ...data };
				});
			}}
			name=''
			type='number'
			inputStyle={{
				width: '75px',
			}}
		/>
	</FlexDiv>;
}

function UserTargetPicker(props: { user: Owner, date: moment.Moment, granularity: Granularity, data: TargetData, setData: React.Dispatch<React.SetStateAction<TargetData>> }) {
	const granularityTicks = getGranularityTicks(props.granularity, props.date);
	const [applyAll, setApplyAll] = React.useState<number>();
	const [deleteAnim, setDeleteAnim] = React.useState(false);

	return <UserWrapper
		deleteAnim={deleteAnim}
		onTransitionEnd={e => {
			//@ts-expect-error it works
			if (e.target.clientHeight === 0) props.setData(data => {
				delete data[props.user.id.toString()];
				return { ...data };
			});
		}}>
		<ApplyAllWrapper>
			<Input
				normalize={[value => {
					const v = typeof value === 'string' ? parseInt(value) : value;
					return v < 0 ? 0 : v;
				}]}
				value={applyAll}
				onChange={setApplyAll}
				name=''
				type='number'
				inputStyle={{
					width: '75px',
				}}
			/>
			<DefaultButton
				margin='0'
				disabled={applyAll === undefined}
				onClick={() => {
					props.setData(data => {
						if (!data) data = {};
						if (!data[props.user.id.toString()]) data[props.user.id.toString()] = {};
						granularityTicks.forEach(([date]) => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							data![props.user.id.toString()][date.format(DATE_FORMAT)] = applyAll ?? 0;
						});
						return { ...data };
					});
					setApplyAll(undefined);
				}}
			>
				<Translate id='apply_all' />
			</DefaultButton>
		</ApplyAllWrapper>
		<UserTargetPickerDiv>
			<UserTargetPickerLeftDiv>
				<HiddableDiv>
					<DeleteDot onClick={() => setDeleteAnim(true)}/>
				</HiddableDiv>
				<UserBlock user={props.user} noCursor flexStyle={{ flexShrink: 1, overflow: 'hidden' }}/>
			</UserTargetPickerLeftDiv>
			<UserTargetPickerRightDiv>
				{granularityTicks.map(([date, title], i) => <GranularityTick userId={props.user.id} title={title} key={`granularityTick[${props.user.id}][${i}]`} date={date} data={props.data} setData={props.setData}/>)}
			</UserTargetPickerRightDiv>
		</UserTargetPickerDiv>
	</UserWrapper>;
}

function RightPart(props: Props & { readOnly?: boolean, granularity: Granularity, data: TargetData, setData: React.Dispatch<React.SetStateAction<TargetData>> }) {
	const [time, setTime] = React.useState<moment.Moment>(moment());
	const recoilUsers = useRecoilValue(AUsers);
	const users: Owner[] = [];
	const { alertDelete } = React.useContext(AlertContext);
	Object.keys(props.data).forEach(key => {
		const user = recoilUsers.find(u => u.id.toString() === key);
		if (user) users.push(user);
	});
	users.reverse();

	return <FlexDiv flow='column-reverse' align='stretch' gap='1em' width='100%' height='100%' padding='0 0 0 10px' justify='flex-end'>
		{!props.readOnly && <Restricted to={{ objectAction: 'DeleteObjective' }} ownerId={props.target?.created_by}>
			<DotWrapper>
				<Dropdown
					dropdownStyle={{
						optionLeft: '-170px',
						optionWidth: '200px',
						optionHeight: '200px',
						height: '25px'
					}}
					datalist={[{
						label: translateToString('delete'),
						value: 'DELETE'
					}]}
					name={'dropdownOptionEvent'}
					JSXButton={() => <img src={optionGrey} width={25} style={{ rotate: '90deg' }}/>}
					onChange={(value) => {
						switch (value.value) {
							case 'DELETE':
								alertDelete({ name: props.target?.name ?? '', zIndex: 999 }).then(res => {
									if (res == AlertRes.Ok && props.target) {
										deleteTarget(props.target.id).then(() => {
											props.setIsOpen(false);
											props.onDelete?.(props.target?.id ?? 0);
										});
									}
								});

						}}
					}/>
			</DotWrapper>
		</Restricted>}
		{users.length !== recoilUsers.length && <DropdownOwners
			users={recoilUsers.filter(u => users.every(u2 => u2.id !== u.id)).map(u => ({ label: u.name, value: u }))}
			onChange={v => {
				props.setData(data => {
					if (!v.value) return { ...data };
					data[v.value.id.toString()] = {};
					return { ...data };
				});
			}}
			JSXButton={() => <Add />}
		/>}
		{users.map(u => <UserTargetPicker
			key={u.id}
			user={u}
			date={time}
			granularity={props.granularity}
			data={props.data}
			setData={props.setData}
		/>)}
		<div style={{ alignSelf: 'center' }}>
			<TimeCarousel
				format={GRANULARITY_OPTIONS[props.granularity].formatFn}
				granularity={GRANULARITY_OPTIONS[props.granularity].timeDifferenceGranularity}
				dateGranularityDifferences={GRANULARITY_OPTIONS[props.granularity].timeDifference}
				time={time}
				onTimeChange={setTime}
			/>
		</div>
	</FlexDiv>;
}

const TargetChartDiv = styled.div<{ noBorder?: boolean }>`
	display: flex;
	flex-flow: column;
	gap: 0.5em;
	padding: 0.5em;
	${({ noBorder }) => noBorder ? '' : `
		border: 1px solid ${BorderColor};
		border-radius: 10px;
	`}
	width: 100%;
`;

const UserChartDiv = styled.div`
	display: flex;
	align-items: center;
	gap: 1em;
`;

const ProgressBarStyle = styled.div<{ value: number }>`
	width: 100%;
	color: ${GreenSidely};
	font-size: 11px;
	display: flex;
	align-items: center;
	gap: 0.5em;
	.progress .progress-bar {
		background-color: ${GreenSidely}${({ value }) => Math.floor((value > 1 ? 1 : value) * 255).toString(16).padStart(2, '0')};
		width: 100%;
	}
	.progress {
		flex-shrink: 0;
		width: calc(100% - 0.5em - 25px);
	}
`;

export function TargetChart(props: { target: Target, dashboard?: boolean }) {
	const [chartDatas, setChartDatas] = React.useState<TargetChartData[]>();
	const { target } = props;
	const users = useRecoilValue(AUsers);

	React.useEffect(() => {
		getTargetChart(target.id, moment().startOf(target.granularity).format(DATE_FORMAT), target.target).then(setChartDatas);
	}, [target.id]);

	let fn: (target: number | undefined, competed: number | undefined) => number;
	switch (target.target) {
		case 'Dn':
			fn = (target, competed) => !target ? 0 : (competed ?? 0) / (target / 100);
			break;
		case 'CompletedEvents':
		case 'CompletedTask':
		case 'CompletedCall':
		case 'CompletedMeeting':
		case 'CompletedVisit':
		case 'CompletedSms':
		case 'CompletedEmail':
		case 'CompletedAnimation':
		case 'CompletedPromotion':
		case 'CompletedDelivery':
		case 'CompletedOrder':
		case 'CompletedTimeOff':
		case 'CompletedPublicHoliday':
		case 'CompletedSession':
		case 'CompletedTraining':
		case 'CompletedTradeShow':
		case 'CompletedSickLeave':
		case 'CompletedWarehouseVisit':
		case 'CompletedVehicleMaintenance':
		case 'CompletedSampleCollection':
			fn = (target, competed) => !target ? 0 : (competed ?? 0) / target;
			break;
	}

	return <TargetChartDiv noBorder={props.dashboard}>
		<RestrictedSuperAdmin>
		</RestrictedSuperAdmin>
		{Object.keys(target.data).map(userId => {
			const user = users.find(u => u.id === parseInt(userId));
			const cd = chartDatas?.find(cd => cd.user_id === parseInt(userId));
			const value = cd ? fn(cd.target, cd.competed) : 0;
			return { user, value };}).sort((a, b) => b.value - a.value)
			.map(({ user, value }, index) =>
				(<UserChartDiv key={`UserChartBlock[${index}]`}>
					<UserBlock user={user} width='33%' noCursor />
					<ProgressBarStyle value={value}>
						<ProgressBar
							now={value}
							min={0}
							max={1}
						/>
						{(value * 100).toFixed()}%
					</ProgressBarStyle>
				</UserChartDiv>)
			)
		}
	</TargetChartDiv>;
}

function LeftPart(props: Props & { mode: PopupMode, setMode: React.Dispatch<React.SetStateAction<PopupMode>>} & {
	title: string | undefined,
	setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
	granularity: Granularity | undefined,
	setGranularity: React.Dispatch<React.SetStateAction<Granularity | undefined>>,
	targetTarget: TargetEnum | undefined,
	setTargetTarget: React.Dispatch<React.SetStateAction<TargetEnum>>,
}) {
	const { target, title, setTitle, granularity, setGranularity } = props;
	const { alertDelete } = React.useContext(AlertContext);
	const { isAllowedTo } = React.useContext(PermissionContext);

	return <PopupCreation
		hideValidation
		onModeChange={() => props.setMode(mode => mode === PopupMode.Details ? PopupMode.Default : PopupMode.Details)}
		extended={props.mode === PopupMode.Default}
		onClose={() => props.setIsOpen(false)}
		title={target?.name ?? translateToString('new_target')}
		dropdown={(props.readOnly || props.mode === PopupMode.Default || !isAllowedTo({ objectAction: 'DeleteObjective' }, { ownerId: target?.created_by })) ? undefined : {
			data: [{
				label: translateToString('delete'),
				value: 'DELETE'
			}],
			onChange: value => {
				switch (value.value) {
					case 'DELETE':
						alertDelete({ name: target?.name ?? '', zIndex: 999 }).then(res => {
							if (res == AlertRes.Ok && target) {
								deleteTarget(target.id).then(() => {
									props.setIsOpen(false);
									props.onDelete?.(target.id);
								});
							}
						});

				}
			}
		}}
	>
		<CreationPopupContainer gap='1em'>
			<Input
				required
				disabled={props.readOnly}
				inputStyle={localCreationPopupInputStyle}
				name="title"
				type="text"
				placeholder={translateToString('company.creation.event.title')}
				label={translateToString('company.creation.event.title')}
				normalize={[normalizeCapital]}
				onChange={setTitle}
				value={title}
			/>
			<Dropdown
				dropdownStyle={localCreationPopupDropdownStyle}
				label={translateToString('target')}
				required
				name='target_picker'
				datalist={TARGET_ENUM_LIST.map(value => ({ label: value, value }))}
				selectedValue={props.targetTarget ? { label: props.targetTarget, value: props.targetTarget } : undefined}
				onChange={value => props.setTargetTarget(value.value)}
				readOnly
			/>
			<Dropdown
				dropdownStyle={localCreationPopupDropdownStyle}
				label={translateToString('granularity')}
				required
				name='granularity_picker'
				selectedValue={granularity && { label: translateToString(granularity), value: granularity }}
				datalist={ALL_GRANULARITY.map(g => ({ label: translateToString(g), value: g }))}
				onChange={g => setGranularity(g.value)}
			/>
			{target && <TargetChart target={target}/>}
		</CreationPopupContainer>
	</PopupCreation>;

}

const DEFAULT_POPUP_MODE = PopupMode.Details;

function genDefaultData(users: Owner[]): TargetData {
	const res = {};
	users.forEach(u => {
		if (u.status) res[u.id.toString()] = {};
	});
	return res;
}

export default function PopupTarget(props: Props & { isOpen: boolean, mode?: PopupMode }) {
	const [target, setTarget] = React.useState<Target | undefined>(props.target);
	const [granularity, setGranularity] = React.useState<Granularity | undefined>(props.target?.granularity);
	const [mode, setMode] = React.useState<PopupMode>(DEFAULT_POPUP_MODE);
	const [title, setTitle] = React.useState<string | undefined>(target?.name);
	const users = useRecoilValue(AUsers);
	const [data, setData] = React.useState<TargetData>(target?.data ?? genDefaultData(users));
	const alert = useAlert();
	const [autoOpen, setAutoOpen] = React.useState<boolean>(false);
	const [targetTarget, setTargetTarget] = React.useState<TargetEnum>();

	React.useEffect(() => {
		if (!autoOpen && granularity && title && props.mode === undefined && targetTarget) {
			setAutoOpen(true);
			setMode(PopupMode.Default);
		}
	}, [granularity, title]);

	React.useEffect(() => {
		setTitle(target?.name);
		setGranularity(target?.granularity);
		setData(target?.data ? JSON.parse(JSON.stringify(target.data)) : genDefaultData(users));
		setTargetTarget(target?.target);
	}, [target]);

	React.useEffect(() => {
		setTarget(props.target);
	}, [props.target]);

	React.useEffect(() => {
		if (props.targetId === undefined) {
			setTarget(props.target);
		} else {
			getTargetById(props.targetId).then(setTarget);
		}
	}, [props.targetId]);

	React.useEffect(() => {
		props.mode !== undefined && setMode(props.mode);
	}, [props.mode]);

	const reset = () => {
		setMode(DEFAULT_POPUP_MODE);
		setTargetTarget(undefined);
		setTarget(undefined);
		setTitle(undefined);
		setGranularity(undefined);
		setAutoOpen(false);
		setData(genDefaultData(users));
	};

	const getDifferencesUpdate = (): PutTargetBody => {
		if (!target) return {
			name: title,
			data,
			target: targetTarget,
			granularity: granularity ?? 'month'
		};
		const body: PutTargetBody = {};
		if (target.name !== title) body.name = title;
		if (
			Object.entries(data).filter(([_, values]) => Object.keys(values).length !== 0).length !== Object.entries(target.data).filter(([_, values]) => Object.keys(values).length !== 0).length
			|| Object.entries(data).reduce((acc, [key, value]) => {
				if (acc) return acc;
				return target.data[key] === undefined || Object.keys(diff(target.data[key], value) ?? {}).length !== 0; 
			}, false)
		) body.data = data;
		if (target.target !== targetTarget) body.target = targetTarget;
		if (target.granularity !== granularity) body.granularity = granularity;
		return body;
	};


	const close = (force: boolean) => {
		if (force || props.readOnly || Object.keys(getDifferencesUpdate()).length === 0) {
			props.setIsOpen(false);
			reset();
			return;
		}
		alert({
			zIndex: 999,
			title: translateToString('unsaved_changes'),
			content: translateToString('unsaved_changes_leave'),
			buttons: [{ title: translateToString('yes'), res: AlertRes.Ok, style: ButtonStyle.White }]
		}).then(res => {
			if (res == AlertRes.Ok) {
				props.setIsOpen(false);
				reset();
			}
		});
	};

	React.useEffect(() => {
		setTitle(target?.name);
		setGranularity(target?.granularity);
	}, [target]);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={() => close(false)}
		popupMode={mode}
		popupStyle={{ animate: true, minWidth: '450px' }}
		name='Prom'
	>
		<Restricted to={{ objectAction: target ? 'ReadObjective' : 'CreateObjective' }} fallback={<Unhautorized />} ownerId={target?.created_by}>
			<FlexDiv height='100%' width='100%' flow='column'>
				<FlexDiv height='100%' width='100%' flexShrink={1} minHeight='0'>
					<LeftContainer isFullOpen={mode === PopupMode.Default}>
						<LeftPart {...props}
							target={target}
							setIsOpen={close}
							mode={mode}
							setMode={setMode}
							title={title}
							setTitle={setTitle}
							granularity={granularity}
							setGranularity={setGranularity}
							targetTarget={targetTarget}
							setTargetTarget={setTargetTarget}
						/>
					</LeftContainer>
					<RightContainer isFullOpen={mode === PopupMode.Default} isOpen={false}>
						<RightPart {...props}
							granularity={granularity ?? 'month'}
							target={target}
							readOnly={props.readOnly}
							data={data}
							setData={setData}
						/>
					</RightContainer>
				</FlexDiv>
				<Restricted to={{ objectAction: target ? 'UpdateObjective' : 'CreateObjective' }} ownerId={target?.created_by}>
					<SaveWrapper isOpen={!props.readOnly && Object.keys(getDifferencesUpdate()).length !== 0}>
						<DefaultButton
							width='200px'
							disabled={props.readOnly || Object.keys(getDifferencesUpdate()).length === 0 || !title || !granularity || !targetTarget || Object.entries(data).filter(([_, values]) => Object.keys(values).length === 0).length !== 0 || Object.keys(data).length === 0}
							onClick={() => {
								if (props.readOnly) return;
								if (target?.id) props.onUpdate?.(target.id, getDifferencesUpdate());
								else if (title && granularity && targetTarget) {
									props.onCreate?.({
										name: title,
										data,
										granularity,
										target: targetTarget
									});
								} else return;
								close(true);
							}}><Translate id='save' /></DefaultButton>
					</SaveWrapper>
				</Restricted>
			</FlexDiv>
		</Restricted>
	</Popup>;
}