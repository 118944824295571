/* eslint-disable @typescript-eslint/no-non-null-assertion */
import optionGrey from 'images/icon/options_grey.png';
import * as moment from 'moment';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { FilterId, FilterResult } from '../../../components_v2/filter/model/Model';
import Pagination from '../../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../../components_v2/pagination/model/Model';
import { Column, Table, TableSortType } from '../../../components_v2/table/Table';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { useFunctionState } from '../../../utils/customHooks';
import { AlertContext, AlertRes } from '../../alert/AlertProvider';
import { DEFAULT_STEP } from '../../authorization/model';
import { ColumnResume, additionalFieldColumnIdFormatter } from '../../client-companies/Companies';
import { DateAccessor, FormFieldColumnDisplayer } from '../../client-companies/data/CompanyColumns';
import { ColumnHidder } from '../../contact/data/ContactColumns';
import { TableContext } from '../../contact/data/ContactContext';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { TableRow } from '../../orders/templateOrders/style/Style';
import { FlexDiv } from '../../products/style';
import { FormInstance, deleteFormInstance, exportFieldValues, exportGroupedFieldValues, getFormInstances } from '../actions';
import { FORM_HIDDEN_LOCAL_STORAGE, FORM_SORT_LOCAL_STORAGE, FormInstanceViewSubProps } from './FormInstanceView';
import { isSuperAdmin } from '../../../components_v2/utils';
import { toast } from 'react-toastify';
import _ from 'lodash';

const DEFAULT_PAGINATION = { step: DEFAULT_STEP, currentPage: 1, offset: 0 };
const DEFAULT_SORT = { id: 'made_at', desc: true };

export default function FormInstanceList(props: FormInstanceViewSubProps) {
const LOCAL_STORAGE_RESIZE_KEY = 'table_form_instances_resize';
	const [instances, setInstances] = React.useState<FormInstance[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>(DEFAULT_PAGINATION);
	const { alertDelete } = React.useContext(AlertContext);
	const users = useRecoilValue(AUsers);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADING);
	const { formId, template, fieldColumns, setFilterState } = props;
	const context = React.useContext(TableContext);
	const { hiddenColumns, sortColumns, filterResult, setFilterResult } = context;
	const [resize, setResize] = React.useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_RESIZE_KEY) || '{}'));

	const [, setSort] = useFunctionState<TableSortType | undefined>(DEFAULT_SORT, ({ oldValue, newValue }) => {
		if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) refresh();
		return newValue;
	});
	const isSuper = isSuperAdmin();
	const ALL_FORM_COLUMNS: Array<ColumnResume> = [
		{ name: 'started_at', categoryIndex: 0, translationKey: 'started_on' },
		{ name: 'updated_at', categoryIndex: 0 },
		{ name: 'made_at', categoryIndex: 0, translationKey: 'ended_on' },
		{ name: 'created_at', categoryIndex: 0, excluded: !isSuper, translationKey: 'synched_on' },
		{ name: 'progression', categoryIndex: 0 },
		{ name: 'created_by', categoryIndex: 0 },
		{ name: 'linked_company', categoryIndex: 0 }
	];

	const list: DropdownData[] = [{ label: translateToString('exports.with_row_pivot'), value: 1 }, { label: translateToString('exports.with_column_pivot'), value: 2 }];

	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <></>,
			bottomRightToolbarComponent: <Dropdown
				datalist={list}
				name='dropdown_actions'
				readOnly
				JSXButton={() => (
					<img
						src={optionGrey}
						className="custom-icon"
						style={{ marginTop: '3px' }}
						alt=""
					/>
				)}
				dropdownStyle={{
					optionWidth: '200px',
					optionLeft: '-175px'
				}}
				onChange={async(value: DropdownData) => {
					const formName = template?.name;
					setLoadingState(LoadingStateEnum.LOADING);
					const launchImport = async(filterResult: FilterResult | undefined) => {
						try {
							if (value.value === 1) {
								await exportFieldValues(formId, formName!, { filters: filterResult?.formatted });
							} else if (value.value === 2) {
								await exportGroupedFieldValues(formId, formName!, { filters: filterResult?.formatted });
							}
							setLoadingState(LoadingStateEnum.LOADED);
						} catch {
							setLoadingState(LoadingStateEnum.ERROR);
						}
					};
					setFilterResult(filterResult => {
						launchImport(filterResult);
						return filterResult;
					});
				}}
			/>
		});
	}, [template, formId]);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			localStorage.setItem(LOCAL_STORAGE_RESIZE_KEY, JSON.stringify(resize));
		}, 500);

		return () => clearTimeout(handler);
	}, [resize]);

	const refresh = React.useCallback(() => {
		if (!props.viewLoaded) return;
		setPagination(pagination => {
			setFilterResult(filterResult => {
				setLoadingState(LoadingStateEnum.LOADING);
				setSort(sort => {
					let order_by = sort?.id;
					if (sort?.id.startsWith('{"additional_columns":')) {
						order_by = JSON.parse(sort.id);
					} else if (sort?.id.startsWith('{"additional_field_columns":')) {
						order_by = JSON.parse(sort.id);
					}
					getFormInstances(formId, {
						limit: pagination?.step ?? DEFAULT_STEP,
						offset: pagination?.offset ?? 0,
						filters: filterResult?.formatted,
						order_by: order_by,
						descending: sort?.desc,
					}).then(res => {
						setInstances(res);
						setLoadingState(LoadingStateEnum.LOADED);
					}).catch(_ => {
						toast.error('Error');
						setLoadingState(LoadingStateEnum.ERROR);
						setFilterResult(undefined);
					});
					return sort;
				});
				return filterResult;
			});
			return pagination;
		});
	}, [formId, props.viewLoaded]);

	React.useEffect(() => {
		setPagination(DEFAULT_PAGINATION);
		refresh();
	}, [filterResult, formId, props.viewLoaded]);

	const columns: Array<Column<FormInstance>> = React.useMemo(() => {
		const cols: Array<Column<FormInstance>> = [
			{
				id: 'started_at',
				Header: translateToString('started_on'),
				accessor: row => <TableRow
					fontWeight="500"
					color={DarkGreySidely}
					cursor="pointer"
					onClick={() => props.openPopup(row.uuid)}>
					{row.started_at && <DateAccessor utc={false} date={moment.utc(row.started_at).local()} />}
				</TableRow>,
				minWidth: 150,
				type: 'string',
			},
			{
				id: 'updated_at',
				Header: translateToString('updated_at'),
				accessor: row => <TableRow
					fontWeight="500"
					color={DarkGreySidely}
					cursor="pointer"
					onClick={() => props.openPopup(row.uuid)}>
					{row.updated_at && <DateAccessor utc={false} date={moment.utc(row.updated_at).local()} />}
				</TableRow>,
				minWidth: 150,
				type: 'string',
			},
			{
				id: 'made_at',
				Header: translateToString('ended_on'),
				accessor: row => <TableRow
					fontWeight="500"
					color={DarkGreySidely}
					cursor="pointer"
					onClick={() => props.openPopup(row.uuid)}>
					<FlexDiv fontWeight={row.progression < 100 ? '200' : '400'}>
						<DateAccessor utc={false} date={moment.utc(row.made_at).local()} />
						{row.progression < 100 ? translateToString('draft') : translateToString('completed')}
					</FlexDiv>
				</TableRow>,
				minWidth: 150,
				type: 'string',
			},
			... isSuper ? [{
				id: 'created_at',
				Header: translateToString('synched_on'),
				accessor: row => <TableRow
					fontWeight="500"
					color={DarkGreySidely}
					cursor="pointer"
					onClick={() => props.openPopup(row.uuid)}>
					{row.created_at && <DateAccessor utc={false} date={moment.utc(row.created_at).local()} />}
				</TableRow>,
				minWidth: 150,
				type: 'string',
			} as Column<FormInstance>] : [],
			{
				id: 'progression',
				Header: translateToString('progress'),
				accessor: row => <TableRow>
					<ProgressBar
						now={row.progression}
						variant={row.progression == 100 ? 'success' : row.progression > 45 ? 'warning' : 'danger'}
						min={0}
						max={100}
					/>
				</TableRow>,
				minWidth: 150,
				type: 'numeric',
			},
			{
				id: 'created_by',
				Header: translateToString('created_by'),
				accessor: row => <TableRow>{users.find(a => a.id == row.created_by)?.name}</TableRow>,
				minWidth: 150,
				type: 'string',
			},
			{
				id: 'linked_company',
				Header: translateToString('company'),
				accessor: row => <TableRow>
					{row.linked_company}
				</TableRow>,
				minWidth: 150,
				type: 'decimal',
			},
		];
		Object.entries(fieldColumns).forEach(([key, value]) => {
			cols.push({
				id: additionalFieldColumnIdFormatter(value.id),
				filterId: {
					additional_field_columns: [value.id]
				},
				Header: value.name,
				accessor: row => <FormFieldColumnDisplayer
					fieldType={value.type}
					value={row.additional_field_columns_value?.[key.toString()] as any}
					users={users}
				/>,
				minWidth: 150,
				type: 'string',
			});
		});
		cols.push(
			{
				id: 'quick_actions',
				noHeaderEllipsis: true,
				Header: <ColumnHidder
					categories={['form', 'additional_field_columns']}
					columns={[
						...ALL_FORM_COLUMNS,
						...Object.values(fieldColumns).map(e => ({ name: additionalFieldColumnIdFormatter(e.id), translationKey: e.name, noTranslation: true, categoryIndex: 1 }))
					]}
					hiddenColumnKey={FORM_HIDDEN_LOCAL_STORAGE + `[${formId}]`}
					sortColumnKey={FORM_SORT_LOCAL_STORAGE + `[${formId}]`}
				/>,
				accessor: row =>
					<Dropdown
						name='quick_action'
						datalist={[{
							label: translateToString('global.quick_action.delete'),
							value: 'DELETE'
						}]}
						readOnly
						dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
						JSXButton={() =>
							<img
								src={optionGrey}
								width={25}
								height={25}
								style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
							/>
						}
						onChange={value => {
							if (value.value === 'DELETE') {
								alertDelete({
									zIndex: 201,
									name: row.made_at as unknown as string
								})
									.then(result => result === AlertRes.Ok ? deleteFormInstance(row.uuid).then(refresh) : undefined);
							}
						}}
					/>,
				width: 40,
				minWidth: 40,
				disableSortBy: true,
				disableFilter: true,
				unresizeable: true,
				freeze: 'right'
			});
		return cols;
	}, [formId, fieldColumns]);

	const onClickFilter = React.useCallback((id: FilterId) => {
		setFilterState({
			selectedId: id,
			openSummary: false,
			filterOpen: true
		});
	}, []);

	return <>
		<Table
			initialSortBy={DEFAULT_SORT}
			height='calc(100vh - 174px)'
			columns={columns}
			data={instances}
			hiddenColumns={hiddenColumns}
			sortColumns={sortColumns}
			onClickFilter={onClickFilter}
			onSort={React.useCallback((sort: TableSortType) => setSort(sort[0] ?? DEFAULT_SORT), [])}
			resizeValue={resize}
			EnableResize
			onResize={React.useCallback(
				_.debounce((value) => {
					if (Object.keys(value).length > 0) setResize(value);
				}, 300),
				[]
			)}
		/>
		<Pagination
			label={translateToString('forms')}
			amount={instances[0]?.count ?? 0}
			currentCount={instances.length}
			steps={[DEFAULT_STEP, 75, 100]}
			onChange={(res) => {
				if (JSON.stringify(res) == JSON.stringify(pagination)) return;
				setPagination(res);
				refresh();
			}}
		/>

		<ComponentLoader loadingState={loadingState} allScreen />
	</>;
}
