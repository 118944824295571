import axios from 'axios';
import { FilterType } from 'bindings/filters/FilterType';
import { Cookies } from 'react-cookie';
import { IRow, Table } from '../../../../typings/proto/protobufs';
import {
	FilterId,
	FilterTree,
	isCheckoutEvolutionFilter,
	isExternalIdFilter,
	isFrequencyFilter,
	isLastEventDateFilter,
	isNextEventDateFilter
} from '../../../components_v2/filter/model/Model';
import { FilterParameter } from '../../../components_v2/filter/pages/FilterList';
import { URL_FOUNDATION } from '../../../config/keys';
import { translateToString } from '../../../styles/global/translate';
import { ExportType, TypeFile } from '../../orders/model/Model';
import { FilterCategory, filterCategories, getCompaniesFitlersCategories } from '../Companies';
import {
	ClientCompanyCreate,
	ClientCompanyDeleteParams,
	ClientCompanyEdit,
	ClientCompanyParams,
	ClientCompanyTimelineParams,
	Company,
	CompanyContactDb,
	CompanyDetail,
	CompanyDocumentDb,
	CompanyEventDb,
	CompanyFreeForm,
	CompanyLinkedElementAmount,
	CompanyNoteDb,
	CompanyOpportunityDb,
	CompanyOrderDb,
	CompanyParams,
	CompanyPhotoDb,
	CompanyShelfAuditDb,
	CompanyTimeline,
	DbCompany,
	EventStatus,
	EventType,
	FreeForm,
	NoteCompanyEdit,
	PostDocumentParams,
	PostPhotoParams,
	ProspectOrigin,
	ServerAdvancedFilter
} from '../model/Model';
import { Frequency } from '../popup/Frequencies';
import { filterIdToTranslatedName } from './CompanyColumns';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function exportData(fileType: TypeFile, type: ExportType): Promise<null> {
	let kind: object & { type: string };
	if (typeof type === 'string') {
		kind = { type: type };
	} else {
		kind = type;
	}
	const param = {
		output: fileType,
		kind,
	};
	return axios.post(`${URL_FOUNDATION}/api/export`, param);
}

export async function exportCompanies(params?: CompanyParams) {
	const data = {
		search: params?.search,
		new_filters: params?.new_filters,
		order_by: params?.order_by,
		descending: params?.descending,
		columns: params?.columns
	};
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/export`, data);
}

export async function getCompanies(params?: CompanyParams, noDefault?: boolean): Promise<DbCompany> {
	const data = {
		limit: params?.limit ?? (noDefault ? undefined : 50),
		offset: params?.offset ?? (noDefault ? undefined : 0),
		search: params?.search,
		// scope: params?.scope?.toUpperCase(),
		// filters: params?.filters ?? [],
		new_filters: params?.new_filters,
		order_by: params?.order_by,
		descending: params?.descending,
		columns: params?.columns,
		order_by_type: params?.order_by_type
	};
	if (data.columns && data.columns.includes('parent_name') && !data.columns.includes('parent_id')) {
		data.columns.push('parent_id');
	}
	try {
		const result = await axios.post<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/client-companies/query`, data, { responseType: 'arraybuffer' })
			.then(async response => {
				const message = Table.decode(new Uint8Array(response.data));
				return {
					companies: message.row.map((c: IRow): Company => {
						return {
							...c,
							company_id: c.companyId!,
							name: c.name!,
							email: c.mail === '' ? ' ' : c.mail ?? undefined,
							phone: c.phone === '' ? ' ' : c.phone ?? undefined,
							company_city: c.companyCity ?? '',
							company_address: c.companyAddress ?? undefined,
							company_post_code: c.companyPostcode ?? undefined,
							company_country: c.companyCountry ?? undefined,
							latitude: c.latitude ?? undefined,
							longitude: c.longitude ?? undefined,
							website: c.website ?? undefined,
							status_id: c.statusId ?? -1,
							owner_id: c.ownerId ?? -1,
							tags: (c.tagIds as any) ?? [],
							created_at: (c.createdAt != null) ? new Date((c.createdAt.seconds as number ?? 0) * 1000) : undefined,
							updated_at: (c.updatedAt != null) ? new Date((c.updatedAt.seconds as number ?? 0) * 1000) : undefined,
							created_by: c.createdBy == null ? undefined : c.createdBy,
							updated_by: c.updatedBy == null ? undefined : c.updatedBy,
							parent_id: c.parentId,
							parent_name: c.parentName,
							dn: c.dn,
							frequency: c.frequency,
							latest_shelf_audit_date: (c.lastShelfAuditDate != null) ? new Date((c.lastShelfAuditDate.seconds as number ?? 0) * 1000) : undefined,
							latest_event_date: c.latestEventDate,
							next_event_date: c.nextEventDate,
							latest_shelf_audit_id: c.latestShelfAuditId,
							externalId: c.externalId ?? undefined,
							checkoutEvolution: c.checkoutEvolution ?? undefined,
							shelfAuditTemplates: c.shelfAuditTemplates ?? undefined,
						} as Company;
					}),
					total: message.count
				} as DbCompany;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { companies: [], total: 0 };
	}
}

export async function getCompanyDetail(client_company_id: number): Promise<CompanyDetail | undefined> {
	return axios.get<CompanyDetail | undefined>(`${URL_FOUNDATION}/api/v2/client-companies/${client_company_id}`)
		.then(response => response.data)
		.catch(_ => undefined);
}

export async function getCompanyTimeline(params: ClientCompanyTimelineParams): Promise<CompanyTimeline[]> {
	try {
		const result = await axios.get<CompanyTimeline[]>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/timeline`, { params })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

const toLocalFilter = (companyFilter: [FilterId, FilterType]): ServerAdvancedFilter[] => {
	if (isCheckoutEvolutionFilter(companyFilter[0])) {
		return [
			[
				{ checkout: { element: 'previous_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'current_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'evolution' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'previous_uvc_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'current_uvc_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'uvc_evolution' } },
				companyFilter[1]
			]
		];
	}
	if (isFrequencyFilter(companyFilter[0])) {
		return [
			[
				{ frequencies: { element: 'score' } },
				companyFilter[1]
			],
			[
				{ frequencies: { element: 'frequency' } },
				companyFilter[1]
			]
		];
	}
	if (isNextEventDateFilter(companyFilter[0])) {
		return [
			[
				{ next_event_date: null },
				companyFilter[1]
			]
		];
	}
	if (isLastEventDateFilter(companyFilter[0])) {
		return [
			[
				{ last_event_date: null },
				companyFilter[1]
			]
		];
	}
	if (isExternalIdFilter(companyFilter[0])) {
		return [
			[
				{ external_id: null },
				companyFilter[1]
			]
		];

	}
	return [[companyFilter[0], companyFilter[1]]];
};

function translateFilterCategories(name: FilterCategory): string {
	let before = '';
	if (name === 'shelf_audit') before = '(v1) ';
	return before + translateToString(name);
}

export async function getCompanyFilters(): Promise<{filters: FilterParameter[], columns: FilterId[]}> {
	try {
		const result = await axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/v2/client-companies/filters`)
			.then(response => {
				return ({
					filters: filterCategories.filter(fc => fc !== 'shelf_audit').map((fc): FilterParameter => ({
						category: translateFilterCategories(fc),
						filters: response.data
							.reduce((acc, filter) => [...acc, ...toLocalFilter(filter)], [])
							.filter(companyFilter => getCompaniesFitlersCategories(companyFilter[0]).includes(fc))
							.map(companyFilter => ({ id: companyFilter[0], name: filterIdToTranslatedName(companyFilter[0]), type: companyFilter[1] }))
					})),
					columns: response.data.reduce((acc, filter) => [...acc, ...toLocalFilter(filter)], []).map(r => r[0])
				});
			});
		return result;
	} catch (error) {
		console.error(error);
		return { filters: [], columns: [] };
	}
}

export async function getCompanyLinkedElementAmounts(client_company_id: number): Promise<CompanyLinkedElementAmount> {
	try {
		const result = await axios.get<CompanyLinkedElementAmount>(`${URL_FOUNDATION}/api/v2/client-companies/${client_company_id}/linked-element-counts`)
			.then(response => response.data);
		return result;
	} catch (error) {
		console.error(error);
		return {
			amount_free_forms: 0,
			amount_contacts: 0,
			amount_documents: 0,
			amount_events: 0,
			amount_notes: 0,
			amount_opportunities: 0,
			amount_orders: 0,
			amount_photos: 0,
			amount_shelf_audit: 0
		} as CompanyLinkedElementAmount;
	}
}

export async function getCompanyContacts(params: ClientCompanyParams): Promise<CompanyContactDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyContactDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/contacts`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { contacts: [], total: 0 };
	}
}

export async function deleteCompanyContact(params: ClientCompanyDeleteParams): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/contacts/${params.id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getCompanyEvents(params: ClientCompanyParams): Promise<CompanyEventDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyEventDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/events`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { events: [], total: 0 };
	}
}

export async function getCompanyOpportunities(params: ClientCompanyParams): Promise<CompanyOpportunityDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyOpportunityDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/opportunities`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { opportunities: [], total: 0 };
	}
}

export async function deleteCompanyOpportunity(params: ClientCompanyDeleteParams): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/opportunities/${params.id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getCompanyOrders(params: ClientCompanyParams): Promise<CompanyOrderDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyOrderDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/orders`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { orders: [], total: 0 };
	}
}

export async function getCompanyPhotos(params: ClientCompanyParams): Promise<CompanyPhotoDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyPhotoDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/photos`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { photos: [], total: 0 };
	}
}

export async function getCompanyDocuments(params: ClientCompanyParams): Promise<CompanyDocumentDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyDocumentDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/documents`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { documents: [], total: 0 };
	}
}

export async function deleteCompanyDocument(params: { client_company_id: number, document_id: number }): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/documents/${params.document_id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function deleteCompanyPhoto(params: { client_company_id: number, photo_id: number }): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/photos/${params.photo_id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function deleteCompanyNote(params: { client_company_id: number, note_id: number }): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/notes/${params.note_id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getCompanyShelfAudits(params: ClientCompanyParams): Promise<CompanyShelfAuditDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyShelfAuditDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/shelf-audits`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { shelf_audits: [], total: 0 };
	}
}

export async function getCompanyFreeForms(params: ClientCompanyParams): Promise<CompanyFreeForm[]> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyFreeForm[]>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/free-forms`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getCompanyNotes(params: ClientCompanyParams): Promise<CompanyNoteDb> {
	const data = {
		limit: params.limit,
		offset: params.offset
	};
	try {
		const result = await axios.get<CompanyNoteDb>(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/notes`, { params: data })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { notes: [], total: 0 };
	}
}

export async function getEventTypes(): Promise<EventType[]> {
	try {
		return await axios.get<EventType[]>(`${URL_FOUNDATION}/api/crm/event-types`)
			.then(response => {
				const events: EventType[] = response.data as EventType[];
				const index = events.findIndex(item => item.name === 'Visit');
				if (index !== -1) {
					const visit = events.splice(index, 1)[0];
					events.unshift(visit);
				}
				return events;
			});
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getEventStatuses(): Promise<EventStatus[]> {
	try {
		const result = await axios.get<EventStatus[]>(`${URL_FOUNDATION}/api/crm/event-statuses`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function updateCompany(params: ClientCompanyEdit): Promise<boolean> {
	const newParams = { client_company_id: params.client_company_id };
	Object.keys(params).filter(k => k !== 'client_company_id').forEach(k => {
		if (params[k] === null || params[k] === undefined) {
			newParams[k] = 'deleted';
		} else {
			newParams[k] = { updated: params[k] };
		}
	});

	try {
		const result = await axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}`, newParams)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		return false;
	}
}

export async function updateCompanyNote(params: NoteCompanyEdit): Promise<boolean> {
	const newParams = { note_id: params.note_id };
	Object.keys(params).filter(k => k !== 'client_company_id' && k !== 'note_id').forEach(k => {
		if (params[k] === null || params[k] === undefined) {
			newParams[k] = 'deleted';
		} else {
			newParams[k] = { updated: params[k] };
		}
	});

	try {
		const result = await axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/notes`, newParams)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		return false;
	}
}

export async function CreateCompany(params: ClientCompanyCreate): Promise<number> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/client-companies`, params)
			.then(response => {
				return response.status >= 200 && response.status < 300 ? response.data : -1;
			});
		return result;
	} catch (error) {
		console.error(error);
		return -1;
	}
}

type FrequencyTarget = {
	event_type: number,
	frequency: number
}

export async function deleteFrequency(company_id: number, event_id: number): Promise<null> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${company_id}/frequency-targets/${event_id}`).then(res => res.data);
}

export async function createFrequency(id: number, params: FrequencyTarget): Promise<null> {
	return axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${id}/frequency-targets`, params).then(res => res.data);
}
export async function createFrequencies(id: number, params: FrequencyTarget[]): Promise<null> {
	return axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${id}/frequency-targets/multiple`, params).then(res => res.data);
}
export async function getFrequencies(id: number): Promise<Frequency[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/client-companies/${id}/frequency-targets`).then(res => res.data);
}

export async function deleteCompany(client_company_id: number): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${client_company_id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function deleteCompanies(clientCompaniesId: number[], isAllSelected: boolean, new_filters?: FilterTree, search?: string): Promise<boolean> {
	const params = {
		client_companies: clientCompaniesId,
		all: isAllSelected,
		new_filters,
		search
	};

	try {
		const result = await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies`, { data: params })
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getProspectOrigins(): Promise<ProspectOrigin[]> {
	try {
		const result = await axios.get<ProspectOrigin[]>(`${URL_FOUNDATION}/api/prospect-origin`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function postPhotos(params: PostPhotoParams): Promise<number[]> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/photos`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function postDocuments(params: PostDocumentParams): Promise<number[]> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/documents`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getFreeForms(): Promise<FreeForm[]> {
	try {
		const result = await axios.get(`${URL_FOUNDATION}/api/v2/free-forms`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function deleteExternalId(companyId: number, externalMappingId: number): Promise<null> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/mappings/${externalMappingId}`);
}

export async function setExternalId(companyId: number, externalMappingId: number, external_id: string): Promise<null> {
	return axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/mappings/${externalMappingId}`, { external_id });
}
